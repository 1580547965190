import {Menu} from "../components/Menu";
import React from "react";

declare const window: Window &
    typeof globalThis & {
    Telegram: any
}

export default function Settings() {
    const openBot = () => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.openTelegramLink('https://t.me/olxnotifybot');
        }
    }

    return <>
        <Menu/>

        <div className="p-2 mt-4">
            <p className="dark:text-gray-50 text-gray-500 font-bold">ДАНИЙ ФУНКЦІОНАЛ ПРАЦЮЄ В ТЕСТОВОМУ РЕЖИМІ І МОЖЕ
                БУТИ НЕДОСТУПНИЙ ВАМ ПІСЛЯ ЙОГО ЗАПУСКУ!</p>

            <p className="dark:text-gray-50 text-gray-500 mt-4 font-bold">Для того, щоб отримувати сповіщення в каналі
                Вам необхідно:</p>
            <p className="dark:text-gray-50 text-gray-500">1. Створити телеграм канал.</p>
            <p className="dark:text-gray-50 text-gray-500">2. Добавити <span onClick={() => {
                openBot()
            }} className="underline">@olxnotifybot</span> як адміністратора каналу.</p>
            <p className="dark:text-gray-50 text-gray-500">3. Надіслати сюди назву вашого каналу(@name) або
                ID(-10101010101).</p>

            <div className="w-full flex justify-between mt-4">
                <input type="text" id="first_name"
                       className="w-full rounded p-2 mr-2 border dark:border-gray-500 dark:text-gray-500 dark:focus:border-gray-50 dark:focus:text-gray-50 bg-[var(--bg-color)] focus:outline-none border-gray-500 text-gray-500 focus:border-gray-950 focus:text-gray-950"
                       placeholder="-10101010101" required/>
                <button
                    className="font-bold dark:bg-gray-50 bg-gray-950 text-[var(--bg-color)] rounded w-max p-2 border w-24">Send
                </button>
            </div>


            <p className="dark:text-gray-50 text-gray-500 mt-4 font-bold">ID використовується тільки для приватних
                каналів.</p>
            <p className="dark:text-gray-50 text-gray-500">Для того, щоб дізнатись ID Вам необхідно виконати
                команду <span className="underline text-blue-500">/myid</span> у вашому каналі.</p>
        </div>
    </>
}

