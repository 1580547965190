import {useLocation, useNavigate} from "react-router-dom";
import React from "react";
import {Database, Dollar, Info, Mail, Settings} from "./Icons"

export function Menu() {
    const location = useLocation();
    const navigate = useNavigate();

    const onClick = (path: string) => {
        return () => {
            navigate(path)
        }
    }

    const isActive = (path: string) => {
        return location.pathname === path
    }

    return <div className='w-screen'>
        <div className="flex justify-between">
            <MenuItem onClick={onClick(`/`)} active={isActive('/')} icon=<Database/>
                      label="Запити"/>
            <MenuItem onClick={onClick(`/plans`)} active={isActive('/plans')} icon=<Dollar/>
                      label="Тарифи"/>
            <MenuItem onClick={onClick(`/settings`)} active={isActive('/settings')}
                      icon=<Settings/> label="Налаштування"/>
            <MenuItem onClick={onClick(`/info`)} active={isActive('/info')} icon=<Info/>
                      label="Про Бот"/>
            <MenuItem onClick={onClick(`/contacts`)} active={isActive('/contacts')} icon=<Mail/>
                      label="Контакти"/>
        </div>
    </div>
}

function MenuItem({icon, label, active, onClick}: any) {
    return <div onClick={onClick} className="w-full flex justify-center">
        <div className={active ? 'dark:text-gray-50 text-gray-950' : 'text-gray-500'}>
            {icon}
            <span className="m-auto text-xs">{label}</span>
        </div>
    </div>
}

