import {Menu} from "../components/Menu";
import React from "react";

export default function Info() {
    return <>
        <Menu/>

        <div className="p-2 mt-4">
            <p className="dark:text-gray-50 text-gray-500 font-bold">Notify Me Bot призначений для пошуку оголошень в
                реальному часі.</p>

            <p className="dark:text-gray-50 text-gray-500 mt-4 font-bold">Як це працює?</p>
            <p className="dark:text-gray-50 text-gray-500">Ви створюєте пошуковий запит, a бот сповістить вас як тільки
                нове оголошеня з'явиться на сайті.</p>
            <p className="dark:text-gray-50 text-gray-500">Ви будете отримувати тільки 💯 відсотків нові оголошення без
                надокучливого спаму.</p>

            <p className="dark:text-gray-50 text-gray-500 mt-4 font-bold">Основні переваги:</p>
            <p className="dark:text-gray-50 text-gray-500">1️⃣ Отримуйте оголошення першими (це дає змогу придбати
                ходовий товар (послугу) за
                низькою ціною).</p>
            <p className="dark:text-gray-50 text-gray-500">2️⃣ Економте свій час (не витрачайте години пошуку на сайті
                чи в додатку - доручіть
                це нам).</p>
            <p className="dark:text-gray-50 text-gray-500">3️⃣ Зручний та зрозумілий інтерфейс.</p>
            <p className="dark:text-gray-50 text-gray-500">4️⃣ Можливість відслідковувати конкурентний діапазон цін.</p>

            <p className="dark:text-gray-50 text-gray-500 mt-4 font-bold">Давайте розглянемо наступний приклад:</p>
            <p className="dark:text-gray-50 text-gray-500">Аліса хоче орендувати 🏡. Кожен раз, коли вона 📞 по черговому
                оголошенні - квартира або здана, або зарезервована.</p>

            <p className="dark:text-gray-50 text-gray-500 mt-2 font-bold">Як бути першим?</p>
            <p className="dark:text-gray-50 text-gray-500">Наш бот надає одну безкоштовну заявку для створення запиту.
                Все, що вам потрібно зробити - це вказати пошукові критерії.</p>
            <p className="dark:text-gray-50 text-gray-500">Тобто, Ви можете задати:</p>

            <p className="dark:text-gray-50 text-gray-500">категорія - Нерухомість, регіон - Львівська обл., місто -
                Львів, ціна - від 5000 до 10 000 грн. тощо</p>


            <p className="dark:text-gray-50 text-gray-500 mt-2">Як тільки нове оголошеня за заданими параметрами з'явиться на
                сайті, ви отримаєте
                сповіщення.</p>
            <p className="dark:text-gray-50 text-gray-500">Не забувайте, що ви завжди можете видалити запит та створити
                новий.</p>


            <p className="dark:text-gray-50 text-gray-500 mt-4 font-bold">Приємного користування 🙌🏼</p>
            <p className="dark:text-gray-50 text-gray-500">Дякуємо, що обрали нас 💙</p>

        </div>

    </>
}