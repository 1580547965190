export default [
  {
    "category_id": 1,
    "parent": 0,
    "code": "nedvizhimost",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "realestate",
    "type": "real_estate",
    "name": "Нерухомість",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 1,
    "max_photos": 24,
    "path": "nedvizhimost",
    "icon": {
      "image_url": "https://categories.olxcdn.com/assets/categories/olxua/nedvizhimost-1-1x.png",
      "big_image_url": "https://categories.olxcdn.com/assets/categories/olxua/nedvizhimost-1-2x.png"
    },
    "children": [
      9,
      206,
      1522,
      1606,
      1610,
      1616,
      1754,
      1757
    ]
  },
  {
    "category_id": 3,
    "parent": 0,
    "code": "zapchasti-dlya-transporta",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "spare",
    "type": "automotive",
    "name": "Запчастини для транспорту",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 1,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta",
    "icon": {
      "image_url": "https://categories.olxcdn.com/assets/categories/olxua/zapchasti-dlya-transporta-3-1x.png",
      "big_image_url": "https://categories.olxcdn.com/assets/categories/olxua/zapchasti-dlya-transporta-3-2x.png"
    },
    "children": [
      270,
      316,
      2017,
      2018,
      2019,
      2154,
      2155,
      2156,
      2157,
      2357,
      2571
    ]
  },
  {
    "category_id": 6,
    "parent": 0,
    "code": "rabota",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "jobs",
    "type": "job",
    "name": "Робота",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 1,
    "max_photos": 0,
    "path": "rabota",
    "icon": {
      "image_url": "https://categories.olxcdn.com/assets/categories/olxua/rabota-6-1x.png",
      "big_image_url": "https://categories.olxcdn.com/assets/categories/olxua/rabota-6-2x.png"
    },
    "children": [
      134,
      136,
      137,
      140,
      141,
      143,
      144,
      145,
      147,
      150,
      151,
      152,
      154,
      183,
      1159,
      1165,
      1473,
      1475,
      1477,
      1479,
      1481,
      1866,
      1874,
      1902
    ]
  },
  {
    "category_id": 7,
    "parent": 0,
    "code": "uslugi",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "services",
    "type": "",
    "name": "Бізнес та послуги",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 1,
    "max_photos": 8,
    "path": "uslugi",
    "icon": {
      "image_url": "https://categories.olxcdn.com/assets/categories/olxua/uslugi-7-1x.png",
      "big_image_url": "https://categories.olxcdn.com/assets/categories/olxua/uslugi-7-2x.png"
    },
    "children": [
      42,
      47,
      112,
      186,
      188,
      190,
      191,
      192,
      193,
      194,
      195,
      196,
      203,
      204,
      726,
      767,
      773,
      810,
      813,
      836
    ]
  },
  {
    "category_id": 9,
    "parent": 1,
    "code": "posutochno-pochasovo",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Подобова оренда житла",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 24,
    "path": "nedvizhimost/posutochno-pochasovo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      328,
      329,
      331,
      1618,
      1620,
      1622
    ]
  },
  {
    "category_id": 20,
    "parent": 1606,
    "code": "arenda-zemli",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Оренда землі",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "nedvizhimost/zemlya/arenda-zemli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 21,
    "parent": 1616,
    "code": "prodazha-garazhey-parkovok",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Продаж гаражів, парковок",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "nedvizhimost/garazhy-parkovki/prodazha-garazhey-parkovok",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 28,
    "parent": 1616,
    "code": "arenda-garazhey-parkovok",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Оренда гаражів, парковок",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "nedvizhimost/garazhy-parkovki/arenda-garazhey-parkovok",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 35,
    "parent": 0,
    "code": "zhivotnye",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "animals",
    "type": "goods",
    "name": "Тварини",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 1,
    "max_photos": 8,
    "path": "zhivotnye",
    "icon": {
      "image_url": "https://categories.olxcdn.com/assets/categories/olxua/zhivotnye-35-1x.png",
      "big_image_url": "https://categories.olxcdn.com/assets/categories/olxua/zhivotnye-35-2x.png"
    },
    "children": [
      64,
      65,
      66,
      67,
      120,
      247,
      364,
      365,
      366,
      367,
      368,
      1520
    ]
  },
  {
    "category_id": 36,
    "parent": 0,
    "code": "detskiy-mir",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "motherchild",
    "type": "goods",
    "name": "Дитячий світ",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 1,
    "max_photos": 8,
    "path": "detskiy-mir",
    "icon": {
      "image_url": "https://categories.olxcdn.com/assets/categories/olxua/detskiy-mir-36-1x.png",
      "big_image_url": "https://categories.olxcdn.com/assets/categories/olxua/detskiy-mir-36-2x.png"
    },
    "children": [
      68,
      69,
      71,
      538,
      539,
      542,
      543,
      544,
      545,
      889
    ]
  },
  {
    "category_id": 37,
    "parent": 0,
    "code": "elektronika",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "electronics",
    "type": "electronics",
    "name": "Електроніка",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 1,
    "max_photos": 8,
    "path": "elektronika",
    "icon": {
      "image_url": "https://categories.olxcdn.com/assets/categories/olxua/elektronika-37-1x.png",
      "big_image_url": "https://categories.olxcdn.com/assets/categories/olxua/elektronika-37-2x.png"
    },
    "children": [
      38,
      44,
      55,
      369,
      383,
      390,
      395,
      401,
      402,
      420,
      429,
      1431,
      1502,
      1508
    ]
  },
  {
    "category_id": 38,
    "parent": 37,
    "code": "kompyutery-i-komplektuyuschie",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Комп'ютери та комплектуючі",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "elektronika/kompyutery-i-komplektuyuschie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      78,
      83,
      84,
      453,
      458,
      466,
      476,
      480
    ]
  },
  {
    "category_id": 40,
    "parent": 573,
    "code": "lyzhi-snoubordy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Лижі / сноуборди",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh/lyzhi-snoubordy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 42,
    "parent": 7,
    "code": "prodazha-biznesa",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Продаж бізнесу",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "uslugi/prodazha-biznesa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 44,
    "parent": 37,
    "code": "telefony-i-aksesuary",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Телефони та аксесуари",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "elektronika/telefony-i-aksesuary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      85,
      86,
      88,
      442,
      450,
      1482
    ]
  },
  {
    "category_id": 45,
    "parent": 903,
    "code": "muzykalnye-instrumenty",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Музичні інструменти",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/muzykalnye-instrumenty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      90,
      489,
      490,
      491,
      493,
      495,
      497,
      498,
      499,
      509,
      1429
    ]
  },
  {
    "category_id": 47,
    "parent": 7,
    "code": "oborudovanie",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Обладнання",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "uslugi/oborudovanie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 48,
    "parent": 899,
    "code": "komnatnye-rasteniya",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кімнатні рослини",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "dom-i-sad/komnatnye-rasteniya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 49,
    "parent": 903,
    "code": "knigi-zhurnaly",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Книги / журнали",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/knigi-zhurnaly",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 52,
    "parent": 891,
    "code": "krasota-zdorove",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Краса / здоров'я",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      568,
      570,
      571,
      572,
      3007,
      3008,
      3009,
      3010,
      3011,
      3012,
      3013,
      3014,
      3015,
      3016,
      3017,
      3018
    ]
  },
  {
    "category_id": 53,
    "parent": 903,
    "code": "antikvariat-kollektsii",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Антикваріат / колекції",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/antikvariat-kollektsii",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      622,
      623,
      624,
      625,
      626,
      631
    ]
  },
  {
    "category_id": 54,
    "parent": 903,
    "code": "bilety",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Квитки",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/bilety",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 55,
    "parent": 37,
    "code": "igry-i-igrovye-pristavki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Ігри та ігрові приставки",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "elektronika/igry-i-igrovye-pristavki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      101,
      102,
      103,
      484,
      1161
    ]
  },
  {
    "category_id": 57,
    "parent": 899,
    "code": "mebel",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Меблі",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "dom-i-sad/mebel",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      107,
      511,
      515,
      522,
      523,
      524,
      525
    ]
  },
  {
    "category_id": 60,
    "parent": 899,
    "code": "predmety-interera",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Предмети інтер'єру",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "dom-i-sad/predmety-interera",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      61,
      529,
      533
    ]
  },
  {
    "category_id": 61,
    "parent": 60,
    "code": "svetilniki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Освітлення",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/predmety-interera/svetilniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 63,
    "parent": 899,
    "code": "prochie-tovary-dlya-doma",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші товари для дому",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "dom-i-sad/prochie-tovary-dlya-doma",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 64,
    "parent": 35,
    "code": "sobaki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Собаки",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zhivotnye/sobaki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 65,
    "parent": 35,
    "code": "koshki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Коти",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zhivotnye/koshki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 66,
    "parent": 35,
    "code": "drugie-zhivotnye",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші тварини",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zhivotnye/drugie-zhivotnye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 67,
    "parent": 35,
    "code": "tovary-dlya-zhivotnyh",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Зоотовари",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zhivotnye/tovary-dlya-zhivotnyh",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 68,
    "parent": 36,
    "code": "detskie-kolyaski",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Дитячі коляски",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "detskiy-mir/detskie-kolyaski",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 69,
    "parent": 36,
    "code": "detskaya-mebel",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Дитячі меблі",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "detskiy-mir/detskaya-mebel",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 70,
    "parent": 539,
    "code": "odezhda-dlya-devochek",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Одяг для дівчаток",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "detskiy-mir/detskaya-odezhda/odezhda-dlya-devochek",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 71,
    "parent": 36,
    "code": "prochie-detskie-tovary",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші дитячі товари",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "detskiy-mir/prochie-detskie-tovary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 72,
    "parent": 402,
    "code": "mp3-pleery",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Mp3 плеєри",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/audiotehnika/mp3-pleery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 73,
    "parent": 429,
    "code": "tsifrovye-fotoapparaty",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Цифрові фотоапарати",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/foto-video/tsifrovye-fotoapparaty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 75,
    "parent": 420,
    "code": "televizory",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Телевізори",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tv-videotehnika/televizory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 76,
    "parent": 383,
    "code": "prochaya-tehnika-dlya-doma",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Інша техніка для дому",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-doma/prochaya-tehnika-dlya-doma",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 78,
    "parent": 38,
    "code": "nastolnye-kompyutery",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Настільні комп'ютери",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/kompyutery-i-komplektuyuschie/nastolnye-kompyutery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 80,
    "parent": 1502,
    "code": "noutbuki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Ноутбуки",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/noutbuki-i-aksesuary/noutbuki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 81,
    "parent": 1508,
    "code": "planshetnye-kompyutery",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Планшетні комп'ютери",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/planshety-el-knigi-i-aksessuary/planshetnye-kompyutery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 83,
    "parent": 38,
    "code": "monitory",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Монітори",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/kompyutery-i-komplektuyuschie/monitory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 84,
    "parent": 38,
    "code": "drugoe",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Інше",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/kompyutery-i-komplektuyuschie/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 85,
    "parent": 44,
    "code": "mobilnye-telefony-smartfony",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Мобільні телефони / смартфони",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/telefony-i-aksesuary/mobilnye-telefony-smartfony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 86,
    "parent": 44,
    "code": "sim-karty-tarify-nomera",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Сім-карти / тарифи / номери",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/telefony-i-aksesuary/sim-karty-tarify-nomera",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 87,
    "parent": 836,
    "code": "remont-proshivka-telefonov-i-smartfonov",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Телефони і смартфони",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/remont-i-obsluzhivanie-tehniki/remont-proshivka-telefonov-i-smartfonov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 88,
    "parent": 44,
    "code": "ratsiyi-i-prochie-telefony",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Рації та інші телефони",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/telefony-i-aksesuary/ratsiyi-i-prochie-telefony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 90,
    "parent": 45,
    "code": "pianino-fortepiano-royali",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Піаніно / фортепіано / роялі",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/muzykalnye-instrumenty/pianino-fortepiano-royali",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 93,
    "parent": 899,
    "code": "produkty-pitaniya-napitki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Продукти харчування / напої",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "dom-i-sad/produkty-pitaniya-napitki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 97,
    "parent": 891,
    "code": "dlya-svadby",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Для весілля",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2584,
      2585,
      2586,
      2587,
      2588,
      2589,
      2609,
      2610
    ]
  },
  {
    "category_id": 98,
    "parent": 891,
    "code": "aksessuary",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Аксесуари",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      552,
      553,
      557,
      893,
      3141,
      3142,
      3143,
      3144,
      3145,
      3146,
      3147,
      3148,
      3149,
      3150,
      3151,
      3152
    ]
  },
  {
    "category_id": 101,
    "parent": 55,
    "code": "pristavki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Приставки",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/igry-i-igrovye-pristavki/pristavki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 102,
    "parent": 55,
    "code": "igry-dlya-pristavok",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Ігри для приставок",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/igry-i-igrovye-pristavki/igry-dlya-pristavok",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 103,
    "parent": 55,
    "code": "geroi-igr",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Герої ігор",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/igry-i-igrovye-pristavki/geroi-igr",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 104,
    "parent": 836,
    "code": "remont-proshivka-igrovyh-pristavok",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Ігрові приставки",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/remont-i-obsluzhivanie-tehniki/remont-proshivka-igrovyh-pristavok",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 107,
    "parent": 57,
    "code": "mebel-na-zakaz",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Меблі на замовлення",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/mebel/mebel-na-zakaz",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 108,
    "parent": 1532,
    "code": "legkovye-avtomobili",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Легкові автомобілі",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      963,
      965,
      969,
      973,
      981,
      983,
      985,
      987,
      989,
      991,
      997,
      1007,
      1009,
      1011,
      1015,
      1019,
      1023,
      1035,
      1037,
      1043,
      1045,
      1053,
      1055,
      1063,
      1065,
      1067,
      1069,
      1071,
      1077,
      1081,
      1093,
      1095,
      1097,
      1101,
      1103,
      1105,
      1107,
      1109,
      1111,
      1115,
      1117,
      1119,
      1121,
      1123,
      1131,
      1133,
      1361,
      1413,
      1534,
      1536,
      1538,
      1540,
      1542,
      1544,
      1546,
      1548,
      1550,
      1552,
      1554,
      1556,
      1558,
      1560,
      1562,
      1564,
      1566,
      1568,
      1570,
      1572,
      1574,
      1576,
      1578,
      1580,
      1582,
      1584,
      1586,
      1588,
      1590,
      1592,
      1594,
      1762,
      1763,
      1764,
      1765,
      1766,
      1767,
      1768,
      1769,
      1770,
      1771,
      1772,
      1773,
      1774,
      1775,
      1776,
      1777
    ]
  },
  {
    "category_id": 109,
    "parent": 1532,
    "code": "moto",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мото",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 24,
    "path": "transport/moto",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      264,
      265,
      266,
      326,
      1936,
      1937,
      1938,
      1939,
      1940,
      1941,
      1942,
      1943
    ]
  },
  {
    "category_id": 110,
    "parent": 1532,
    "code": "drugoy-transport",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інший транспорт",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 24,
    "path": "transport/drugoy-transport",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2680,
      2681,
      2682,
      2683,
      2684,
      2685,
      2686
    ]
  },
  {
    "category_id": 112,
    "parent": 7,
    "code": "avto-moto-uslugi",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Авто / мото послуги",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "uslugi/avto-moto-uslugi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 115,
    "parent": 903,
    "code": "poisk-poputchikov",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пошук попутників",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/poisk-poputchikov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 118,
    "parent": 903,
    "code": "poisk-grupp-muzykantov",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пошук гуртів / музикантів",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/poisk-grupp-muzykantov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 120,
    "parent": 35,
    "code": "byuro-nahodok",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бюро знахідок",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zhivotnye/byuro-nahodok",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 134,
    "parent": 6,
    "code": "meditsina-farmatsiya",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Медицина / фармацевтика",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/meditsina-farmatsiya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1818,
      1820,
      1822,
      1824
    ]
  },
  {
    "category_id": 136,
    "parent": 6,
    "code": "chastichnaya-zanyatost",
    "position": 18,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Часткова зайнятість",
    "view_type": "list",
    "is_addable": false,
    "is_searchable": true,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/chastichnaya-zanyatost",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 137,
    "parent": 6,
    "code": "krasota-fitnes-sport",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Краса / фітнес / спорт",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/krasota-fitnes-sport",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1797,
      1800,
      1802,
      1803,
      1804,
      1805,
      1807
    ]
  },
  {
    "category_id": 140,
    "parent": 6,
    "code": "transport-logistika",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Логістика / Склад / Доставка",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/transport-logistika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1911,
      1912,
      1913,
      1914,
      1915,
      1916,
      1917,
      1918,
      1919
    ]
  },
  {
    "category_id": 141,
    "parent": 6,
    "code": "obrazovanie",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Освіта / переклад",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/obrazovanie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1814,
      1815,
      1816,
      1817
    ]
  },
  {
    "category_id": 143,
    "parent": 6,
    "code": "proizvodstvo-energetika",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Виробництво / робітничі спеціальності",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/proizvodstvo-energetika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1837,
      1838,
      1839,
      1840,
      1841,
      1842,
      1843,
      1844,
      1845,
      1846,
      1847,
      1848,
      1849,
      1850,
      1851,
      1852,
      1853,
      1854,
      1855,
      1856
    ]
  },
  {
    "category_id": 144,
    "parent": 6,
    "code": "roznichnaya-torgovlya-prodazhi-zakupki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Роздрібна торгівля / продажі / закупки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/roznichnaya-torgovlya-prodazhi-zakupki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1920,
      1921,
      1922,
      1923,
      1924,
      1925,
      1926,
      1927,
      1928,
      1929,
      1930,
      1931,
      1932,
      1933,
      1934,
      1935
    ]
  },
  {
    "category_id": 145,
    "parent": 6,
    "code": "marketing-reklama-dizayn",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Реклама / дизайн / PR",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/marketing-reklama-dizayn",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1830,
      1831,
      1832,
      1833,
      1834,
      1835,
      1836
    ]
  },
  {
    "category_id": 147,
    "parent": 6,
    "code": "it-telekom-kompyutery",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "IT / комп'ютери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/it-telekom-kompyutery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1819,
      1821,
      1823,
      1826
    ]
  },
  {
    "category_id": 150,
    "parent": 6,
    "code": "stroitelstvo",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Будівництво / облицювальні роботи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/stroitelstvo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1778,
      1779,
      1780,
      1781,
      1782,
      1783,
      1784,
      1785,
      1786,
      1787,
      1788,
      1789,
      1790
    ]
  },
  {
    "category_id": 151,
    "parent": 6,
    "code": "drugie-sfery-zanyatiy",
    "position": 23,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інші сфери занять",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/drugie-sfery-zanyatiy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 152,
    "parent": 6,
    "code": "ohrana-bezopasnost",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Охорона / безпека",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/ohrana-bezopasnost",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1796,
      1799,
      1801
    ]
  },
  {
    "category_id": 154,
    "parent": 6,
    "code": "kultura-iskusstvo",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Культура / мистецтво / розваги",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/kultura-iskusstvo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1889,
      1890,
      1891,
      1892
    ]
  },
  {
    "category_id": 183,
    "parent": 6,
    "code": "domashniy-personal",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Клінінг / Домашній персонал",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/domashniy-personal",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1808,
      1809,
      1810,
      1811,
      1812,
      1813
    ]
  },
  {
    "category_id": 186,
    "parent": 7,
    "code": "prochie-uslugi",
    "position": 20,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Інші послуги",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "uslugi/prochie-uslugi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 188,
    "parent": 7,
    "code": "stroitelstvo-otdelka-remont",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Будівництво / ремонт / прибирання",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "uslugi/stroitelstvo-otdelka-remont",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      198,
      822,
      823,
      824,
      825,
      826,
      827,
      828,
      829
    ]
  },
  {
    "category_id": 190,
    "parent": 7,
    "code": "turizm-immigratsiya",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Туризм / іміграція",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "uslugi/turizm-immigratsiya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 191,
    "parent": 7,
    "code": "yuridicheskie-uslugi",
    "position": 18,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Юридичні послуги",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "uslugi/yuridicheskie-uslugi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 192,
    "parent": 7,
    "code": "perevozki-arenda-transporta",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "services",
    "name": "Перевезення / транспортні послуги",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "uslugi/perevozki-arenda-transporta",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3255,
      3256,
      3257,
      3258,
      3259,
      3260,
      3261,
      3262
    ]
  },
  {
    "category_id": 193,
    "parent": 7,
    "code": "krasota-zdorove",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Краса / здоров'я",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "uslugi/krasota-zdorove",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      851,
      852,
      853,
      854,
      855,
      856,
      1175
    ]
  },
  {
    "category_id": 194,
    "parent": 7,
    "code": "uslugi-perevodchikov-nabor-teksta",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Послуги перекладачів / набір тексту",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "uslugi/uslugi-perevodchikov-nabor-teksta",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 195,
    "parent": 7,
    "code": "uslugi-dlya-zhivotnyh",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Послуги для тварин",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "uslugi/uslugi-dlya-zhivotnyh",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 196,
    "parent": 7,
    "code": "razvlechenie-foto-video",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Розваги / Мистецтво / Фото / Відео",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "uslugi/razvlechenie-foto-video",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 198,
    "parent": 188,
    "code": "bytovoy-remont-uborka",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Побутовий ремонт / прибирання",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/stroitelstvo-otdelka-remont/bytovoy-remont-uborka",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 203,
    "parent": 7,
    "code": "obrazovanie",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Освіта / Спорт",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "uslugi/obrazovanie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 204,
    "parent": 7,
    "code": "nyani-sidelki",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Няні / доглядальниці",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "uslugi/nyani-sidelki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 206,
    "parent": 1,
    "code": "doma",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Будинки",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 24,
    "path": "nedvizhimost/doma",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      330,
      1602
    ]
  },
  {
    "category_id": 222,
    "parent": 1532,
    "code": "gruzovye-avtomobili",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Вантажні автомобілі",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 24,
    "path": "transport/gruzovye-avtomobili",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 225,
    "parent": 1532,
    "code": "avtobusy",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автобуси",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 24,
    "path": "transport/avtobusy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 247,
    "parent": 35,
    "code": "vyazka",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "В'язка",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zhivotnye/vyazka",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 253,
    "parent": 1532,
    "code": "vodnyy-transport",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Водний транспорт ",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 24,
    "path": "transport/vodnyy-transport",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1985,
      1986,
      1987,
      1988,
      1989,
      1990,
      1991,
      1992
    ]
  },
  {
    "category_id": 261,
    "parent": 1532,
    "code": "pritsepy-doma-na-kolesah",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Причепи / будинки на колесах",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 24,
    "path": "transport/pritsepy-doma-na-kolesah",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1993,
      1994,
      1995,
      1996,
      1997,
      1998,
      1999,
      2000,
      2001,
      2002,
      2003,
      2004,
      2005,
      2006,
      2007,
      2008,
      2009
    ]
  },
  {
    "category_id": 264,
    "parent": 109,
    "code": "mototsikly",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мотоцикли",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/moto/mototsikly",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 265,
    "parent": 109,
    "code": "mopedy-skutery",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мопеди / скутери",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/moto/mopedy-skutery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 266,
    "parent": 109,
    "code": "kvadrotsikly",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Квадроцикли",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/moto/kvadrotsikly",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 270,
    "parent": 3,
    "code": "shiny-diski-i-kolesa",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Шини, диски і колеса",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/shiny-diski-i-kolesa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1459,
      1461,
      1463,
      1465,
      1467,
      2109
    ]
  },
  {
    "category_id": 273,
    "parent": 1532,
    "code": "spetstehnika",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Спецтехніка",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 24,
    "path": "transport/spetstehnika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      293,
      295,
      297,
      298,
      299,
      301,
      302,
      303,
      1965,
      1966,
      1967,
      1968,
      1969,
      1970,
      1971,
      1972,
      1973,
      1974,
      1975,
      1976,
      1977
    ]
  },
  {
    "category_id": 293,
    "parent": 273,
    "code": "buldozery-traktory",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Бульдозери / трактори",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/spetstehnika/buldozery-traktory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 295,
    "parent": 273,
    "code": "kommunalnaya-tehnika",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Комунальна техніка",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/spetstehnika/kommunalnaya-tehnika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 297,
    "parent": 273,
    "code": "pogruzchiki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Навантажувачі",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/spetstehnika/pogruzchiki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 298,
    "parent": 273,
    "code": "samosvaly",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Самоскиди",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/spetstehnika/samosvaly",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 299,
    "parent": 273,
    "code": "stroitelnaya-tehnika",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Будівельна техніка",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/spetstehnika/stroitelnaya-tehnika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 301,
    "parent": 273,
    "code": "ekskavatory",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Екскаватори",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/spetstehnika/ekskavatory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 302,
    "parent": 273,
    "code": "oborudovanie-dlya-spetstehniki",
    "position": 20,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Обладнання для спецтехніки",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/spetstehnika/oborudovanie-dlya-spetstehniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 303,
    "parent": 273,
    "code": "prochaya-spetstehnika",
    "position": 21,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інша спецтехніка",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/spetstehnika/prochaya-spetstehnika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 304,
    "parent": 1532,
    "code": "selhoztehnika",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Сільгосптехніка",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 24,
    "path": "transport/selhoztehnika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1978,
      1979,
      1980,
      1981,
      1982,
      1983,
      1984
    ]
  },
  {
    "category_id": 316,
    "parent": 3,
    "code": "prochie-zapchasti",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Запчастини для іншої техніки",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/prochie-zapchasti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2578,
      2579,
      2580,
      2581,
      2582,
      2583
    ]
  },
  {
    "category_id": 326,
    "parent": 109,
    "code": "moto-prochee",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мото - інше",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/moto/moto-prochee",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 328,
    "parent": 9,
    "code": "posutochno-pochasovo-khostely",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Хостели, койко-місця",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 24,
    "path": "nedvizhimost/posutochno-pochasovo/posutochno-pochasovo-khostely",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 329,
    "parent": 9,
    "code": "posutochno-pochasovo-komnaty",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Кімнати подобово, погодинно",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 24,
    "path": "nedvizhimost/posutochno-pochasovo/posutochno-pochasovo-komnaty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 330,
    "parent": 206,
    "code": "arenda-domov",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Довгострокова оренда будинків",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "nedvizhimost/doma/arenda-domov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 331,
    "parent": 9,
    "code": "posutochno-pochasovo-doma",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Будинки подобово, погодинно",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 24,
    "path": "nedvizhimost/posutochno-pochasovo/posutochno-pochasovo-doma",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 364,
    "parent": 35,
    "code": "ptitsy",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пташки",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zhivotnye/ptitsy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 365,
    "parent": 35,
    "code": "gryzuny",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Гризуни",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zhivotnye/gryzuny",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 366,
    "parent": 35,
    "code": "reptilii",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Рептилії",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zhivotnye/reptilii",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 367,
    "parent": 35,
    "code": "akvariumnye-rybki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Акваріумістика",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zhivotnye/akvariumnye-rybki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 368,
    "parent": 35,
    "code": "selskohozyaystvennye-zhivotnye",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сільгосп тварини",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zhivotnye/selskohozyaystvennye-zhivotnye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 369,
    "parent": 37,
    "code": "tehnika-dlya-kuhni",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Техніка для кухні",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-kuhni",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      370,
      371,
      372,
      373,
      374,
      375,
      376,
      378,
      380,
      381,
      382
    ]
  },
  {
    "category_id": 370,
    "parent": 369,
    "code": "holodilniki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Холодильники",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-kuhni/holodilniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 371,
    "parent": 369,
    "code": "mikrovolnovye-pechi",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Мікрохвильові печі",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-kuhni/mikrovolnovye-pechi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 372,
    "parent": 369,
    "code": "plity-pechi",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Плити / печі",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-kuhni/plity-pechi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 373,
    "parent": 369,
    "code": "elektrochayniki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Електрочайники",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-kuhni/elektrochayniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 374,
    "parent": 369,
    "code": "kofevarki-kofemolki",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Кавоварки / кавомолки",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-kuhni/kofevarki-kofemolki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 375,
    "parent": 369,
    "code": "kuhonnye-kombayny-i-izmelchiteli",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Кухонні комбайни та подрібнювачі",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-kuhni/kuhonnye-kombayny-i-izmelchiteli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 376,
    "parent": 369,
    "code": "parovarki",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Пароварки, мультиварки",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-kuhni/parovarki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 378,
    "parent": 369,
    "code": "hlebopechki",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Хлібопічки",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-kuhni/hlebopechki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 380,
    "parent": 369,
    "code": "posudomoechnye-mashiny",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Посудомийні машини",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-kuhni/posudomoechnye-mashiny",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 381,
    "parent": 369,
    "code": "vytyazhki",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Витяжки",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-kuhni/vytyazhki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 382,
    "parent": 369,
    "code": "prochaya-tehnika-dlya-kuhni",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Інша техніка для кухні",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-kuhni/prochaya-tehnika-dlya-kuhni",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 383,
    "parent": 37,
    "code": "tehnika-dlya-doma",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Техніка для дому",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-doma",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      76,
      384,
      385,
      386,
      387,
      388,
      389,
      3286,
      3287,
      3288
    ]
  },
  {
    "category_id": 384,
    "parent": 383,
    "code": "pylesosy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Пилососи",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-doma/pylesosy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 385,
    "parent": 383,
    "code": "utyugi",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Праски",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-doma/utyugi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 386,
    "parent": 383,
    "code": "stiralnye-mashiny",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Пральні машини",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-doma/stiralnye-mashiny",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 387,
    "parent": 383,
    "code": "shveynye-mashiny-i-overloki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Швейні машини та оверлоки",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-doma/shveynye-mashiny-i-overloki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 388,
    "parent": 383,
    "code": "vyazalnye-mashiny",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "В'язальні машини",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-doma/vyazalnye-mashiny",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 389,
    "parent": 383,
    "code": "filtry-dlya-vody",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Фільтри для води",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-doma/filtry-dlya-vody",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 390,
    "parent": 37,
    "code": "individualnyy-uhod",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Індивідуальний догляд",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "elektronika/individualnyy-uhod",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      391,
      392,
      393,
      394
    ]
  },
  {
    "category_id": 391,
    "parent": 390,
    "code": "britvy-epilyatory-mashinki-dlya-strizhki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Бритви, епілятори, машинки для стрижки",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/individualnyy-uhod/britvy-epilyatory-mashinki-dlya-strizhki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 392,
    "parent": 390,
    "code": "feny-ukladka-volos",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Фени, укладка волосся",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/individualnyy-uhod/feny-ukladka-volos",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 393,
    "parent": 390,
    "code": "vesy",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Ваги",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/individualnyy-uhod/vesy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 394,
    "parent": 390,
    "code": "prochaya-tehnika-dlya-individualnogo-uhoda",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Інша техніка для індивідуального догляду",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/individualnyy-uhod/prochaya-tehnika-dlya-individualnogo-uhoda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 395,
    "parent": 37,
    "code": "klimaticheskoe-oborudovanie",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Кліматичне обладнання",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "elektronika/klimaticheskoe-oborudovanie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 401,
    "parent": 37,
    "code": "aksessuary-i-komplektuyuschie",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Аксесуари й комплектуючі",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "elektronika/aksessuary-i-komplektuyuschie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 402,
    "parent": 37,
    "code": "audiotehnika",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Аудіотехніка",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "elektronika/audiotehnika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      72,
      404,
      405,
      409,
      410,
      413,
      414,
      415,
      416,
      419
    ]
  },
  {
    "category_id": 404,
    "parent": 402,
    "code": "naushniki",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Навушники",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/audiotehnika/naushniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 405,
    "parent": 402,
    "code": "portativnaya-akustika",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Портативна акустика",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/audiotehnika/portativnaya-akustika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 409,
    "parent": 402,
    "code": "magnitoly",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Магнітоли",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/audiotehnika/magnitoly",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 410,
    "parent": 402,
    "code": "radiopriemniki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Радіоприймачі",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/audiotehnika/radiopriemniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 413,
    "parent": 402,
    "code": "muzykalnye-tsentry",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Музичні центри",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/audiotehnika/muzykalnye-tsentry",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 414,
    "parent": 402,
    "code": "akusticheskie-sistemy",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Акустичні системи",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/audiotehnika/akusticheskie-sistemy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 415,
    "parent": 402,
    "code": "usiliteli-resivery",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Підсилювачі / ресивери",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/audiotehnika/usiliteli-resivery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 416,
    "parent": 402,
    "code": "cd-md-vinilovye-proigryvateli",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Cd / md / вінілові програвачі",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/audiotehnika/cd-md-vinilovye-proigryvateli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 419,
    "parent": 402,
    "code": "prochaya-audiotehnika",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Інша аудіотехніка",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/audiotehnika/prochaya-audiotehnika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 420,
    "parent": 37,
    "code": "tv-videotehnika",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Тв / відеотехніка",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "elektronika/tv-videotehnika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      75,
      421,
      424,
      426,
      428,
      1518
    ]
  },
  {
    "category_id": 421,
    "parent": 420,
    "code": "dvd-pleery",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Медіа програвачі",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tv-videotehnika/dvd-pleery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 424,
    "parent": 420,
    "code": "aksessuary-dlya-tv-videotehniki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Аксесуари для ТВ/Відеотехніки",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tv-videotehnika/aksessuary-dlya-tv-videotehniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 426,
    "parent": 420,
    "code": "sputnikovoe-tv",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Супутникове ТВ",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tv-videotehnika/sputnikovoe-tv",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 428,
    "parent": 420,
    "code": "prochaya-tv-videotehnika",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Інша ТВ / відеотехніка",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tv-videotehnika/prochaya-tv-videotehnika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 429,
    "parent": 37,
    "code": "foto-video",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Фото / відео",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "elektronika/foto-video",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      73,
      430,
      431,
      432,
      433,
      434,
      435,
      441,
      1500
    ]
  },
  {
    "category_id": 430,
    "parent": 429,
    "code": "plenochnye-fotoapparaty",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Плівкові фотоапарати",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/foto-video/plenochnye-fotoapparaty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 431,
    "parent": 429,
    "code": "videokamery",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Відеокамери",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/foto-video/videokamery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 432,
    "parent": 429,
    "code": "obektivy",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Об'єктиви",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/foto-video/obektivy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 433,
    "parent": 429,
    "code": "shtativy-monopody",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Штативи / моноподи",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/foto-video/shtativy-monopody",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 434,
    "parent": 429,
    "code": "fotovspyshki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Фотоспалахи",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/foto-video/fotovspyshki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 435,
    "parent": 429,
    "code": "aksessuary-dlya-foto-videokamer",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Аксесуари для фото / відеокамер",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/foto-video/aksessuary-dlya-foto-videokamer",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 441,
    "parent": 429,
    "code": "teleskopy-binokli",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Телескопи / біноклі",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/foto-video/teleskopy-binokli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 442,
    "parent": 44,
    "code": "aksessuary-dlya-telefonov",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Аксесуари для телефонів",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/telefony-i-aksesuary/aksessuary-dlya-telefonov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3272,
      3273,
      3274,
      3275,
      3276,
      3277,
      3278,
      3279,
      3280,
      3281,
      3282,
      3283,
      3284
    ]
  },
  {
    "category_id": 450,
    "parent": 44,
    "code": "statsionarnye-telefony",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Стаціонарні телефони",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/telefony-i-aksesuary/statsionarnye-telefony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 453,
    "parent": 38,
    "code": "servery",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Сервери",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/kompyutery-i-komplektuyuschie/servery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 458,
    "parent": 38,
    "code": "komplektuyuschie-i-aksesuary",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Комплектуючі та аксесуари",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/kompyutery-i-komplektuyuschie/komplektuyuschie-i-aksesuary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 466,
    "parent": 38,
    "code": "periferiynye-ustroystva",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Периферійні пристрої",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/kompyutery-i-komplektuyuschie/periferiynye-ustroystva",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 476,
    "parent": 38,
    "code": "vneshnie-nakopiteli",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Зовнішні накопичувачі",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/kompyutery-i-komplektuyuschie/vneshnie-nakopiteli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 480,
    "parent": 38,
    "code": "rashodnye-materialy",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Витратні матеріали",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/kompyutery-i-komplektuyuschie/rashodnye-materialy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 484,
    "parent": 55,
    "code": "aksessuary",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Аксесуари",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/igry-i-igrovye-pristavki/aksessuary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 485,
    "parent": 903,
    "code": "cd-dvd-plastinki",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "CD / DVD / Платівки",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/cd-dvd-plastinki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 489,
    "parent": 45,
    "code": "akusticheskie-gitary",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Акустичні гітари",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/muzykalnye-instrumenty/akusticheskie-gitary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 490,
    "parent": 45,
    "code": "elektrogitary",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Електрогітари",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/muzykalnye-instrumenty/elektrogitary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 491,
    "parent": 45,
    "code": "bas-gitary",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бас-гітари",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/muzykalnye-instrumenty/bas-gitary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 493,
    "parent": 45,
    "code": "kombousiliteli",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Комбопідсилювачі",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/muzykalnye-instrumenty/kombousiliteli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 495,
    "parent": 45,
    "code": "sintezatory",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Синтезатори",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/muzykalnye-instrumenty/sintezatory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 497,
    "parent": 45,
    "code": "duhovye-instrumenty",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Духові інструменти",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/muzykalnye-instrumenty/duhovye-instrumenty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 498,
    "parent": 45,
    "code": "udarnye-instrumenty",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ударні інструменти",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/muzykalnye-instrumenty/udarnye-instrumenty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 499,
    "parent": 45,
    "code": "studiynoe-oborudovanie",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Студійне обладнання",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/muzykalnye-instrumenty/studiynoe-oborudovanie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 509,
    "parent": 45,
    "code": "aksessuary-dlya-muzykalnyh-instrumentov",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Аксесуари для музичних інструментів",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/muzykalnye-instrumenty/aksessuary-dlya-muzykalnyh-instrumentov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 511,
    "parent": 57,
    "code": "mebel-dlya-gostinoy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Меблі для вітальні",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/mebel/mebel-dlya-gostinoy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 515,
    "parent": 57,
    "code": "mebel-dlya-spalni",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Меблі для спальні",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/mebel/mebel-dlya-spalni",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 522,
    "parent": 57,
    "code": "mebel-dlya-prihozhey",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Меблі для передпокою",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/mebel/mebel-dlya-prihozhey",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 523,
    "parent": 57,
    "code": "kuhonnaya-mebel",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кухонні меблі",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/mebel/kuhonnaya-mebel",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 524,
    "parent": 57,
    "code": "mebel-dlya-vannoy-komnaty",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Меблі для ванної кімнати",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/mebel/mebel-dlya-vannoy-komnaty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 525,
    "parent": 57,
    "code": "ofisnaya-mebel",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Офісні меблі",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/mebel/ofisnaya-mebel",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 529,
    "parent": 60,
    "code": "tekstil",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Текстиль",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/predmety-interera/tekstil",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 533,
    "parent": 60,
    "code": "dekor-okon",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Декор вікон",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/predmety-interera/dekor-okon",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 537,
    "parent": 899,
    "code": "hozyaystvennyy-inventar",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Господарський інвентар / побутова хімія",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "dom-i-sad/hozyaystvennyy-inventar",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 538,
    "parent": 36,
    "code": "detskie-avtokresla",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Дитячі автокрісла",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "detskiy-mir/detskie-avtokresla",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 539,
    "parent": 36,
    "code": "detskaya-odezhda",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Дитячий одяг",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "detskiy-mir/detskaya-odezhda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      70,
      540,
      541
    ]
  },
  {
    "category_id": 540,
    "parent": 539,
    "code": "odezhda-dlya-novorozhdennyh",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Одяг для новонароджених",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "detskiy-mir/detskaya-odezhda/odezhda-dlya-novorozhdennyh",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 541,
    "parent": 539,
    "code": "odezhda-dlya-malchikov",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Одяг для хлопчиків",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "detskiy-mir/detskaya-odezhda/odezhda-dlya-malchikov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 542,
    "parent": 36,
    "code": "detskaya-obuv",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Дитяче взуття",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "detskiy-mir/detskaya-obuv",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 543,
    "parent": 36,
    "code": "igrushki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Іграшки",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "detskiy-mir/igrushki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 544,
    "parent": 36,
    "code": "kormlenie",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Годування",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "detskiy-mir/kormlenie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 545,
    "parent": 36,
    "code": "tovary-dlya-shkolnikov",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Товари для школярів",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "detskiy-mir/tovary-dlya-shkolnikov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 552,
    "parent": 98,
    "code": "sumki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сумки",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sumki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3153,
      3163,
      3173,
      3181,
      3188,
      3195,
      3199,
      3202,
      3204,
      3206,
      3208,
      3210,
      3213
    ]
  },
  {
    "category_id": 553,
    "parent": 98,
    "code": "bizhuteriya",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Біжутерія",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/bizhuteriya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3154,
      3164,
      3174,
      3182,
      3189,
      3196,
      3200,
      3203,
      3205,
      3207,
      3209,
      3211,
      3212,
      3214
    ]
  },
  {
    "category_id": 556,
    "parent": 891,
    "code": "naruchnye-chasy",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Наручні годинники",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "moda-i-stil/naruchnye-chasy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 557,
    "parent": 98,
    "code": "yuvelirnye-izdeliya",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ювелірні вироби",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/yuvelirnye-izdeliya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3242,
      3243,
      3244,
      3245,
      3246,
      3247,
      3248,
      3249,
      3250,
      3251,
      3252,
      3253,
      3254
    ]
  },
  {
    "category_id": 568,
    "parent": 52,
    "code": "parfyumeriya",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Парфумерія",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/parfyumeriya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2728,
      2729,
      2730,
      2731,
      2732,
      2733,
      2734,
      2735
    ]
  },
  {
    "category_id": 570,
    "parent": 52,
    "code": "oborudovanie-parikmaherskih-salonov-krasoty",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Обладнання перукарень / салонів краси",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/oborudovanie-parikmaherskih-salonov-krasoty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3000,
      3001,
      3002,
      3003,
      3004,
      3005,
      3006
    ]
  },
  {
    "category_id": 571,
    "parent": 52,
    "code": "tovary-dlya-invalidov",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Товари для інвалідів",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/tovary-dlya-invalidov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2830,
      2831,
      2832,
      2833,
      2834,
      2835,
      2836
    ]
  },
  {
    "category_id": 572,
    "parent": 52,
    "code": "prochie-tovary-dlya-krasoty-i-zdorovya",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші товари для краси і здоров'я",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/prochie-tovary-dlya-krasoty-i-zdorovya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 573,
    "parent": 903,
    "code": "sport-otdyh",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спорт / відпочинок",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      40,
      582,
      583,
      584,
      590,
      596,
      597,
      602,
      603,
      604,
      605,
      607,
      608
    ]
  },
  {
    "category_id": 582,
    "parent": 573,
    "code": "konki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ковзани",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh/konki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 583,
    "parent": 573,
    "code": "rolikovye-konki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Роликові ковзани",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh/rolikovye-konki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 584,
    "parent": 573,
    "code": "atletika-fitnes",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Атлетика / фітнес",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh/atletika-fitnes",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 590,
    "parent": 573,
    "code": "turizm",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Туризм",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh/turizm",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3290,
      3291,
      3292,
      3293,
      3294,
      3295,
      3296,
      3297
    ]
  },
  {
    "category_id": 596,
    "parent": 573,
    "code": "ohota-rybalka",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Полювання / риболовля",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh/ohota-rybalka",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 597,
    "parent": 573,
    "code": "igry-s-raketkoy",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ігри з ракеткою",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh/igry-s-raketkoy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 602,
    "parent": 573,
    "code": "vodnye-vidy-sporta",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Водні види спорту",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh/vodnye-vidy-sporta",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 603,
    "parent": 573,
    "code": "futbol",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Футбол",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh/futbol",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 604,
    "parent": 573,
    "code": "hokkey",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Хокей",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh/hokkey",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 605,
    "parent": 573,
    "code": "edinoborstva-boks",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Єдиноборства / бокс",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh/edinoborstva-boks",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 607,
    "parent": 573,
    "code": "nastolnye-igry",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Настільні ігри",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh/nastolnye-igry",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 608,
    "parent": 573,
    "code": "prochie-vidy-sporta",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші види спорту",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh/prochie-vidy-sporta",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 611,
    "parent": 899,
    "code": "sadovyy-inventar",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Садовий інвентар",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "dom-i-sad/sadovyy-inventar",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 622,
    "parent": 53,
    "code": "antikvarnaya-mebel",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Антикварні меблі",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/antikvariat-kollektsii/antikvarnaya-mebel",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 623,
    "parent": 53,
    "code": "bukinistika",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Букіністика",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/antikvariat-kollektsii/bukinistika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 624,
    "parent": 53,
    "code": "zhivopis",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Живопис",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/antikvariat-kollektsii/zhivopis",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 625,
    "parent": 53,
    "code": "predmety-iskusstva",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Предмети мистецтва",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/antikvariat-kollektsii/predmety-iskusstva",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 626,
    "parent": 53,
    "code": "kollektsionirovanie",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Колекціонування",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/antikvariat-kollektsii/kollektsionirovanie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 631,
    "parent": 53,
    "code": "podelki-rukodelie",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Витвори майстрів / рукоділля",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/antikvariat-kollektsii/podelki-rukodelie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 632,
    "parent": 899,
    "code": "kantstovary-rashodnye-materialy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Канцтовари / витратні матеріали",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "dom-i-sad/kantstovary-rashodnye-materialy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 641,
    "parent": 899,
    "code": "stroitelstvo-remont",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Будівництво / ремонт",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "dom-i-sad/stroitelstvo-remont",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      642,
      643,
      644,
      645,
      646,
      647,
      648,
      649,
      650,
      651,
      652,
      653
    ]
  },
  {
    "category_id": 642,
    "parent": 641,
    "code": "santehnika",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сантехніка",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/stroitelstvo-remont/santehnika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 643,
    "parent": 641,
    "code": "ventilyatsiya",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Вентиляція",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/stroitelstvo-remont/ventilyatsiya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 644,
    "parent": 641,
    "code": "otoplenie",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Опалення",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/stroitelstvo-remont/otoplenie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 645,
    "parent": 641,
    "code": "elektrika",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Електрика",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/stroitelstvo-remont/elektrika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 646,
    "parent": 641,
    "code": "pilomaterialy",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пиломатеріали",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/stroitelstvo-remont/pilomaterialy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 647,
    "parent": 641,
    "code": "otdelochnye-i-oblitsovochnye-materialy",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Оздоблювальні та облицювальні матеріали",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/stroitelstvo-remont/otdelochnye-i-oblitsovochnye-materialy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 648,
    "parent": 641,
    "code": "okna-steklo-zerkala",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Вікна / двері / скло / дзеркала",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/stroitelstvo-remont/okna-steklo-zerkala",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 649,
    "parent": 641,
    "code": "lakokrasochnye-materialy",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Лакофарбові матеріали",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/stroitelstvo-remont/lakokrasochnye-materialy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 650,
    "parent": 641,
    "code": "metalloprokat-armatura",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Металопрокат / арматура",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/stroitelstvo-remont/metalloprokat-armatura",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 651,
    "parent": 641,
    "code": "elementy-krepezha",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Елементи кріплення",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/stroitelstvo-remont/elementy-krepezha",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 652,
    "parent": 641,
    "code": "kirpich-beton-penobloki",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Цегла / бетон / піноблоки",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/stroitelstvo-remont/kirpich-beton-penobloki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 653,
    "parent": 641,
    "code": "prochie-stroymaterialy",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші будматеріали",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/stroitelstvo-remont/prochie-stroymaterialy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 654,
    "parent": 899,
    "code": "instrumenty",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інструменти",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "dom-i-sad/instrumenty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      655,
      656,
      657,
      658,
      659,
      3285
    ]
  },
  {
    "category_id": 655,
    "parent": 654,
    "code": "ruchnoy-instrument",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ручний інструмент",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/instrumenty/ruchnoy-instrument",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 656,
    "parent": 654,
    "code": "benzoinstrument",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бензоінструмент",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/instrumenty/benzoinstrument",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 657,
    "parent": 654,
    "code": "elektroinstrument",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Електроінструмент",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/instrumenty/elektroinstrument",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 658,
    "parent": 654,
    "code": "pnevmoinstrument",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пневмоінструмент",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/instrumenty/pnevmoinstrument",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 659,
    "parent": 654,
    "code": "prochiy-instrument",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інший інструмент",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/instrumenty/prochiy-instrument",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 667,
    "parent": 899,
    "code": "posuda-kuhonnaya-utvar",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Посуд / кухонне приладдя",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "dom-i-sad/posuda-kuhonnaya-utvar",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 726,
    "parent": 7,
    "code": "syre-materialy",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Сировина / матеріали",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "uslugi/syre-materialy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 767,
    "parent": 7,
    "code": "finansovye-uslugi",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Фінансові послуги / партнерство",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "uslugi/finansovye-uslugi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 773,
    "parent": 7,
    "code": "reklama-marketing-pr",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Реклама / поліграфія / маркетинг / інтернет",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "uslugi/reklama-marketing-pr",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 810,
    "parent": 7,
    "code": "setevoy-marketing",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Мережевий маркетинг",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "uslugi/setevoy-marketing",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 813,
    "parent": 7,
    "code": "prokat-tovarov",
    "position": 19,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Прокат товарів",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "uslugi/prokat-tovarov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 822,
    "parent": 188,
    "code": "stroitelnye-uslugi",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Будівельні послуги",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/stroitelstvo-otdelka-remont/stroitelnye-uslugi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 823,
    "parent": 188,
    "code": "dizayn-arhitektura",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Дизайн / архітектура",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/stroitelstvo-otdelka-remont/dizayn-arhitektura",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 824,
    "parent": 188,
    "code": "otdelka-remont",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Оздоблення / ремонт",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/stroitelstvo-otdelka-remont/otdelka-remont",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 825,
    "parent": 188,
    "code": "okna-dveri-balkony",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Вікна / двері / балкони",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/stroitelstvo-otdelka-remont/okna-dveri-balkony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 826,
    "parent": 188,
    "code": "santehnika-kommunikatsii",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Сантехніка / комунікації",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/stroitelstvo-otdelka-remont/santehnika-kommunikatsii",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 827,
    "parent": 188,
    "code": "ventilyatsiya-konditsionirovanie",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Вентиляція / кондиціонування",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/stroitelstvo-otdelka-remont/ventilyatsiya-konditsionirovanie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 828,
    "parent": 188,
    "code": "elektrika",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Електрика",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/stroitelstvo-otdelka-remont/elektrika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 829,
    "parent": 188,
    "code": "gotovye-konstruktsii",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Готові конструкції",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/stroitelstvo-otdelka-remont/gotovye-konstruktsii",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 836,
    "parent": 7,
    "code": "remont-i-obsluzhivanie-tehniki",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Ремонт та обслуговування техніки",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "uslugi/remont-i-obsluzhivanie-tehniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      87,
      104,
      1484,
      1486,
      1488,
      1490,
      1492,
      1494,
      1496
    ]
  },
  {
    "category_id": 851,
    "parent": 193,
    "code": "strizhki-naraschivanie-volos",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Стрижки / нарощування волосся",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/krasota-zdorove/strizhki-naraschivanie-volos",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 852,
    "parent": 193,
    "code": "manikyur-naraschivanie-nogtey",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Манікюр / нарощування нігтів",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/krasota-zdorove/manikyur-naraschivanie-nogtey",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 853,
    "parent": 193,
    "code": "makiyazh-kosmetologiya-naraschivanie-resnits",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Макіяж / косметологія / нарощування вій",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/krasota-zdorove/makiyazh-kosmetologiya-naraschivanie-resnits",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 854,
    "parent": 193,
    "code": "meditsina",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Медицина",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/krasota-zdorove/meditsina",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 855,
    "parent": 193,
    "code": "massazh",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Масаж",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/krasota-zdorove/massazh",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 856,
    "parent": 193,
    "code": "krasota-zdorove-prochee",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Краса / здоров'я - інше",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/krasota-zdorove/krasota-zdorove-prochee",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 889,
    "parent": 36,
    "code": "detskiy-transport",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Дитячий транспорт",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "detskiy-mir/detskiy-transport",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 891,
    "parent": 0,
    "code": "moda-i-stil",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "fashion",
    "type": "goods",
    "name": "Мода і стиль",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 1,
    "max_photos": 8,
    "path": "moda-i-stil",
    "icon": {
      "image_url": "https://categories.olxcdn.com/assets/categories/olxua/moda-i-stil-891-1x.png",
      "big_image_url": "https://categories.olxcdn.com/assets/categories/olxua/moda-i-stil-891-2x.png"
    },
    "children": [
      52,
      97,
      98,
      556,
      897,
      1427,
      2010,
      2011,
      2012,
      2013,
      2014,
      2015,
      2016,
      2107,
      3223
    ]
  },
  {
    "category_id": 893,
    "parent": 98,
    "code": "drugie-aksessuary",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші аксесуари",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/drugie-aksessuary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 897,
    "parent": 891,
    "code": "podarki",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Подарунки",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "moda-i-stil/podarki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2338,
      2339,
      2340,
      2341,
      2342,
      2343,
      2344,
      2345,
      2346,
      2347,
      2348,
      2349,
      2350,
      2351,
      2356
    ]
  },
  {
    "category_id": 899,
    "parent": 0,
    "code": "dom-i-sad",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "home",
    "type": "goods",
    "name": "Дім і сад",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 1,
    "max_photos": 8,
    "path": "dom-i-sad",
    "icon": {
      "image_url": "https://categories.olxcdn.com/assets/categories/olxua/dom-i-sad-899-1x.png",
      "big_image_url": "https://categories.olxcdn.com/assets/categories/olxua/dom-i-sad-899-2x.png"
    },
    "children": [
      48,
      57,
      60,
      63,
      93,
      537,
      611,
      632,
      641,
      654,
      667,
      1433
    ]
  },
  {
    "category_id": 903,
    "parent": 0,
    "code": "hobbi-otdyh-i-sport",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "sport",
    "type": "goods",
    "name": "Хобі, відпочинок і спорт",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 1,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport",
    "icon": {
      "image_url": "https://categories.olxcdn.com/assets/categories/olxua/hobbi-otdyh-i-sport-903-1x.png",
      "big_image_url": "https://categories.olxcdn.com/assets/categories/olxua/hobbi-otdyh-i-sport-903-2x.png"
    },
    "children": [
      45,
      49,
      53,
      54,
      115,
      118,
      485,
      573,
      1425,
      3352,
      3415,
      3421
    ]
  },
  {
    "category_id": 963,
    "parent": 108,
    "code": "acura",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Acura",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/acura",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 965,
    "parent": 108,
    "code": "alfa-romeo",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Alfa Romeo",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/alfa-romeo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 969,
    "parent": 108,
    "code": "audi",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Audi",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/audi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 973,
    "parent": 108,
    "code": "bmw",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "BMW",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/bmw",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 981,
    "parent": 108,
    "code": "chery",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Chery",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/chery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 983,
    "parent": 108,
    "code": "chevrolet",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Chevrolet",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/chevrolet",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 985,
    "parent": 108,
    "code": "chrysler",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Chrysler",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/chrysler",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 987,
    "parent": 108,
    "code": "citroen",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Citroen",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/citroen",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 989,
    "parent": 108,
    "code": "dacia",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Dacia",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/dacia",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 991,
    "parent": 108,
    "code": "daewoo",
    "position": 19,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Daewoo",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/daewoo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 997,
    "parent": 108,
    "code": "dodge",
    "position": 21,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Dodge",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/dodge",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1007,
    "parent": 108,
    "code": "fiat",
    "position": 24,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Fiat",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/fiat",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1009,
    "parent": 108,
    "code": "ford",
    "position": 25,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Ford",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/ford",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1011,
    "parent": 108,
    "code": "geely",
    "position": 26,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Geely",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/geely",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1015,
    "parent": 108,
    "code": "great-wall",
    "position": 28,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Great Wall",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/great-wall",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1019,
    "parent": 108,
    "code": "honda",
    "position": 30,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Honda",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/honda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1023,
    "parent": 108,
    "code": "hyundai",
    "position": 32,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Hyundai",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/hyundai",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1035,
    "parent": 108,
    "code": "jeep",
    "position": 38,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Jeep",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/jeep",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1037,
    "parent": 108,
    "code": "kia",
    "position": 39,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Kia",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/kia",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1043,
    "parent": 108,
    "code": "land-rover",
    "position": 42,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Land Rover",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/land-rover",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1045,
    "parent": 108,
    "code": "lexus",
    "position": 43,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Lexus",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/lexus",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1053,
    "parent": 108,
    "code": "mazda",
    "position": 49,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Mazda",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/mazda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1055,
    "parent": 108,
    "code": "mercedes-benz",
    "position": 51,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Mercedes-Benz",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/mercedes-benz",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1063,
    "parent": 108,
    "code": "mitsubishi",
    "position": 55,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Mitsubishi",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/mitsubishi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1065,
    "parent": 108,
    "code": "nissan",
    "position": 56,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Nissan",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/nissan",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1067,
    "parent": 108,
    "code": "oldsmobile",
    "position": 57,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Oldsmobile",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/oldsmobile",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1069,
    "parent": 108,
    "code": "opel",
    "position": 58,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Opel",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/opel",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1071,
    "parent": 108,
    "code": "peugeot",
    "position": 59,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Peugeot",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/peugeot",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1077,
    "parent": 108,
    "code": "porsche",
    "position": 61,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Porsche",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/porsche",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1081,
    "parent": 108,
    "code": "renault",
    "position": 65,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Renault",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/renault",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1093,
    "parent": 108,
    "code": "seat",
    "position": 72,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Seat",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/seat",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1095,
    "parent": 108,
    "code": "skoda",
    "position": 74,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Skoda",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/skoda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1097,
    "parent": 108,
    "code": "smart",
    "position": 75,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Smart",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/smart",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1101,
    "parent": 108,
    "code": "ssangyong",
    "position": 76,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "SsangYong",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/ssangyong",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1103,
    "parent": 108,
    "code": "subaru",
    "position": 77,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Subaru",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/subaru",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1105,
    "parent": 108,
    "code": "suzuki",
    "position": 78,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Suzuki",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/suzuki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1107,
    "parent": 108,
    "code": "toyota",
    "position": 81,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Toyota",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/toyota",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1109,
    "parent": 108,
    "code": "volkswagen",
    "position": 82,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Volkswagen",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/volkswagen",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1111,
    "parent": 108,
    "code": "volvo",
    "position": 83,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Volvo",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/volvo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1115,
    "parent": 108,
    "code": "vaz",
    "position": 87,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "ВАЗ",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/vaz",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1117,
    "parent": 108,
    "code": "gaz",
    "position": 88,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "ГАЗ",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/gaz",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1119,
    "parent": 108,
    "code": "zaz",
    "position": 89,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "ЗАЗ",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/zaz",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1121,
    "parent": 108,
    "code": "izh",
    "position": 90,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "ИЖ",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/izh",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1123,
    "parent": 108,
    "code": "moskvich-azlk",
    "position": 92,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Москвич / АЗЛК",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/moskvich-azlk",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1131,
    "parent": 108,
    "code": "uaz",
    "position": 94,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "УАЗ",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/uaz",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1133,
    "parent": 108,
    "code": "drugie",
    "position": 95,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/drugie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1151,
    "parent": 0,
    "code": "otdam-darom",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "forfree",
    "type": "goods",
    "name": "Віддам безкоштовно",
    "view_type": "grid",
    "is_addable": false,
    "is_searchable": true,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 1,
    "max_photos": 8,
    "path": "otdam-darom",
    "icon": {
      "image_url": "https://categories.olxcdn.com/assets/categories/olxua/otdam-darom-1151-1x.png",
      "big_image_url": "https://categories.olxcdn.com/assets/categories/olxua/otdam-darom-1151-2x.png"
    }
  },
  {
    "category_id": 1153,
    "parent": 0,
    "code": "obmen-barter",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "exchange",
    "type": "goods",
    "name": "Обмін",
    "view_type": "grid",
    "is_addable": false,
    "is_searchable": true,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 1,
    "max_photos": 8,
    "path": "obmen-barter",
    "icon": {
      "image_url": "https://categories.olxcdn.com/assets/categories/olxua/obmen-barter-1153-1x.png",
      "big_image_url": "https://categories.olxcdn.com/assets/categories/olxua/obmen-barter-1153-2x.png"
    }
  },
  {
    "category_id": 1159,
    "parent": 6,
    "code": "nedvizhimost",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Нерухомість",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/nedvizhimost",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1791,
      1792,
      1793,
      1794
    ]
  },
  {
    "category_id": 1161,
    "parent": 55,
    "code": "igry-dlya-pc",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Ігри для PC",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/igry-i-igrovye-pristavki/igry-dlya-pc",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1165,
    "parent": 6,
    "code": "nachalo-karery-studenty",
    "position": 19,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Початок кар'єри / Студенти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/nachalo-karery-studenty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1175,
    "parent": 193,
    "code": "psihologiya",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Послуги психолога",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/krasota-zdorove/psihologiya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1361,
    "parent": 108,
    "code": "roewe",
    "position": 67,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Roewe",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/roewe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1413,
    "parent": 108,
    "code": "brilliance",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Brilliance",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/brilliance",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1425,
    "parent": 903,
    "code": "drugoe",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1427,
    "parent": 891,
    "code": "moda-raznoe",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Мода різне",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "moda-i-stil/moda-raznoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1429,
    "parent": 45,
    "code": "prochee",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/muzykalnye-instrumenty/prochee",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1431,
    "parent": 37,
    "code": "prochaja-electronika",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Інша електроніка",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "elektronika/prochaja-electronika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1433,
    "parent": 899,
    "code": "sad-ogorod",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сад / город",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "dom-i-sad/sad-ogorod",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1459,
    "parent": 270,
    "code": "avtoshiny",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автошини",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/shiny-diski-i-kolesa/avtoshiny",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1461,
    "parent": 270,
    "code": "motoshiny",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мотошини",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/shiny-diski-i-kolesa/motoshiny",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1463,
    "parent": 270,
    "code": "diski",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Диски",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/shiny-diski-i-kolesa/diski",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1465,
    "parent": 270,
    "code": "kolesa-v-sbore",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Колеса в зборі",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/shiny-diski-i-kolesa/kolesa-v-sbore",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1467,
    "parent": 270,
    "code": "kolpaki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Ковпаки",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/shiny-diski-i-kolesa/kolpaki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1473,
    "parent": 6,
    "code": "upravleniye-personalom-hr",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Адміністративний персонал / HR / Секретаріат",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/upravleniye-personalom-hr",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1857,
      1858,
      1859,
      1860,
      1861
    ]
  },
  {
    "category_id": 1475,
    "parent": 6,
    "code": "banki-finansy-strakhovaniye",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Банки / фінанси / страхування / юриспруденція",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/banki-finansy-strakhovaniye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1795,
      1798
    ]
  },
  {
    "category_id": 1477,
    "parent": 6,
    "code": "telekommunikatsii-svyaz",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Колл-центри / Телекомунікації",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/telekommunikatsii-svyaz",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1827,
      1828,
      1829
    ]
  },
  {
    "category_id": 1479,
    "parent": 6,
    "code": "selskoye-khozyaystvo-agrobiznes-lesnoye-khozyaystvo",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Сільське і лісове господарство / агробізнес",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/selskoye-khozyaystvo-agrobiznes-lesnoye-khozyaystvo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1862,
      1863,
      1864,
      1865
    ]
  },
  {
    "category_id": 1481,
    "parent": 6,
    "code": "rabota-za-rubezhom",
    "position": 20,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Робота за кордоном",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/rabota-za-rubezhom",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1893,
      1894,
      1895,
      1896,
      1897,
      1898,
      1899,
      1900,
      1901
    ]
  },
  {
    "category_id": 1482,
    "parent": 44,
    "code": "zapchasti-dlya-telefonov",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Запчастини для телефонів",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/telefony-i-aksesuary/zapchasti-dlya-telefonov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1484,
    "parent": 836,
    "code": "remont-i-obsluzhivanie-klimatycheskoi-tekhniki",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Кліматична техніка",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/remont-i-obsluzhivanie-tehniki/remont-i-obsluzhivanie-klimatycheskoi-tekhniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1486,
    "parent": 836,
    "code": "remont-i-obsluzhivanie-kompyuterov",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Комп'ютери",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/remont-i-obsluzhivanie-tehniki/remont-i-obsluzhivanie-kompyuterov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1488,
    "parent": 836,
    "code": "remont-i-obsluzhivanie-foto-i-videoapparatury",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Фото і відеоапаратура",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/remont-i-obsluzhivanie-tehniki/remont-i-obsluzhivanie-foto-i-videoapparatury",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1490,
    "parent": 836,
    "code": "remont-i-obsluzhivanie-tv-i-videotekhniki",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Тв і відеотехніка",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/remont-i-obsluzhivanie-tehniki/remont-i-obsluzhivanie-tv-i-videotekhniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1492,
    "parent": 836,
    "code": "remont-i-obsluzhivanie-audiotekhniki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Аудіотехніка",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/remont-i-obsluzhivanie-tehniki/remont-i-obsluzhivanie-audiotekhniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1494,
    "parent": 836,
    "code": "remont-i-obsluzhivanie-bytovoi-tekhniki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Побутова техніка",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/remont-i-obsluzhivanie-tehniki/remont-i-obsluzhivanie-bytovoi-tekhniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1496,
    "parent": 836,
    "code": "remont-i-obsluzhivanie-prochei-tekhniki",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "",
    "name": "Інша техніка",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/remont-i-obsluzhivanie-tehniki/remont-i-obsluzhivanie-prochei-tekhniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1500,
    "parent": 429,
    "code": "ekshn-kamery",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Екшн-камери",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/foto-video/ekshn-kamery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1502,
    "parent": 37,
    "code": "noutbuki-i-aksesuary",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Ноутбуки та аксесуари",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "elektronika/noutbuki-i-aksesuary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      80,
      1504,
      1506
    ]
  },
  {
    "category_id": 1504,
    "parent": 1502,
    "code": "zapchasti-dlya-noutbukov",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Запчастини для ноутбуків",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/noutbuki-i-aksesuary/zapchasti-dlya-noutbukov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1506,
    "parent": 1502,
    "code": "aksessuary-dlya-noutbukov",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Аксесуари для ноутбуків",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/noutbuki-i-aksesuary/aksessuary-dlya-noutbukov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1508,
    "parent": 37,
    "code": "planshety-el-knigi-i-aksessuary",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Планшети / ел. книги та аксесуари",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "elektronika/planshety-el-knigi-i-aksessuary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      81,
      1510,
      1512,
      1514,
      1516
    ]
  },
  {
    "category_id": 1510,
    "parent": 1508,
    "code": "elektronnye-knigi",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Електронні книги",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/planshety-el-knigi-i-aksessuary/elektronnye-knigi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1512,
    "parent": 1508,
    "code": "zapchasti-dlya-planshetov-el-knig",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Запчастини для планшетів / эл. книг",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/planshety-el-knigi-i-aksessuary/zapchasti-dlya-planshetov-el-knig",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1514,
    "parent": 1508,
    "code": "aksessuary-dlya-planshetov-el-knig",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Аксесуари планшетов / эл. книг",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/planshety-el-knigi-i-aksessuary/aksessuary-dlya-planshetov-el-knig",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1516,
    "parent": 1508,
    "code": "graficheskiye-planshety",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Графічні планшети",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/planshety-el-knigi-i-aksessuary/graficheskiye-planshety",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1518,
    "parent": 420,
    "code": "proektory",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Проектори",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tv-videotehnika/proektory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1520,
    "parent": 35,
    "code": "besplatno-zhivotnye-i-vyazka",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Безкоштовно (тварини і в'язка)",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zhivotnye/besplatno-zhivotnye-i-vyazka",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1522,
    "parent": 1,
    "code": "nedvizhimost-za-rubezhom",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Нерухомість за кордоном",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 24,
    "path": "nedvizhimost/nedvizhimost-za-rubezhom",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1532,
    "parent": 0,
    "code": "transport",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "automotive",
    "type": "automotive",
    "name": "Авто",
    "view_type": "grid",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 1,
    "max_photos": 24,
    "path": "transport",
    "icon": {
      "image_url": "https://categories.olxcdn.com/assets/categories/olxua/transport-1532-1x.png",
      "big_image_url": "https://categories.olxcdn.com/assets/categories/olxua/transport-1532-2x.png"
    },
    "children": [
      108,
      109,
      110,
      222,
      225,
      253,
      261,
      273,
      304,
      1701,
      1748
    ]
  },
  {
    "category_id": 1534,
    "parent": 108,
    "code": "infiniti",
    "position": 33,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Infiniti",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/infiniti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1536,
    "parent": 108,
    "code": "luaz",
    "position": 91,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "ЛуАЗ",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/luaz",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1538,
    "parent": 108,
    "code": "jaguar",
    "position": 37,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Jaguar",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/jaguar",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1540,
    "parent": 108,
    "code": "mini",
    "position": 54,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "MINI",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/mini",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1542,
    "parent": 108,
    "code": "rover",
    "position": 68,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Rover",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/rover",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1544,
    "parent": 108,
    "code": "bentley",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Bentley",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/bentley",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1546,
    "parent": 108,
    "code": "cadillac",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Cadillac",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/cadillac",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1548,
    "parent": 108,
    "code": "lincoln",
    "position": 45,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Lincoln",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/lincoln",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1550,
    "parent": 108,
    "code": "iveco",
    "position": 35,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Iveco",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/iveco",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1552,
    "parent": 108,
    "code": "lancia",
    "position": 41,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Lancia",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/lancia",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1554,
    "parent": 108,
    "code": "saab",
    "position": 69,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Saab",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/saab",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1556,
    "parent": 108,
    "code": "tesla",
    "position": 80,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Tesla",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/tesla",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1558,
    "parent": 108,
    "code": "byd",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "BYD",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/byd",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1560,
    "parent": 108,
    "code": "daihatsu",
    "position": 20,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Daihatsu",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/daihatsu",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1562,
    "parent": 108,
    "code": "ravon",
    "position": 64,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Ravon",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/ravon",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1564,
    "parent": 108,
    "code": "lifan",
    "position": 44,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Lifan",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/lifan",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1566,
    "parent": 108,
    "code": "mg",
    "position": 53,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "MG",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/mg",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1568,
    "parent": 108,
    "code": "bogdan",
    "position": 86,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Богдан",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/bogdan",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1570,
    "parent": 108,
    "code": "isuzu",
    "position": 34,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Isuzu",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/isuzu",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1572,
    "parent": 108,
    "code": "samand",
    "position": 70,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Samand",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/samand",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1574,
    "parent": 108,
    "code": "dadi",
    "position": 18,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Dadi",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/dadi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1576,
    "parent": 108,
    "code": "maserati",
    "position": 47,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Maserati",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/maserati",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1578,
    "parent": 108,
    "code": "wartburg",
    "position": 84,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Wartburg",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/wartburg",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1580,
    "parent": 108,
    "code": "raf",
    "position": 93,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "РАФ",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/raf",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1582,
    "parent": 108,
    "code": "hummer",
    "position": 31,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Hummer",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/hummer",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1584,
    "parent": 108,
    "code": "pontiac",
    "position": 60,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Pontiac",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/pontiac",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1586,
    "parent": 108,
    "code": "jac",
    "position": 36,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "JAC",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/jac",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1588,
    "parent": 108,
    "code": "ferrari",
    "position": 23,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Ferrari",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/ferrari",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1590,
    "parent": 108,
    "code": "zx",
    "position": 85,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "ZX",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/zx",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1592,
    "parent": 108,
    "code": "chana",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Chana",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/chana",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1594,
    "parent": 108,
    "code": "gmc",
    "position": 27,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "GMC",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/gmc",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1602,
    "parent": 206,
    "code": "prodazha-domov",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Продаж будинків",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "nedvizhimost/doma/prodazha-domov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1606,
    "parent": 1,
    "code": "zemlya",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Земля",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 24,
    "path": "nedvizhimost/zemlya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      20,
      1608
    ]
  },
  {
    "category_id": 1608,
    "parent": 1606,
    "code": "prodazha-zemli",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Продаж землі",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "nedvizhimost/zemlya/prodazha-zemli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1610,
    "parent": 1,
    "code": "kommercheskaya-nedvizhimost",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Комерційна нерухомість",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 24,
    "path": "nedvizhimost/kommercheskaya-nedvizhimost",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1612,
      1614,
      3298
    ]
  },
  {
    "category_id": 1612,
    "parent": 1610,
    "code": "prodazha-kommercheskoy-nedvizhimosti",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Продаж комерційної нерухомості",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 24,
    "path": "nedvizhimost/kommercheskaya-nedvizhimost/prodazha-kommercheskoy-nedvizhimosti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1614,
    "parent": 1610,
    "code": "arenda-kommercheskoy-nedvizhimosti",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Оренда комерційної нерухомості",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 24,
    "path": "nedvizhimost/kommercheskaya-nedvizhimost/arenda-kommercheskoy-nedvizhimosti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1616,
    "parent": 1,
    "code": "garazhy-parkovki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Гаражі, парковки",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 24,
    "path": "nedvizhimost/garazhy-parkovki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      21,
      28
    ]
  },
  {
    "category_id": 1618,
    "parent": 9,
    "code": "posutochno-pochasovo-kvartiry",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Квартири подобово, погодинно",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 24,
    "path": "nedvizhimost/posutochno-pochasovo/posutochno-pochasovo-kvartiry",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1620,
    "parent": 9,
    "code": "posutochno-pochasovo-oteli",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Готелі, бази відпочинку",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 24,
    "path": "nedvizhimost/posutochno-pochasovo/posutochno-pochasovo-oteli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1622,
    "parent": 9,
    "code": "predlozheniya-turoperatorov",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Пропозиції Туроператорів",
    "view_type": "gallery",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 24,
    "path": "nedvizhimost/posutochno-pochasovo/predlozheniya-turoperatorov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1701,
    "parent": 1532,
    "code": "avtomobili-iz-polshi",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автомобілі з Польщі",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1702,
      1703,
      1704,
      1705,
      1706,
      1707,
      1708,
      1709,
      1710,
      1711,
      1712,
      1713,
      1714,
      1715,
      1716,
      1717,
      1718,
      1719,
      1720,
      1721,
      1722,
      1723,
      1724,
      1725,
      1726,
      1727,
      1728,
      1729,
      1730,
      1731,
      1732,
      1733,
      1734,
      1735,
      1736,
      1737,
      1738,
      1739,
      1740,
      1741,
      1742,
      1743,
      1744,
      1745,
      1746
    ]
  },
  {
    "category_id": 1702,
    "parent": 1701,
    "code": "alfa-romeo",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Alfa Romeo",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/alfa-romeo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1703,
    "parent": 1701,
    "code": "audi",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Audi",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/audi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1704,
    "parent": 1701,
    "code": "bmw",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "BMW",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/bmw",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1705,
    "parent": 1701,
    "code": "chevrolet",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Chevrolet",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/chevrolet",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1706,
    "parent": 1701,
    "code": "chrysler",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Chrysler",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/chrysler",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1707,
    "parent": 1701,
    "code": "citroen",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Citroen",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/citroen",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1708,
    "parent": 1701,
    "code": "daewoo",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Daewoo",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/daewoo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1709,
    "parent": 1701,
    "code": "fiat",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Fiat",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/fiat",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1710,
    "parent": 1701,
    "code": "ford",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Ford",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/ford",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1711,
    "parent": 1701,
    "code": "honda",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Honda",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/honda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1712,
    "parent": 1701,
    "code": "hyundai",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Hyundai",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/hyundai",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1713,
    "parent": 1701,
    "code": "kia",
    "position": 20,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Kia",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/kia",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1714,
    "parent": 1701,
    "code": "lancia",
    "position": 21,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Lancia",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/lancia",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1715,
    "parent": 1701,
    "code": "mazda",
    "position": 24,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Mazda",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/mazda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1716,
    "parent": 1701,
    "code": "mercedes-benz",
    "position": 25,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Mercedes-Benz",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/mercedes-benz",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1717,
    "parent": 1701,
    "code": "mitsubishi",
    "position": 27,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Mitsubishi",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/mitsubishi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1718,
    "parent": 1701,
    "code": "nissan",
    "position": 28,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Nissan",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/nissan",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1719,
    "parent": 1701,
    "code": "opel",
    "position": 29,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Opel",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/opel",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1720,
    "parent": 1701,
    "code": "peugeot",
    "position": 30,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Peugeot",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/peugeot",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1721,
    "parent": 1701,
    "code": "renault",
    "position": 33,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Renault",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/renault",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1722,
    "parent": 1701,
    "code": "saab",
    "position": 35,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Saab",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/saab",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1723,
    "parent": 1701,
    "code": "seat",
    "position": 36,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Seat",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/seat",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1724,
    "parent": 1701,
    "code": "skoda",
    "position": 37,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Skoda",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/skoda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1725,
    "parent": 1701,
    "code": "subaru",
    "position": 40,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Subaru",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/subaru",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1726,
    "parent": 1701,
    "code": "suzuki",
    "position": 41,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Suzuki",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/suzuki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1727,
    "parent": 1701,
    "code": "toyota",
    "position": 42,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Toyota",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/toyota",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1728,
    "parent": 1701,
    "code": "volkswagen",
    "position": 43,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Volkswagen",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/volkswagen",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1729,
    "parent": 1701,
    "code": "volvo",
    "position": 44,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Volvo",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/volvo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1730,
    "parent": 1701,
    "code": "pozostale",
    "position": 45,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Pozostałe osobowe",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/pozostale",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1731,
    "parent": 1701,
    "code": "rover",
    "position": 34,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Rover",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/rover",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1732,
    "parent": 1701,
    "code": "landrover",
    "position": 22,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Land Rover",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/landrover",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1733,
    "parent": 1701,
    "code": "jeep",
    "position": 19,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Jeep",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/jeep",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1734,
    "parent": 1701,
    "code": "polonez",
    "position": 31,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Polonez",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/polonez",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1735,
    "parent": 1701,
    "code": "smart",
    "position": 38,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Smart",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/smart",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1736,
    "parent": 1701,
    "code": "mini",
    "position": 26,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Mini",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/mini",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1737,
    "parent": 1701,
    "code": "porsche",
    "position": 32,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Porsche",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/porsche",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1738,
    "parent": 1701,
    "code": "dodge",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Dodge",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/dodge",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1739,
    "parent": 1701,
    "code": "jaguar",
    "position": 18,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Jaguar",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/jaguar",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1740,
    "parent": 1701,
    "code": "dacia",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Dacia",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/dacia",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1741,
    "parent": 1701,
    "code": "daihatsu",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Daihatsu",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/daihatsu",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1742,
    "parent": 1701,
    "code": "lexus",
    "position": 23,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Lexus",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/lexus",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1743,
    "parent": 1701,
    "code": "cadillac",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Cadillac",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/cadillac",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1744,
    "parent": 1701,
    "code": "infiniti",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Infiniti",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/infiniti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1745,
    "parent": 1701,
    "code": "aixam",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Aixam",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/aixam",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1746,
    "parent": 1701,
    "code": "ssangyong",
    "position": 39,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "SsangYong",
    "view_type": "gallery",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/avtomobili-iz-polshi/ssangyong",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1748,
    "parent": 1532,
    "code": "gruzoviki-i-spetstehnika-iz-polshi",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "automotive",
    "type": "automotive",
    "name": "Вантажівки та спецтехніка з Польщі",
    "view_type": "grid",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 24,
    "path": "transport/gruzoviki-i-spetstehnika-iz-polshi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1749,
      1750,
      1751,
      1752
    ]
  },
  {
    "category_id": 1749,
    "parent": 1748,
    "code": "gruzovye-avtomobili-iz-polshi",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "automotive",
    "type": "automotive",
    "name": "Вантажні автомобілі з Польщі",
    "view_type": "grid",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/gruzoviki-i-spetstehnika-iz-polshi/gruzovye-avtomobili-iz-polshi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1750,
    "parent": 1748,
    "code": "pritsepy-iz-polshi",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "automotive",
    "type": "automotive",
    "name": "Причепи з Польщі",
    "view_type": "grid",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/gruzoviki-i-spetstehnika-iz-polshi/pritsepy-iz-polshi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1751,
    "parent": 1748,
    "code": "selhoztehnika-iz-polshi",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "automotive",
    "type": "automotive",
    "name": "Сільгосптехніка з Польщі",
    "view_type": "grid",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/gruzoviki-i-spetstehnika-iz-polshi/selhoztehnika-iz-polshi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1752,
    "parent": 1748,
    "code": "stroitelnaya-tehnika-iz-polshi",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "automotive",
    "type": "automotive",
    "name": "Будівельна техніка з Польщі",
    "view_type": "grid",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/gruzoviki-i-spetstehnika-iz-polshi/stroitelnaya-tehnika-iz-polshi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1754,
    "parent": 1,
    "code": "komnaty",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Кімнати",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 24,
    "path": "nedvizhimost/komnaty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1755,
      1756
    ]
  },
  {
    "category_id": 1755,
    "parent": 1754,
    "code": "prodazha-komnat",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Продаж кімнат",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "nedvizhimost/komnaty/prodazha-komnat",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1756,
    "parent": 1754,
    "code": "dolgosrochnaya-arenda-komnat",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Довгострокова оренда кімнат",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "nedvizhimost/komnaty/dolgosrochnaya-arenda-komnat",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1757,
    "parent": 1,
    "code": "kvartiry",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Квартири",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 24,
    "path": "nedvizhimost/kvartiry",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1758,
      1760
    ]
  },
  {
    "category_id": 1758,
    "parent": 1757,
    "code": "prodazha-kvartir",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Продаж квартир",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "nedvizhimost/kvartiry/prodazha-kvartir",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1760,
    "parent": 1757,
    "code": "dolgosrochnaya-arenda-kvartir",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Довгострокова оренда квартир",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "nedvizhimost/kvartiry/dolgosrochnaya-arenda-kvartir",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1762,
    "parent": 108,
    "code": "aston-martin",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Aston Martin",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/aston-martin",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1763,
    "parent": 108,
    "code": "bmw-alpina",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "BMW-Alpina",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/bmw-alpina",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1764,
    "parent": 108,
    "code": "buick",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Buick",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/buick",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1765,
    "parent": 108,
    "code": "faw",
    "position": 22,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "FAW",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/faw",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1766,
    "parent": 108,
    "code": "groz",
    "position": 29,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Groz",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/groz",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1767,
    "parent": 108,
    "code": "lamborghini",
    "position": 40,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Lamborghini",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/lamborghini",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1768,
    "parent": 108,
    "code": "lotus",
    "position": 46,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Lotus",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/lotus",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1769,
    "parent": 108,
    "code": "maybach",
    "position": 48,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Maybach",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/maybach",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1770,
    "parent": 108,
    "code": "mclaren",
    "position": 50,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "McLaren",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/mclaren",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1771,
    "parent": 108,
    "code": "mercury",
    "position": 52,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Mercury",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/mercury",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1772,
    "parent": 108,
    "code": "proton",
    "position": 62,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Proton",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/proton",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1773,
    "parent": 108,
    "code": "ram",
    "position": 63,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Ram",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/ram",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1774,
    "parent": 108,
    "code": "rolls-royce",
    "position": 66,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Rolls-Royce",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/rolls-royce",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1775,
    "parent": 108,
    "code": "samsung",
    "position": 71,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Samsung",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/samsung",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1776,
    "parent": 108,
    "code": "shelby",
    "position": 73,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Shelby",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/shelby",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1777,
    "parent": 108,
    "code": "tata",
    "position": 79,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "TATA",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/legkovye-avtomobili/tata",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1778,
    "parent": 150,
    "code": "raznorabochiy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Різноробочий",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/stroitelstvo/raznorabochiy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1779,
    "parent": 150,
    "code": "kamenshchik",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Муляр",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/stroitelstvo/kamenshchik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1780,
    "parent": 150,
    "code": "plitochnik",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Плиточник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/stroitelstvo/plitochnik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1781,
    "parent": 150,
    "code": "montazhnik",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Монтажник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/stroitelstvo/montazhnik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1782,
    "parent": 150,
    "code": "svarshchik",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Зварювальник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/stroitelstvo/svarshchik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1783,
    "parent": 150,
    "code": "stroitel",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Будівельник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/stroitelstvo/stroitel",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1784,
    "parent": 150,
    "code": "malyar",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Маляр",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/stroitelstvo/malyar",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1785,
    "parent": 150,
    "code": "prorab",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Виконроб",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/stroitelstvo/prorab",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1786,
    "parent": 150,
    "code": "santekhnik",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Сантехнік",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/stroitelstvo/santekhnik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1787,
    "parent": 150,
    "code": "fasadchik",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Фасадник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/stroitelstvo/fasadchik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1788,
    "parent": 150,
    "code": "shtukatur",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Штукатур",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/stroitelstvo/shtukatur",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1789,
    "parent": 150,
    "code": "elektrik",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Електрик",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/stroitelstvo/elektrik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1790,
    "parent": 150,
    "code": "drugoe",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/stroitelstvo/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1791,
    "parent": 1159,
    "code": "rieltor",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Ріелтор",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/nedvizhimost/rieltor",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1792,
    "parent": 1159,
    "code": "menedzher-po-prodazham",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Менеджер з продажу",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/nedvizhimost/menedzher-po-prodazham",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1793,
    "parent": 1159,
    "code": "broker",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Брокер",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/nedvizhimost/broker",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1794,
    "parent": 1159,
    "code": "drugoe",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/nedvizhimost/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1795,
    "parent": 1475,
    "code": "yurist",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Юрист",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/banki-finansy-strakhovaniye/yurist",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1796,
    "parent": 152,
    "code": "ohrannik",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Охоронець",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/ohrana-bezopasnost/ohrannik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1797,
    "parent": 137,
    "code": "parikmaher",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Перукар",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/krasota-fitnes-sport/parikmaher",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1798,
    "parent": 1475,
    "code": "drugoe",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/banki-finansy-strakhovaniye/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1799,
    "parent": 152,
    "code": "storozh",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Сторож",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/ohrana-bezopasnost/storozh",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1800,
    "parent": 137,
    "code": "master-manikyura",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Майстер манікюру",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/krasota-fitnes-sport/master-manikyura",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1801,
    "parent": 152,
    "code": "drygoe",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/ohrana-bezopasnost/drygoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1802,
    "parent": 137,
    "code": "administrator",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Адміністратор",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/krasota-fitnes-sport/administrator",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1803,
    "parent": 137,
    "code": "massazhist",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Масажист",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/krasota-fitnes-sport/massazhist",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1804,
    "parent": 137,
    "code": "kosmetolog",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Косметолог",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/krasota-fitnes-sport/kosmetolog",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1805,
    "parent": 137,
    "code": "trener",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Тренер",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/krasota-fitnes-sport/trener",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1807,
    "parent": 137,
    "code": "drugoe",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/krasota-fitnes-sport/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1808,
    "parent": 183,
    "code": "sidelka",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Доглядальниця",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/domashniy-personal/sidelka",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1809,
    "parent": 183,
    "code": "uborshchitsa",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Прибиральниця",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/domashniy-personal/uborshchitsa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1810,
    "parent": 183,
    "code": "dvornik",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Двірник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/domashniy-personal/dvornik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1811,
    "parent": 183,
    "code": "nyanya",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Няня",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/domashniy-personal/nyanya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1812,
    "parent": 183,
    "code": "domrabotnitsa",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Домробітниця",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/domashniy-personal/domrabotnitsa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1813,
    "parent": 183,
    "code": "drugoe",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/domashniy-personal/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1814,
    "parent": 141,
    "code": "prepodavatel-uchitel",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Викладач / Вчитель",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/obrazovanie/prepodavatel-uchitel",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1815,
    "parent": 141,
    "code": "vospitatel",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Вихователь",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/obrazovanie/vospitatel",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1816,
    "parent": 141,
    "code": "repetitor",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Репетитор",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/obrazovanie/repetitor",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1817,
    "parent": 141,
    "code": "drugoe",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/obrazovanie/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1818,
    "parent": 134,
    "code": "medsestra",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Медсестра",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/meditsina-farmatsiya/medsestra",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1819,
    "parent": 147,
    "code": "operator-pk",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Оператор ПК",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/it-telekom-kompyutery/operator-pk",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1820,
    "parent": 134,
    "code": "stomatolog",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Стоматолог",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/meditsina-farmatsiya/stomatolog",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1821,
    "parent": 147,
    "code": "master-po-remontu",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Майстер з ремонту",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/it-telekom-kompyutery/master-po-remontu",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1822,
    "parent": 134,
    "code": "farmatsevt",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Фармацевт",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/meditsina-farmatsiya/farmatsevt",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1823,
    "parent": 147,
    "code": "sistemnyy-administrator",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Системний адміністратор",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/it-telekom-kompyutery/sistemnyy-administrator",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1824,
    "parent": 134,
    "code": "drugoe",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/meditsina-farmatsiya/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1826,
    "parent": 147,
    "code": "drugoe",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/it-telekom-kompyutery/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1827,
    "parent": 1477,
    "code": "operator-koll-tsentra",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Оператор колл-центру",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/telekommunikatsii-svyaz/operator-koll-tsentra",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1828,
    "parent": 1477,
    "code": "operator-chata",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Оператор чату",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/telekommunikatsii-svyaz/operator-chata",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1829,
    "parent": 1477,
    "code": "drugoe",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/telekommunikatsii-svyaz/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1830,
    "parent": 145,
    "code": "promouter",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Промоутер",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/marketing-reklama-dizayn/promouter",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1831,
    "parent": 145,
    "code": "dizayner",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Дизайнер",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/marketing-reklama-dizayn/dizayner",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1832,
    "parent": 145,
    "code": "marketolog",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Маркетолог",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/marketing-reklama-dizayn/marketolog",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1833,
    "parent": 145,
    "code": "smm-menedzher",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "SMM-менеджер",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/marketing-reklama-dizayn/smm-menedzher",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1834,
    "parent": 145,
    "code": "kontent-menedzher",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Контент-менеджер",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/marketing-reklama-dizayn/kontent-menedzher",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1835,
    "parent": 145,
    "code": "kopirayter",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Копірайтер",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/marketing-reklama-dizayn/kopirayter",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1836,
    "parent": 145,
    "code": "drugoe",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/marketing-reklama-dizayn/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1837,
    "parent": 143,
    "code": "elektrik",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Електрик",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/proizvodstvo-energetika/elektrik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1838,
    "parent": 143,
    "code": "stolyar",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Столяр",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/proizvodstvo-energetika/stolyar",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1839,
    "parent": 143,
    "code": "shveya-portnoy",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Швачка, кравець",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/proizvodstvo-energetika/shveya-portnoy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1840,
    "parent": 143,
    "code": "tokar",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Токар",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/proizvodstvo-energetika/tokar",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1841,
    "parent": 143,
    "code": "mashinist-ekskavatora",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Машиніст екскаватора",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/proizvodstvo-energetika/mashinist-ekskavatora",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1842,
    "parent": 143,
    "code": "konstruktor",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Конструктор",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/proizvodstvo-energetika/konstruktor",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1843,
    "parent": 143,
    "code": "komplektovshchik",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Комплектувальник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/proizvodstvo-energetika/komplektovshchik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1844,
    "parent": 143,
    "code": "sborshchik-mebeli",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Складальник меблів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/proizvodstvo-energetika/sborshchik-mebeli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1845,
    "parent": 143,
    "code": "raznorabochiy",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Різноробочий",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/proizvodstvo-energetika/raznorabochiy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1846,
    "parent": 143,
    "code": "svarshchik",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Зварювальник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/proizvodstvo-energetika/svarshchik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1847,
    "parent": 143,
    "code": "sborshchik",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Складальник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/proizvodstvo-energetika/sborshchik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1848,
    "parent": 143,
    "code": "slesar",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Слюсар",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/proizvodstvo-energetika/slesar",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1849,
    "parent": 143,
    "code": "malyar",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Маляр",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/proizvodstvo-energetika/malyar",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1850,
    "parent": 143,
    "code": "upakovshchik",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Пакувальник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/proizvodstvo-energetika/upakovshchik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1851,
    "parent": 143,
    "code": "montazhnik",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Монтажник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/proizvodstvo-energetika/montazhnik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1852,
    "parent": 143,
    "code": "mekhanik",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Механік",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/proizvodstvo-energetika/mekhanik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1853,
    "parent": 143,
    "code": "podsobnik",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Підсобник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/proizvodstvo-energetika/podsobnik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1854,
    "parent": 143,
    "code": "operator-chpu",
    "position": 18,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Оператор ЧПУ",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/proizvodstvo-energetika/operator-chpu",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1855,
    "parent": 143,
    "code": "tehnolog",
    "position": 19,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Технолог",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/proizvodstvo-energetika/tehnolog",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1856,
    "parent": 143,
    "code": "drugoe",
    "position": 20,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/proizvodstvo-energetika/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1857,
    "parent": 1473,
    "code": "administrator",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Адміністратор",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/upravleniye-personalom-hr/administrator",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1858,
    "parent": 1473,
    "code": "sekretar",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Секретар",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/upravleniye-personalom-hr/sekretar",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1859,
    "parent": 1473,
    "code": "ofis-menedzher",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Офіс менеджер",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/upravleniye-personalom-hr/ofis-menedzher",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1860,
    "parent": 1473,
    "code": "hr",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "HR",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/upravleniye-personalom-hr/hr",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1861,
    "parent": 1473,
    "code": "drugoe",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/upravleniye-personalom-hr/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1862,
    "parent": 1479,
    "code": "agronom",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Агроном",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/selskoye-khozyaystvo-agrobiznes-lesnoye-khozyaystvo/agronom",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1863,
    "parent": 1479,
    "code": "traktorist",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Тракторист",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/selskoye-khozyaystvo-agrobiznes-lesnoye-khozyaystvo/traktorist",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1864,
    "parent": 1479,
    "code": "piloramshchik",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Пилорамщик",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/selskoye-khozyaystvo-agrobiznes-lesnoye-khozyaystvo/piloramshchik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1865,
    "parent": 1479,
    "code": "drugoe",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/selskoye-khozyaystvo-agrobiznes-lesnoye-khozyaystvo/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1866,
    "parent": 6,
    "code": "buhgalteriya",
    "position": 21,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Бухгалтерія",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/buhgalteriya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1871,
      1872,
      1873
    ]
  },
  {
    "category_id": 1871,
    "parent": 1866,
    "code": "buhgalter",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Бухгалтер",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/buhgalteriya/buhgalter",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1872,
    "parent": 1866,
    "code": "auditor",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Аудитор",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/buhgalteriya/auditor",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1873,
    "parent": 1866,
    "code": "drugoe",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/buhgalteriya/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1874,
    "parent": 6,
    "code": "otelno-restorannyy-biznes-turizm",
    "position": 22,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Готельно-ресторанний бізнес / Туризм",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/otelno-restorannyy-biznes-turizm",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1875,
      1876,
      1877,
      1878,
      1879,
      1880,
      1881,
      1882,
      1883,
      1884,
      1885,
      1886,
      1887,
      1888
    ]
  },
  {
    "category_id": 1875,
    "parent": 1874,
    "code": "administrator",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Адміністратор",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/otelno-restorannyy-biznes-turizm/administrator",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1876,
    "parent": 1874,
    "code": "gornichnaya",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Покоївка",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/otelno-restorannyy-biznes-turizm/gornichnaya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1877,
    "parent": 1874,
    "code": "menedzher",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Менеджер",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/otelno-restorannyy-biznes-turizm/menedzher",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1878,
    "parent": 1874,
    "code": "barista",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Бариста",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/otelno-restorannyy-biznes-turizm/barista",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1879,
    "parent": 1874,
    "code": "povar",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Кухар",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/otelno-restorannyy-biznes-turizm/povar",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1880,
    "parent": 1874,
    "code": "barmen",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Бармен",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/otelno-restorannyy-biznes-turizm/barmen",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1881,
    "parent": 1874,
    "code": "ofitsiant",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Офіціант",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/otelno-restorannyy-biznes-turizm/ofitsiant",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1882,
    "parent": 1874,
    "code": "konditer",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Кондитер",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/otelno-restorannyy-biznes-turizm/konditer",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1883,
    "parent": 1874,
    "code": "pekar",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Пекар",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/otelno-restorannyy-biznes-turizm/pekar",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1884,
    "parent": 1874,
    "code": "pomoshchnik-povara",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Помічник кухаря",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/otelno-restorannyy-biznes-turizm/pomoshchnik-povara",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1885,
    "parent": 1874,
    "code": "sushist",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Сушист",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/otelno-restorannyy-biznes-turizm/sushist",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1886,
    "parent": 1874,
    "code": "posudomoyshchitsa",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Посудомийниця",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/otelno-restorannyy-biznes-turizm/posudomoyshchitsa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1887,
    "parent": 1874,
    "code": "pitstsayolo",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Піцайоло",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/otelno-restorannyy-biznes-turizm/pitstsayolo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1888,
    "parent": 1874,
    "code": "drugoe",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/otelno-restorannyy-biznes-turizm/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1889,
    "parent": 154,
    "code": "fotograf",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Фотограф",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/kultura-iskusstvo/fotograf",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1890,
    "parent": 154,
    "code": "model",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Модель",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/kultura-iskusstvo/model",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1891,
    "parent": 154,
    "code": "animator",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Аніматор",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/kultura-iskusstvo/animator",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1892,
    "parent": 154,
    "code": "drugoe",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/kultura-iskusstvo/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1893,
    "parent": 1481,
    "code": "raznorabochiy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Різноробочий",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/rabota-za-rubezhom/raznorabochiy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1894,
    "parent": 1481,
    "code": "povar",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Кухар",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/rabota-za-rubezhom/povar",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1895,
    "parent": 1481,
    "code": "voditel",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Водій",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/rabota-za-rubezhom/voditel",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1896,
    "parent": 1481,
    "code": "nyanya",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Няня",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/rabota-za-rubezhom/nyanya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1897,
    "parent": 1481,
    "code": "sidelka",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Доглядальниця",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/rabota-za-rubezhom/sidelka",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1898,
    "parent": 1481,
    "code": "svarschik",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Зварювальник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/rabota-za-rubezhom/svarschik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1899,
    "parent": 1481,
    "code": "stroitel",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Будівельник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/rabota-za-rubezhom/stroitel",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1900,
    "parent": 1481,
    "code": "upakovschik",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Пакувальник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/rabota-za-rubezhom/upakovschik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1901,
    "parent": 1481,
    "code": "drugoe",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/rabota-za-rubezhom/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1902,
    "parent": 6,
    "code": "sto-avtomoyki",
    "position": 24,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "СТО / автомийки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 2,
    "max_photos": 0,
    "path": "rabota/sto-avtomoyki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1903,
      1904,
      1905,
      1906,
      1907,
      1908,
      1909,
      1910
    ]
  },
  {
    "category_id": 1903,
    "parent": 1902,
    "code": "avtoslesar",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Автослюсар",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/sto-avtomoyki/avtoslesar",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1904,
    "parent": 1902,
    "code": "moyshchik",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Мийник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/sto-avtomoyki/moyshchik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1905,
    "parent": 1902,
    "code": "mehanik",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Механік",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/sto-avtomoyki/mehanik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1906,
    "parent": 1902,
    "code": "avtomalyar",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Автомаляр",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/sto-avtomoyki/avtomalyar",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1907,
    "parent": 1902,
    "code": "avtoelektrik",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Автоелектрик",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/sto-avtomoyki/avtoelektrik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1908,
    "parent": 1902,
    "code": "rihtovshchik",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Рихтовщик",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/sto-avtomoyki/rihtovshchik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1909,
    "parent": 1902,
    "code": "shinomontazhnik",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Шиномонтажник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/sto-avtomoyki/shinomontazhnik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1910,
    "parent": 1902,
    "code": "drugoe",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/sto-avtomoyki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1911,
    "parent": 140,
    "code": "voditel",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Водій",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/transport-logistika/voditel",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1912,
    "parent": 140,
    "code": "gruzchik",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Вантажник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/transport-logistika/gruzchik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1913,
    "parent": 140,
    "code": "kurer",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Кур'єр",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/transport-logistika/kurer",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1914,
    "parent": 140,
    "code": "kladovshchik",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Комірник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/transport-logistika/kladovshchik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1915,
    "parent": 140,
    "code": "dispetcher",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Диспетчер",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/transport-logistika/dispetcher",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1916,
    "parent": 140,
    "code": "ekspeditor",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Експедитор",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/transport-logistika/ekspeditor",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1917,
    "parent": 140,
    "code": "dalnoboyshchik",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Далекобійник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/transport-logistika/dalnoboyshchik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1918,
    "parent": 140,
    "code": "taksist",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Таксист",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/transport-logistika/taksist",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1919,
    "parent": 140,
    "code": "drugoe",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/transport-logistika/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1920,
    "parent": 144,
    "code": "merchendayzer",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Мерчендайзер",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/roznichnaya-torgovlya-prodazhi-zakupki/merchendayzer",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1921,
    "parent": 144,
    "code": "menedzher-po-prodazham",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Менеджер з продажу",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/roznichnaya-torgovlya-prodazhi-zakupki/menedzher-po-prodazham",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1922,
    "parent": 144,
    "code": "prodavets",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Продавець",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/roznichnaya-torgovlya-prodazhi-zakupki/prodavets",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1923,
    "parent": 144,
    "code": "supervayzer",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Супервайзер",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/roznichnaya-torgovlya-prodazhi-zakupki/supervayzer",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1924,
    "parent": 144,
    "code": "florist",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Флорист",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/roznichnaya-torgovlya-prodazhi-zakupki/florist",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1925,
    "parent": 144,
    "code": "prodavets-konsultant",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Продавець-консультант",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/roznichnaya-torgovlya-prodazhi-zakupki/prodavets-konsultant",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1926,
    "parent": 144,
    "code": "torgovyy-predstavitel",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Торговий представник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/roznichnaya-torgovlya-prodazhi-zakupki/torgovyy-predstavitel",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1927,
    "parent": 144,
    "code": "menedzher-internet-magazina",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Менеджер інтернет магазину",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/roznichnaya-torgovlya-prodazhi-zakupki/menedzher-internet-magazina",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1928,
    "parent": 144,
    "code": "kassir",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Касир",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/roznichnaya-torgovlya-prodazhi-zakupki/kassir",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1929,
    "parent": 144,
    "code": "menedzher-po-rabote-s-klientami",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Менеджер по роботі з клієнтами",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/roznichnaya-torgovlya-prodazhi-zakupki/menedzher-po-rabote-s-klientami",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1930,
    "parent": 144,
    "code": "tovaroved",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Товарознавець",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/roznichnaya-torgovlya-prodazhi-zakupki/tovaroved",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1931,
    "parent": 144,
    "code": "rukovoditel-otdela-prodazh",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Керівник відділу продажів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/roznichnaya-torgovlya-prodazhi-zakupki/rukovoditel-otdela-prodazh",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1932,
    "parent": 144,
    "code": "menedzher-po-zakupkam",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Менеджер по закупці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/roznichnaya-torgovlya-prodazhi-zakupki/menedzher-po-zakupkam",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1933,
    "parent": 144,
    "code": "administrator",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Адміністратор",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/roznichnaya-torgovlya-prodazhi-zakupki/administrator",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1934,
    "parent": 144,
    "code": "pekar",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Пекар",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/roznichnaya-torgovlya-prodazhi-zakupki/pekar",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1935,
    "parent": 144,
    "code": "drugoe",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "job",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 0,
    "path": "rabota/roznichnaya-torgovlya-prodazhi-zakupki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1936,
    "parent": 109,
    "code": "maksi-skuter",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Максі-скутер",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/moto/maksi-skuter",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1937,
    "parent": 109,
    "code": "snihokhid",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Снігохід",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/moto/snihokhid",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1938,
    "parent": 109,
    "code": "bahhi",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Баггі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/moto/bahhi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1939,
    "parent": 109,
    "code": "kartynh",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Картинг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/moto/kartynh",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1940,
    "parent": 109,
    "code": "elektrosamokaty-hiroskutery",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Електросамокати / гіроскутери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/moto/elektrosamokaty-hiroskutery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1941,
    "parent": 109,
    "code": "elektroskutery",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Електроскутери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/moto/elektroskutery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1942,
    "parent": 109,
    "code": "elektromotosykly",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Електромотоцикли",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/moto/elektromotosykly",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1943,
    "parent": 109,
    "code": "elektrokvadratsykly",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Електроквадрацикли",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/moto/elektrokvadratsykly",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1944,
    "parent": 0,
    "code": "dopomoga",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "dopomoga",
    "type": "goods",
    "name": "Допомога",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 1,
    "max_photos": 8,
    "path": "dopomoga",
    "icon": {
      "image_url": "https://categories.olxcdn.com/assets/categories/olxua/dopomoga-1944-1x.png",
      "big_image_url": "https://categories.olxcdn.com/assets/categories/olxua/dopomoga-1944-2x.png"
    },
    "children": [
      1945,
      1946
    ]
  },
  {
    "category_id": 1945,
    "parent": 1944,
    "code": "potribna_dopomoha",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Потрібна допомога",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "dopomoga/potribna_dopomoha",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1947,
      1948,
      1949,
      1950,
      1951,
      1952,
      1953,
      1954,
      1963
    ]
  },
  {
    "category_id": 1946,
    "parent": 1944,
    "code": "proponuiu_dopomohu",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пропоную допомогу",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "dopomoga/proponuiu_dopomohu",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      1955,
      1956,
      1957,
      1958,
      1959,
      1960,
      1961,
      1962,
      1964
    ]
  },
  {
    "category_id": 1947,
    "parent": 1945,
    "code": "liky_ta_hihiienichni_zasoby",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ліки та Гігієнічні засоби",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dopomoga/potribna_dopomoha/liky_ta_hihiienichni_zasoby",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1948,
    "parent": 1945,
    "code": "zhytlo",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Житло",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dopomoga/potribna_dopomoha/zhytlo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1949,
    "parent": 1945,
    "code": "transport",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Транспорт",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dopomoga/potribna_dopomoha/transport",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1950,
    "parent": 1945,
    "code": "dlia_ditei",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Для дітей",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dopomoga/potribna_dopomoha/dlia_ditei",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1951,
    "parent": 1945,
    "code": "odiah_ta_vzuttia",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Одяг та взуття",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dopomoga/potribna_dopomoha/odiah_ta_vzuttia",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1952,
    "parent": 1945,
    "code": "dlia_tvaryn",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Для тварин",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dopomoga/potribna_dopomoha/dlia_tvaryn",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1953,
    "parent": 1945,
    "code": "medychna_dopomoha",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Медична допомога",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dopomoga/potribna_dopomoha/medychna_dopomoha",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1954,
    "parent": 1945,
    "code": "inshe",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dopomoga/potribna_dopomoha/inshe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1955,
    "parent": 1946,
    "code": "liky_ta_hihiienichni_zasoby",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ліки та Гігієнічні засоби",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dopomoga/proponuiu_dopomohu/liky_ta_hihiienichni_zasoby",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1956,
    "parent": 1946,
    "code": "zhytlo",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Житло",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dopomoga/proponuiu_dopomohu/zhytlo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1957,
    "parent": 1946,
    "code": "transport",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Транспорт",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dopomoga/proponuiu_dopomohu/transport",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1958,
    "parent": 1946,
    "code": "dlia_ditei",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Для дітей",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dopomoga/proponuiu_dopomohu/dlia_ditei",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1959,
    "parent": 1946,
    "code": "odiah_ta_vzuttia",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Одяг та взуття",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dopomoga/proponuiu_dopomohu/odiah_ta_vzuttia",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1960,
    "parent": 1946,
    "code": "dlia_tvaryn",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Для тварин",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dopomoga/proponuiu_dopomohu/dlia_tvaryn",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1961,
    "parent": 1946,
    "code": "medychna_dopomoha",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Медична допомога",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dopomoga/proponuiu_dopomohu/medychna_dopomoha",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1962,
    "parent": 1946,
    "code": "inshe",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dopomoga/proponuiu_dopomohu/inshe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1963,
    "parent": 1945,
    "code": "yizha_produkty",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Їжа / Продукти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dopomoga/potribna_dopomoha/yizha_produkty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1964,
    "parent": 1946,
    "code": "yizha_produkty_0",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Їжа / Продукти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dopomoga/proponuiu_dopomohu/yizha_produkty_0",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1965,
    "parent": 273,
    "code": "avtovyshky",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автовишки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/spetstehnika/avtovyshky",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1966,
    "parent": 273,
    "code": "avtokrany-ta-manipuliatory",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автокрани та маніпулятори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/spetstehnika/avtokrany-ta-manipuliatory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1967,
    "parent": 273,
    "code": "avtohreider",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автогрейдер",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/spetstehnika/avtohreider",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1968,
    "parent": 273,
    "code": "asfaltoukladach",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Асфальтоукладач",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/spetstehnika/asfaltoukladach",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1969,
    "parent": 273,
    "code": "dorozhnii-katok",
    "position": 18,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Дорожній каток",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/spetstehnika/dorozhnii-katok",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1970,
    "parent": 273,
    "code": "vsiudykhody",
    "position": 19,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Всюдиходи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/spetstehnika/vsiudykhody",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1971,
    "parent": 273,
    "code": "mininavantazhuvach",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мінінавантажувач",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/spetstehnika/mininavantazhuvach",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1972,
    "parent": 273,
    "code": "betonozmishuvachi",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Бетонозмішувачі (міксери)",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/spetstehnika/betonozmishuvachi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1973,
    "parent": 273,
    "code": "betononasosy",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Бетононасоси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/spetstehnika/betononasosy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1974,
    "parent": 273,
    "code": "burova-ustanovka",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Бурова установка",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/spetstehnika/burova-ustanovka",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1975,
    "parent": 273,
    "code": "smittievoz",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Сміттєвоз",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/spetstehnika/smittievoz",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1976,
    "parent": 273,
    "code": "lisozahotivelna-tekhnika",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Лісозаготівельна техніка",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/spetstehnika/lisozahotivelna-tekhnika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1977,
    "parent": 273,
    "code": "mashyna-asenizator",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Машина асенізатор (вакуумна)",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/spetstehnika/mashyna-asenizator",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1978,
    "parent": 304,
    "code": "traktor-silskohospodarskyi",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Трактор сільськогосподарський",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/selhoztehnika/traktor-silskohospodarskyi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1979,
    "parent": 304,
    "code": "motoblok",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мотоблок",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/selhoztehnika/motoblok",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1980,
    "parent": 304,
    "code": "motokultyvator",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мотокультиватор",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/selhoztehnika/motokultyvator",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1981,
    "parent": 304,
    "code": "mini-traktor",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Міні-трактор",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/selhoztehnika/mini-traktor",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1982,
    "parent": 304,
    "code": "kombain",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Комбайн",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/selhoztehnika/kombain",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1983,
    "parent": 304,
    "code": "navisne-obladnannia",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Навісне та додаткове обладнання",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/selhoztehnika/navisne-obladnannia",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1984,
    "parent": 304,
    "code": "insha-silhosptekhnika",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інша сільгосптехніка",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/selhoztehnika/insha-silhosptekhnika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1985,
    "parent": 253,
    "code": "yakhta",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Яхта",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/vodnyy-transport/yakhta",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1986,
    "parent": 253,
    "code": "kater",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Катер",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/vodnyy-transport/kater",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1987,
    "parent": 253,
    "code": "motornyi-choven",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Моторний човен",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/vodnyy-transport/motornyi-choven",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1988,
    "parent": 253,
    "code": "katamaran",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Катамаран",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/vodnyy-transport/katamaran",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1989,
    "parent": 253,
    "code": "hrebnyi-choven",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Гребний човен",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/vodnyy-transport/hrebnyi-choven",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1990,
    "parent": 253,
    "code": "hidrotsykl",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Гідроцикл",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/vodnyy-transport/hidrotsykl",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1991,
    "parent": 253,
    "code": "kaiak",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Каяк",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/vodnyy-transport/kaiak",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1992,
    "parent": 253,
    "code": "inshyi-vodnyi-transport",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інший водний транспорт",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/vodnyy-transport/inshyi-vodnyi-transport",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1993,
    "parent": 261,
    "code": "lehkovyi-prychip",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Легковий причіп",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/pritsepy-doma-na-kolesah/lehkovyi-prychip",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1994,
    "parent": 261,
    "code": "lafet",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Лафет",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/pritsepy-doma-na-kolesah/lafet",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1995,
    "parent": 261,
    "code": "napivprychip",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Напівпричіп",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/pritsepy-doma-na-kolesah/napivprychip",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1996,
    "parent": 261,
    "code": "bortovyi-napivprychip",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Бортовий напівпричіп",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/pritsepy-doma-na-kolesah/bortovyi-napivprychip",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1997,
    "parent": 261,
    "code": "vantazhnyi-prychip",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Вантажний причіп",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/pritsepy-doma-na-kolesah/vantazhnyi-prychip",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1998,
    "parent": 261,
    "code": "prychip-konteinerovoz",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Причіп контейнеровоз",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/pritsepy-doma-na-kolesah/prychip-konteinerovoz",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 1999,
    "parent": 261,
    "code": "prychip-zernovoz",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Причіп зерновоз",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/pritsepy-doma-na-kolesah/prychip-zernovoz",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2000,
    "parent": 261,
    "code": "samoskyd-napivprychip",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Самоскид напівпричіп",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/pritsepy-doma-na-kolesah/samoskyd-napivprychip",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2001,
    "parent": 261,
    "code": "tral",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Трал",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/pritsepy-doma-na-kolesah/tral",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2002,
    "parent": 261,
    "code": "torhovyi-prychip",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Торговий причіп",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/pritsepy-doma-na-kolesah/torhovyi-prychip",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2003,
    "parent": 261,
    "code": "traktornyi-prychip",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Тракторний причіп",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/pritsepy-doma-na-kolesah/traktornyi-prychip",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2004,
    "parent": 261,
    "code": "prychip-furhon",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Причіп фургон",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/pritsepy-doma-na-kolesah/prychip-furhon",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2005,
    "parent": 261,
    "code": "prychepy-tsysterny",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Причепи цистерни",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/pritsepy-doma-na-kolesah/prychepy-tsysterny",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2006,
    "parent": 261,
    "code": "avtodim",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автодім",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/pritsepy-doma-na-kolesah/avtodim",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2007,
    "parent": 261,
    "code": "kempinh",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Кемпінг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/pritsepy-doma-na-kolesah/kempinh",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2008,
    "parent": 261,
    "code": "prychip-dacha",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Причіп дача",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/pritsepy-doma-na-kolesah/prychip-dacha",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2009,
    "parent": 261,
    "code": "inshi-prychepy",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші причепи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/pritsepy-doma-na-kolesah/inshi-prychepy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2010,
    "parent": 891,
    "code": "muzhskoe-bele-plavki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Чоловіча білизна та плавки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskoe-bele-plavki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2110,
      2111,
      2112,
      2113,
      2293
    ]
  },
  {
    "category_id": 2011,
    "parent": 891,
    "code": "zhenskoe-bele-kupalniki",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Жіноча білизна та купальники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2296,
      2297,
      2298,
      2299,
      2300,
      2301,
      2302,
      2303,
      2304,
      2305,
      2306,
      2307,
      2308,
      2309,
      2310,
      2332,
      2337
    ]
  },
  {
    "category_id": 2012,
    "parent": 891,
    "code": "odezhda-dlya-beremennyh",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Одяг для вагітних",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "moda-i-stil/odezhda-dlya-beremennyh",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2114,
      2115,
      2116,
      2117,
      2118,
      2119,
      2120,
      2121,
      2122,
      2123,
      2124,
      2125,
      2126,
      2127,
      2128,
      2129,
      2130,
      2294
    ]
  },
  {
    "category_id": 2013,
    "parent": 891,
    "code": "golovnye-ubory",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Головні убори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "moda-i-stil/golovnye-ubory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2131,
      2132,
      2135,
      2138,
      2141,
      2144,
      2147,
      2150,
      2151,
      2295
    ]
  },
  {
    "category_id": 2014,
    "parent": 891,
    "code": "muzhskaya-obuv",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Чоловіче взуття",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2687,
      2688,
      2689,
      2690,
      2691,
      2692,
      2693,
      2694,
      2695,
      2727
    ]
  },
  {
    "category_id": 2015,
    "parent": 891,
    "code": "zhenskaya-obuv",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Жіноче взуття",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2618,
      2619,
      2620,
      2621,
      2622,
      2623,
      2624,
      2625,
      2626,
      2627,
      2628,
      2665
    ]
  },
  {
    "category_id": 2016,
    "parent": 891,
    "code": "zhenskaya-odezhda",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Жіночий одяг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2837,
      2838,
      2839,
      2840,
      2841,
      2842,
      2843,
      2844,
      2845,
      2846,
      2847,
      2848,
      2849,
      2850,
      2851,
      2852,
      2951
    ]
  },
  {
    "category_id": 2017,
    "parent": 3,
    "code": "motozapchasti",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мотозапчастини",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2020,
      2021,
      2022,
      2023,
      2024,
      2025,
      2026,
      2027,
      2028,
      2029,
      2030,
      2031,
      2032,
      2033,
      2034,
      2035,
      2036,
      2037,
      2038,
      2039,
      2040,
      2070
    ]
  },
  {
    "category_id": 2018,
    "parent": 3,
    "code": "motoekipirovka",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мотоекіпірування",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoekipirovka",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2043,
      2044,
      2045,
      2046,
      2047,
      2048,
      2049,
      2050,
      2051,
      2052
    ]
  },
  {
    "category_id": 2019,
    "parent": 3,
    "code": "motoaksessuary",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мотоаксесуари",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoaksessuary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2053,
      2054,
      2055,
      2056,
      2057,
      2058,
      2059,
      2060,
      2061,
      2062,
      2063,
      2064,
      2065,
      2066,
      2067,
      2068,
      2069
    ]
  },
  {
    "category_id": 2020,
    "parent": 2017,
    "code": "akkumulyatory",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Акумулятори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/akkumulyatory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2071,
      2072,
      2073,
      2074
    ]
  },
  {
    "category_id": 2021,
    "parent": 2017,
    "code": "amortizatory-i-podveska",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Амортизатори та підвіска",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/amortizatory-i-podveska",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2075,
      2076,
      2077,
      2078,
      2079,
      2080
    ]
  },
  {
    "category_id": 2022,
    "parent": 2017,
    "code": "variatory-i-reduktory",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Варіатори та редуктори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/variatory-i-reduktory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2023,
    "parent": 2017,
    "code": "vykhlopnyye-sistemy-pryamotoki-glushiteli",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Вихлопні системи, прямоструми, глушники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/vykhlopnyye-sistemy-pryamotoki-glushiteli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2024,
    "parent": 2017,
    "code": "zapchasti-dvigatelya",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Запчастини двигуна",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/zapchasti-dvigatelya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2025,
    "parent": 2017,
    "code": "korobka-peredach",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Коробка передач",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/korobka-peredach",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2026,
    "parent": 2017,
    "code": "nozhki-i-rychagi-nozhnogo-upravleniya",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Ніжки та педалі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/nozhki-i-rychagi-nozhnogo-upravleniya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2027,
    "parent": 2017,
    "code": "osveshcheniye-i-lampy",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Освітлення та лампи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/osveshcheniye-i-lampy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2028,
    "parent": 2017,
    "code": "podshipniki",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Підшипники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/podshipniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2029,
    "parent": 2017,
    "code": "privod-i-khodovaya",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Привід та ходова",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/privod-i-khodovaya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2030,
    "parent": 2017,
    "code": "rama-kronshteyny-i-mayatniki",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Рама, кронштейни та маятники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/rama-kronshteyny-i-mayatniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2031,
    "parent": 2017,
    "code": "rul-i-upravleniye",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Кермо та управління",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/rul-i-upravleniye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2085,
      2086,
      2087,
      2088,
      2292
    ]
  },
  {
    "category_id": 2032,
    "parent": 2017,
    "code": "sideniya",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Сидіння",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/sideniya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2033,
    "parent": 2017,
    "code": "sistema-okhlazhdeniya",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Система охолодження",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/sistema-okhlazhdeniya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2034,
    "parent": 2017,
    "code": "stsepleniye",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Зчеплення",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/stsepleniye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2035,
    "parent": 2017,
    "code": "toplivnaya-sistema",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Паливна система",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/toplivnaya-sistema",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2089,
      2090,
      2091
    ]
  },
  {
    "category_id": 2036,
    "parent": 2017,
    "code": "tormoznaya-sistema",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Гальмівна система",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/tormoznaya-sistema",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2037,
    "parent": 2017,
    "code": "trosy-i-shlangi",
    "position": 18,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Троси та шланги",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/trosy-i-shlangi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2038,
    "parent": 2017,
    "code": "filtry",
    "position": 19,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Фільтри",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/filtry",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2092,
      2093,
      2094,
      2095
    ]
  },
  {
    "category_id": 2039,
    "parent": 2017,
    "code": "tsepi-i-zvezdy",
    "position": 20,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Ланцюги та зірочки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/tsepi-i-zvezdy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2040,
    "parent": 2017,
    "code": "elektrika",
    "position": 21,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Електрика",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/elektrika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2099,
      2100,
      2101,
      2102,
      2103,
      2104,
      2105,
      2106
    ]
  },
  {
    "category_id": 2043,
    "parent": 2018,
    "code": "motoshlemy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мотошоломи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoekipirovka/motoshlemy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2044,
    "parent": 2018,
    "code": "motokurtki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мотокуртки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoekipirovka/motokurtki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2045,
    "parent": 2018,
    "code": "motoshtany",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мотоштани",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoekipirovka/motoshtany",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2046,
    "parent": 2018,
    "code": "motoboty-motobotinki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мотоботи, моточеревики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoekipirovka/motoboty-motobotinki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2047,
    "parent": 2018,
    "code": "motokombinezony",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мотокомбінезони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoekipirovka/motokombinezony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2048,
    "parent": 2018,
    "code": "motoperchatki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Моторукавички",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoekipirovka/motoperchatki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2049,
    "parent": 2018,
    "code": "motodozhdeviki-motobakhily",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мотодощовики, мотобахіли",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoekipirovka/motodozhdeviki-motobakhily",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2050,
    "parent": 2018,
    "code": "motozashchita",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мотозахист",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoekipirovka/motozashchita",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2051,
    "parent": 2018,
    "code": "podshlemniki",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Підшоломники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoekipirovka/podshlemniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2052,
    "parent": 2018,
    "code": "drugaya-motoekipirovka",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інше мотоекіпірування",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoekipirovka/drugaya-motoekipirovka",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2053,
    "parent": 2019,
    "code": "bryzgoviki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Бризковики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoaksessuary/bryzgoviki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2054,
    "parent": 2019,
    "code": "derzhatel-telefona-plansheta",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Тримач для телефона, планшета",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoaksessuary/derzhatel-telefona-plansheta",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2055,
    "parent": 2019,
    "code": "instrumenty",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інструменти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoaksessuary/instrumenty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2056,
    "parent": 2019,
    "code": "krepleniye-nomera-na-mototsikl",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Кріплення для номерів на мотоцикл",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoaksessuary/krepleniye-nomera-na-mototsikl",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2057,
    "parent": 2019,
    "code": "motozerkala",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Дзеркала на мотоцикл",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoaksessuary/motozerkala",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2058,
    "parent": 2019,
    "code": "nakleyki-na-mototsikl-skuter",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Наклейки на мотоцикл, скутер",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoaksessuary/nakleyki-na-mototsikl-skuter",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2059,
    "parent": 2019,
    "code": "obtekateli-i-vetrovyye-stekla",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Обтічники та вітрове скло",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoaksessuary/obtekateli-i-vetrovyye-stekla",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2060,
    "parent": 2019,
    "code": "otboyniki-krashpedy",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Відбійники (крашпеди)",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoaksessuary/otboyniki-krashpedy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2061,
    "parent": 2019,
    "code": "panelnyye-pribory",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Панельні прилади",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoaksessuary/panelnyye-pribory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2062,
    "parent": 2019,
    "code": "peregovornyye-ustroystva",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Переговорні пристрої",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoaksessuary/peregovornyye-ustroystva",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2063,
    "parent": 2019,
    "code": "podnozhki",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Підніжки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoaksessuary/podnozhki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2064,
    "parent": 2019,
    "code": "protivougonnyye-sistemy",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Протиугінні системи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoaksessuary/protivougonnyye-sistemy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2065,
    "parent": 2019,
    "code": "stayling",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Стайлінг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoaksessuary/stayling",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2066,
    "parent": 2019,
    "code": "stendy-i-podyemniki",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Стенди та підйомники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoaksessuary/stendy-i-podyemniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2067,
    "parent": 2019,
    "code": "chekhly-i-nakidki-dlya-mototsikla",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Чохли та накидки для мотоцикла",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoaksessuary/chekhly-i-nakidki-dlya-mototsikla",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2068,
    "parent": 2019,
    "code": "motosumki-kofry-ryukzaki",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мотосумки, кофри, рюкзаки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoaksessuary/motosumki-kofry-ryukzaki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2069,
    "parent": 2019,
    "code": "procheye",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші мотоаксесуари",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motoaksessuary/procheye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2070,
    "parent": 2017,
    "code": "drugiye-motozapchasti",
    "position": 22,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші мотозапчастини",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/drugiye-motozapchasti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2071,
    "parent": 2020,
    "code": "dlya-skuterov",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Для скутерів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/akkumulyatory/dlya-skuterov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2072,
    "parent": 2020,
    "code": "dlya-mototsiklov",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Для мотоциклів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/akkumulyatory/dlya-mototsiklov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2073,
    "parent": 2020,
    "code": "dlya-mopedov",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Для мопедів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/akkumulyatory/dlya-mopedov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2074,
    "parent": 2020,
    "code": "drugiye",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/akkumulyatory/drugiye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2075,
    "parent": 2021,
    "code": "amortizatory-zadniye",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Задні амортизатори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/amortizatory-i-podveska/amortizatory-zadniye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2076,
    "parent": 2021,
    "code": "amortizatory-peredniye",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Передні амортизатори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/amortizatory-i-podveska/amortizatory-peredniye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2077,
    "parent": 2021,
    "code": "saylentbloki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Сайлентблоки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/amortizatory-i-podveska/saylentbloki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2078,
    "parent": 2021,
    "code": "salniki-pylniki-amortizatora",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Сальники, пильовики амортизатора",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/amortizatory-i-podveska/salniki-pylniki-amortizatora",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2079,
    "parent": 2021,
    "code": "traversy",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Траверси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/amortizatory-i-podveska/traversy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2080,
    "parent": 2021,
    "code": "drugiye",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/amortizatory-i-podveska/drugiye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2085,
    "parent": 2031,
    "code": "rukoyatki-i-gripsy",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Рукоятки та гріпси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/rul-i-upravleniye/rukoyatki-i-gripsy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2086,
    "parent": 2031,
    "code": "rulevyye-dempfery",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Кермові демпфери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/rul-i-upravleniye/rulevyye-dempfery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2087,
    "parent": 2031,
    "code": "rychagi",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Важелі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/rul-i-upravleniye/rychagi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2088,
    "parent": 2031,
    "code": "drugiye",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/rul-i-upravleniye/drugiye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2089,
    "parent": 2035,
    "code": "benzobaki-kryshki-datchiki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Бензобаки, кришки, датчики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/toplivnaya-sistema/benzobaki-kryshki-datchiki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2090,
    "parent": 2035,
    "code": "karbyuratory",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Карбюратори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/toplivnaya-sistema/karbyuratory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2091,
    "parent": 2035,
    "code": "drugiye",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/toplivnaya-sistema/drugiye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2092,
    "parent": 2038,
    "code": "vozdushnyye",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Повітряні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/filtry/vozdushnyye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2093,
    "parent": 2038,
    "code": "maslyanyye",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мастильні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/filtry/maslyanyye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2094,
    "parent": 2038,
    "code": "toplivnyye",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Паливні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/filtry/toplivnyye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2095,
    "parent": 2038,
    "code": "drugiye",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/filtry/drugiye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2099,
    "parent": 2040,
    "code": "generatory-i-magneto",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Генератори та магнето",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/elektrika/generatory-i-magneto",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2100,
    "parent": 2040,
    "code": "zamki-zazhiganiya",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Замки запалювання",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/elektrika/zamki-zazhiganiya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2101,
    "parent": 2040,
    "code": "katushki-zazhiganiya",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Котушки запалювання",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/elektrika/katushki-zazhiganiya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2102,
    "parent": 2040,
    "code": "kommutatory",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Комутатори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/elektrika/kommutatory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2103,
    "parent": 2040,
    "code": "rele",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Реле",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/elektrika/rele",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2104,
    "parent": 2040,
    "code": "svechi-zazhiganiya",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Свічки запалювання",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/elektrika/svechi-zazhiganiya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2105,
    "parent": 2040,
    "code": "startery",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Стартери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/elektrika/startery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2106,
    "parent": 2040,
    "code": "drugiye",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/elektrika/drugiye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2107,
    "parent": 891,
    "code": "muzhskaya-odezhda",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Чоловічий одяг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2736,
      2737,
      2738,
      2739,
      2740,
      2741,
      2742,
      2743,
      2744,
      2745,
      2746,
      2828
    ]
  },
  {
    "category_id": 2109,
    "parent": 270,
    "code": "moto-diski-i-kolesa",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мото диски та колеса",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/shiny-diski-i-kolesa/moto-diski-i-kolesa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2110,
    "parent": 2010,
    "code": "trusy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Труси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskoe-bele-plavki/trusy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2111,
    "parent": 2010,
    "code": "termobele",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Термобілизна",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskoe-bele-plavki/termobele",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2112,
    "parent": 2010,
    "code": "noski",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шкарпетки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskoe-bele-plavki/noski",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2113,
    "parent": 2010,
    "code": "plavki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Плавки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskoe-bele-plavki/plavki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2114,
    "parent": 2012,
    "code": "futbolki-mayki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Футболки та майки для вагітних та годуючих",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/odezhda-dlya-beremennyh/futbolki-mayki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2115,
    "parent": 2012,
    "code": "bluzki-tuniki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Блузки та туніки для вагітних та годуючих",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/odezhda-dlya-beremennyh/bluzki-tuniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2116,
    "parent": 2012,
    "code": "platya",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Плаття для вагітних та годуючих",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/odezhda-dlya-beremennyh/platya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2117,
    "parent": 2012,
    "code": "yubki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спідниці для вагітних",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/odezhda-dlya-beremennyh/yubki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2118,
    "parent": 2012,
    "code": "kupalniki",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Купальники для вагітних",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/odezhda-dlya-beremennyh/kupalniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2119,
    "parent": 2012,
    "code": "nizhnee-bele",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Нижня білизна для вагітних та годуючих",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/odezhda-dlya-beremennyh/nizhnee-bele",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2120,
    "parent": 2012,
    "code": "shorty",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шорти для вагітних",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/odezhda-dlya-beremennyh/shorty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2121,
    "parent": 2012,
    "code": "kombinezony",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Комбінезони для вагітних та годуючих",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/odezhda-dlya-beremennyh/kombinezony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2122,
    "parent": 2012,
    "code": "shtany-legginsy",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Штани і легінси для вагітних",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/odezhda-dlya-beremennyh/shtany-legginsy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2123,
    "parent": 2012,
    "code": "dzhinsy",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джинси для вагітних",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/odezhda-dlya-beremennyh/dzhinsy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2124,
    "parent": 2012,
    "code": "kolgotki",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Колготки для вагітних",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/odezhda-dlya-beremennyh/kolgotki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2125,
    "parent": 2012,
    "code": "verhnyaya-odezhda",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Верхній одяг для вагітних",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/odezhda-dlya-beremennyh/verhnyaya-odezhda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2126,
    "parent": 2012,
    "code": "odezhda-dlya-sna-doma",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Одяг для сну і дому для вагітних та годуючих",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/odezhda-dlya-beremennyh/odezhda-dlya-sna-doma",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2127,
    "parent": 2012,
    "code": "svitery-tolstovki",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Светри і толстовки для вагітних та годуючих",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/odezhda-dlya-beremennyh/svitery-tolstovki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2128,
    "parent": 2012,
    "code": "kostyumy",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Костюми для вагітних та годуючих",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/odezhda-dlya-beremennyh/kostyumy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2129,
    "parent": 2012,
    "code": "sarafany",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сарафани для вагітних та годуючих",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/odezhda-dlya-beremennyh/sarafany",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2130,
    "parent": 2012,
    "code": "poyasa",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пояси для вагітних та годуючих",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/odezhda-dlya-beremennyh/poyasa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2131,
    "parent": 2013,
    "code": "beysbolki-kepki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бейсболки та кепки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/golovnye-ubory/beysbolki-kepki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2132,
    "parent": 2013,
    "code": "balaklavy",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Балаклави",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/golovnye-ubory/balaklavy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2135,
    "parent": 2013,
    "code": "komplekty",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Комплекти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/golovnye-ubory/komplekty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2138,
    "parent": 2013,
    "code": "panamy",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Панами",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/golovnye-ubory/panamy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2141,
    "parent": 2013,
    "code": "shapki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шапки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/golovnye-ubory/shapki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2144,
    "parent": 2013,
    "code": "shlyapy",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Капелюхи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/golovnye-ubory/shlyapy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2147,
    "parent": 2013,
    "code": "berety",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Берети",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/golovnye-ubory/berety",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2150,
    "parent": 2013,
    "code": "bandany-baffy",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бандани, бафи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/golovnye-ubory/bandany-baffy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2151,
    "parent": 2013,
    "code": "povyazki-teplye-naushniki",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пов'язки та хутряні навушники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/golovnye-ubory/povyazki-teplye-naushniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2154,
    "parent": 3,
    "code": "aksessuary-dlya-avto",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Аксесуари для авто",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2158,
      2159,
      2160,
      2161,
      2162,
      2163,
      2164,
      2165,
      2166,
      2167,
      2168,
      2169,
      2170,
      2171,
      2172,
      2173,
      2174,
      2175,
      2176,
      2177,
      2178,
      2179,
      2180,
      2181,
      2182,
      2183,
      2184,
      2185,
      2186,
      2187,
      2188,
      2189,
      2190,
      2191,
      2290,
      2291
    ]
  },
  {
    "category_id": 2155,
    "parent": 3,
    "code": "avtozvuk-i-multimedia",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автозвук та мультимедіа",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2192,
      2193,
      2194,
      2195,
      2196,
      2197,
      2198,
      2199
    ]
  },
  {
    "category_id": 2156,
    "parent": 3,
    "code": "gps-navigatory-videoregistratory",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "GPS-навігатори / відеореєстратори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/gps-navigatory-videoregistratory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2247,
      2248,
      2249
    ]
  },
  {
    "category_id": 2157,
    "parent": 3,
    "code": "transport-na-zapchasti-avtorazborki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Транспорт на запчастини / авторозбірки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2250,
      2251,
      2252,
      2253,
      2254,
      2255,
      2256,
      2257,
      2258,
      2259,
      2260,
      2261,
      2262,
      2263,
      2264,
      2265,
      2266,
      2267,
      2268,
      2269,
      2270,
      2271,
      2272,
      2273,
      2274,
      2275,
      2276,
      2277,
      2278,
      2279,
      2280,
      2281,
      2282,
      2283,
      2284,
      2285,
      2286,
      2287,
      2288,
      2289
    ]
  },
  {
    "category_id": 2158,
    "parent": 2154,
    "code": "avtokovriki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автоковрики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/avtokovriki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2159,
    "parent": 2154,
    "code": "salonnyye-chasy-pribory-bk",
    "position": 30,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Салонний годинник, прилади, БК",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/salonnyye-chasy-pribory-bk",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2160,
    "parent": 2154,
    "code": "avtosignalizatsii-blokiratory",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автосигналізації, блокувачі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/avtosignalizatsii-blokiratory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2161,
    "parent": 2154,
    "code": "avtokholodilniki",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автохолодильники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/avtokholodilniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2162,
    "parent": 2154,
    "code": "avtoshtorki",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автошторки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/avtoshtorki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2163,
    "parent": 2154,
    "code": "dlya-tyuninga",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Для тюнінгу",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/dlya-tyuninga",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2164,
    "parent": 2154,
    "code": "aromatizatory-ozonatory",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Ароматизатори, озонатори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/aromatizatory-ozonatory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2165,
    "parent": 2154,
    "code": "bagazhniki",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Багажники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/bagazhniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2166,
    "parent": 2154,
    "code": "bryzgoviki",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Бризковики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/bryzgoviki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2167,
    "parent": 2154,
    "code": "ventilyatory-avtomobilnyye",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Вентилятор в авто",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/ventilyatory-avtomobilnyye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2168,
    "parent": 2154,
    "code": "vozdukhozaborniki",
    "position": 26,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Повітрозабірники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/vozdukhozaborniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2169,
    "parent": 2154,
    "code": "dekorativnaya-podsvetka-avto",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Декоративна підсвітка авто",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/dekorativnaya-podsvetka-avto",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2170,
    "parent": 2154,
    "code": "derzhateli-i-podstavki",
    "position": 25,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Підставки та тримачі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/derzhateli-i-podstavki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2171,
    "parent": 2154,
    "code": "deflektory",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Дефлектори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/deflektory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2172,
    "parent": 2154,
    "code": "domkraty",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Домкрати",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/domkraty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2173,
    "parent": 2154,
    "code": "lebedki",
    "position": 21,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Лебідки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/lebedki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2174,
    "parent": 2154,
    "code": "avto-nasosy-kompressory-manometry",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автонасоси, компресори, манометри",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/avto-nasosy-kompressory-manometry",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2175,
    "parent": 2154,
    "code": "avto-instrumenty-nabory-klyuchey",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Авто інструменти, набори ключів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/avto-instrumenty-nabory-klyuchey",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2176,
    "parent": 2154,
    "code": "puskovyye-i-zaryadnyye-ustroystva",
    "position": 28,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Пускові та зарядні пристрої",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/puskovyye-i-zaryadnyye-ustroystva",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2177,
    "parent": 2154,
    "code": "nakleyki-emblemy-znachki",
    "position": 22,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Наклейки, емблеми, значки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/nakleyki-emblemy-znachki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2178,
    "parent": 2154,
    "code": "nasadki-glushitelya",
    "position": 23,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Насадки на глушник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/nasadki-glushitelya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2179,
    "parent": 2154,
    "code": "organayzery-avtomobilnyye",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автомобільний органайзер",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/organayzery-avtomobilnyye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2180,
    "parent": 2154,
    "code": "podogrev-sideniy-i-avtomassazhery",
    "position": 24,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Підігрів сидінь та автомасажери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/podogrev-sideniy-i-avtomassazhery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2181,
    "parent": 2154,
    "code": "podushki-v-avtomobil",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автоподушки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/podushki-v-avtomobil",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2182,
    "parent": 2154,
    "code": "porogi-i-nakladki",
    "position": 27,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Пороги та накладки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/porogi-i-nakladki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2183,
    "parent": 2154,
    "code": "ramki-nomernogo-znaka",
    "position": 29,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Рамки номерного знаку",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/ramki-nomernogo-znaka",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2184,
    "parent": 2154,
    "code": "ognetushitel-aptechka-avariyka",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Вогнегасник, аптечка, аварійка",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/ognetushitel-aptechka-avariyka",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2185,
    "parent": 2154,
    "code": "tonirovka-i-solntsezashchita",
    "position": 31,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Тонування та сонцезахист",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/tonirovka-i-solntsezashchita",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2186,
    "parent": 2154,
    "code": "farkopy",
    "position": 32,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Фаркопи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/farkopy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2187,
    "parent": 2154,
    "code": "tsepi-braslety-protivoskolzheniya",
    "position": 20,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Ланцюги, браслети протиковзання",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/tsepi-braslety-protivoskolzheniya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2188,
    "parent": 2154,
    "code": "chekhly-tenty-nakidki",
    "position": 33,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Чохли, тенти, накидки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/chekhly-tenty-nakidki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2189,
    "parent": 2154,
    "code": "shumoizolyatsiya",
    "position": 34,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Шумоізоляція",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/shumoizolyatsiya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2190,
    "parent": 2154,
    "code": "shchetki-i-skrebki",
    "position": 35,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Щітки та скребки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/shchetki-i-skrebki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2191,
    "parent": 2154,
    "code": "prochiye-avtoaksessuary",
    "position": 36,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші автоаксесуари",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/prochiye-avtoaksessuary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2192,
    "parent": 2155,
    "code": "magnitoly",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автомагнітоли",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/magnitoly",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2200,
      2201,
      2202,
      2203,
      2204,
      2205,
      2206,
      2207,
      2208,
      2209,
      2210,
      2211,
      2212,
      2213,
      2214,
      2215
    ]
  },
  {
    "category_id": 2193,
    "parent": 2155,
    "code": "kolonki-sabvufery",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Колонки / сабвуфери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/kolonki-sabvufery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2216,
      2217,
      2218,
      2219,
      2220,
      2221,
      2222,
      2223,
      2224,
      2225,
      2226,
      2227,
      2228,
      2229,
      2230
    ]
  },
  {
    "category_id": 2194,
    "parent": 2155,
    "code": "usiliteli",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Підсилювачі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/usiliteli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2231,
      2232,
      2233,
      2234,
      2235,
      2236,
      2237,
      2238,
      2239,
      2240,
      2241,
      2242,
      2243,
      2244,
      2245,
      2246
    ]
  },
  {
    "category_id": 2195,
    "parent": 2155,
    "code": "antenny-dlya-avto",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Антени для авто",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/antenny-dlya-avto",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2196,
    "parent": 2155,
    "code": "fm-transmittery",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "FM-трансмітери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/fm-transmittery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2197,
    "parent": 2155,
    "code": "kabeli-adaptery-predokhraniteli",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Кабелі, адаптери, запобіжники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/kabeli-adaptery-predokhraniteli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2198,
    "parent": 2155,
    "code": "dvd-tv-drugaya-multimedia",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "DVD, TV, інша мультимедіа",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/dvd-tv-drugaya-multimedia",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2199,
    "parent": 2155,
    "code": "prochiye-komponenty",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші компоненти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/prochiye-komponenty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2200,
    "parent": 2192,
    "code": "pioneer",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Pioneer",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/magnitoly/pioneer",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2201,
    "parent": 2192,
    "code": "jvc",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "JVC",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/magnitoly/jvc",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2202,
    "parent": 2192,
    "code": "sony",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Sony",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/magnitoly/sony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2203,
    "parent": 2192,
    "code": "kenwood",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Kenwood",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/magnitoly/kenwood",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2204,
    "parent": 2192,
    "code": "alpine",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Alpine",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/magnitoly/alpine",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2205,
    "parent": 2192,
    "code": "prology",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Prology",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/magnitoly/prology",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2206,
    "parent": 2192,
    "code": "blaupunkt",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Blaupunkt",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/magnitoly/blaupunkt",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2207,
    "parent": 2192,
    "code": "cyclon",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Cyclon",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/magnitoly/cyclon",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2208,
    "parent": 2192,
    "code": "shuttle",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "SHUTTLE",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/magnitoly/shuttle",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2209,
    "parent": 2192,
    "code": "mystery",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Mystery",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/magnitoly/mystery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2210,
    "parent": 2192,
    "code": "clarion",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Clarion",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/magnitoly/clarion",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2211,
    "parent": 2192,
    "code": "lg",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "LG",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/magnitoly/lg",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2212,
    "parent": 2192,
    "code": "swat",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Swat",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/magnitoly/swat",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2213,
    "parent": 2192,
    "code": "philips",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Philips",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/magnitoly/philips",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2214,
    "parent": 2192,
    "code": "incar",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Incar",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/magnitoly/incar",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2215,
    "parent": 2192,
    "code": "magnitoly-drugikh-marok",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Магнітоли інших марок",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/magnitoly/magnitoly-drugikh-marok",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2216,
    "parent": 2193,
    "code": "alpine",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Alpine",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/kolonki-sabvufery/alpine",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2217,
    "parent": 2193,
    "code": "blaupunkt",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Blaupunkt",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/kolonki-sabvufery/blaupunkt",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2218,
    "parent": 2193,
    "code": "dls",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "DLS",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/kolonki-sabvufery/dls",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2219,
    "parent": 2193,
    "code": "edge",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Edge",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/kolonki-sabvufery/edge",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2220,
    "parent": 2193,
    "code": "focal",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Focal",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/kolonki-sabvufery/focal",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2221,
    "parent": 2193,
    "code": "hertz",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Hertz",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/kolonki-sabvufery/hertz",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2222,
    "parent": 2193,
    "code": "jbl",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "JBL",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/kolonki-sabvufery/jbl",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2223,
    "parent": 2193,
    "code": "jvc",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "JVC",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/kolonki-sabvufery/jvc",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2224,
    "parent": 2193,
    "code": "kenwood",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Kenwood",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/kolonki-sabvufery/kenwood",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2225,
    "parent": 2193,
    "code": "kicx",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Kicx",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/kolonki-sabvufery/kicx",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2226,
    "parent": 2193,
    "code": "mystery",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Mystery",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/kolonki-sabvufery/mystery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2227,
    "parent": 2193,
    "code": "pioneer",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Pioneer",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/kolonki-sabvufery/pioneer",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2228,
    "parent": 2193,
    "code": "sony",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Sony",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/kolonki-sabvufery/sony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2229,
    "parent": 2193,
    "code": "supra",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Supra",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/kolonki-sabvufery/supra",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2230,
    "parent": 2193,
    "code": "kolonki-sabvufery-drugikh-marok",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Колонки/сабвуфери інших марок",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/kolonki-sabvufery/kolonki-sabvufery-drugikh-marok",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2231,
    "parent": 2194,
    "code": "alpine",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Alpine",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/usiliteli/alpine",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2232,
    "parent": 2194,
    "code": "blaupunkt",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Blaupunkt",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/usiliteli/blaupunkt",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2233,
    "parent": 2194,
    "code": "dls",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "DLS",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/usiliteli/dls",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2234,
    "parent": 2194,
    "code": "ground-zero",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Ground Zero",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/usiliteli/ground-zero",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2235,
    "parent": 2194,
    "code": "hertz",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Hertz",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/usiliteli/hertz",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2236,
    "parent": 2194,
    "code": "jbl",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "JBL",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/usiliteli/jbl",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2237,
    "parent": 2194,
    "code": "jvc",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "JVC",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/usiliteli/jvc",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2238,
    "parent": 2194,
    "code": "kenwood",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Kenwood",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/usiliteli/kenwood",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2239,
    "parent": 2194,
    "code": "kicx",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Kicx",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/usiliteli/kicx",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2240,
    "parent": 2194,
    "code": "magnat",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Magnat",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/usiliteli/magnat",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2241,
    "parent": 2194,
    "code": "mystery",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Mystery",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/usiliteli/mystery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2242,
    "parent": 2194,
    "code": "pioneer",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Pioneer",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/usiliteli/pioneer",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2243,
    "parent": 2194,
    "code": "pride",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Pride",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/usiliteli/pride",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2244,
    "parent": 2194,
    "code": "sony",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Sony",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/usiliteli/sony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2245,
    "parent": 2194,
    "code": "supra",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Supra",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/usiliteli/supra",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2246,
    "parent": 2194,
    "code": "usiliteli-drugikh-marok",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Підсилювачі інших марок",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozvuk-i-multimedia/usiliteli/usiliteli-drugikh-marok",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2247,
    "parent": 2156,
    "code": "gps-navigatory",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "GPS-навігатори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/gps-navigatory-videoregistratory/gps-navigatory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2248,
    "parent": 2156,
    "code": "navigatsionnyye-karty-i-gps-aksessuary",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Навігаційні карти та GPS-аксесуари",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/gps-navigatory-videoregistratory/navigatsionnyye-karty-i-gps-aksessuary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2249,
    "parent": 2156,
    "code": "videoregistratory",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Відеореєстратори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/gps-navigatory-videoregistratory/videoregistratory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2250,
    "parent": 2157,
    "code": "acura",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Acura",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/acura",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2251,
    "parent": 2157,
    "code": "audi",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Audi",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/audi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2252,
    "parent": 2157,
    "code": "bmw",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "BMW",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/bmw",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2253,
    "parent": 2157,
    "code": "chevrolet",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Chevrolet",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/chevrolet",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2254,
    "parent": 2157,
    "code": "chrysler",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Chrysler",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/chrysler",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2255,
    "parent": 2157,
    "code": "citroen",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Citroen",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/citroen",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2256,
    "parent": 2157,
    "code": "daewoo",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Daewoo",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/daewoo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2257,
    "parent": 2157,
    "code": "dodge",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Dodge",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/dodge",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2258,
    "parent": 2157,
    "code": "fiat",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Fiat",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/fiat",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2259,
    "parent": 2157,
    "code": "ford",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Ford",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/ford",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2260,
    "parent": 2157,
    "code": "honda",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Honda",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/honda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2261,
    "parent": 2157,
    "code": "hyundai",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Hyundai",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/hyundai",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2262,
    "parent": 2157,
    "code": "infiniti",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Infiniti",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/infiniti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2263,
    "parent": 2157,
    "code": "jeep",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Jeep",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/jeep",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2264,
    "parent": 2157,
    "code": "kia",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Kia",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/kia",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2265,
    "parent": 2157,
    "code": "land-rover",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Land Rover",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/land-rover",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2266,
    "parent": 2157,
    "code": "lexus",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Lexus",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/lexus",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2267,
    "parent": 2157,
    "code": "lincoln",
    "position": 18,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Lincoln",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/lincoln",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2268,
    "parent": 2157,
    "code": "mazda",
    "position": 19,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Mazda",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/mazda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2269,
    "parent": 2157,
    "code": "mercedes-benz",
    "position": 20,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Mercedes-Benz",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/mercedes-benz",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2270,
    "parent": 2157,
    "code": "mitsubishi",
    "position": 21,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Mitsubishi",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/mitsubishi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2271,
    "parent": 2157,
    "code": "nissan",
    "position": 22,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Nissan",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/nissan",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2272,
    "parent": 2157,
    "code": "opel",
    "position": 23,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Opel",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/opel",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2273,
    "parent": 2157,
    "code": "peugeot",
    "position": 24,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Peugeot",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/peugeot",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2274,
    "parent": 2157,
    "code": "porsche",
    "position": 25,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Porsche",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/porsche",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2275,
    "parent": 2157,
    "code": "renault",
    "position": 26,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Renault",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/renault",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2276,
    "parent": 2157,
    "code": "rover",
    "position": 27,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Rover",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/rover",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2277,
    "parent": 2157,
    "code": "seat",
    "position": 28,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Seat",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/seat",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2278,
    "parent": 2157,
    "code": "skoda",
    "position": 29,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Skoda",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/skoda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2279,
    "parent": 2157,
    "code": "subaru",
    "position": 30,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Subaru",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/subaru",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2280,
    "parent": 2157,
    "code": "suzuki",
    "position": 31,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Suzuki",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/suzuki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2281,
    "parent": 2157,
    "code": "toyota",
    "position": 32,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Toyota",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/toyota",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2282,
    "parent": 2157,
    "code": "volkswagen",
    "position": 33,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Volkswagen",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/volkswagen",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2283,
    "parent": 2157,
    "code": "volvo",
    "position": 34,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Volvo",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/volvo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2284,
    "parent": 2157,
    "code": "vaz",
    "position": 35,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "ВАЗ",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/vaz",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2285,
    "parent": 2157,
    "code": "gaz",
    "position": 36,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "ГАЗ",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/gaz",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2286,
    "parent": 2157,
    "code": "zaz",
    "position": 37,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "ЗАЗ",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/zaz",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2287,
    "parent": 2157,
    "code": "izh",
    "position": 38,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "ИЖ",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/izh",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2288,
    "parent": 2157,
    "code": "moskvich",
    "position": 39,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Москвич",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/moskvich",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2289,
    "parent": 2157,
    "code": "drugoy-brend",
    "position": 40,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інший бренд",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/transport-na-zapchasti-avtorazborki/drugoy-brend",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2290,
    "parent": 2154,
    "code": "kanistry",
    "position": 19,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Каністри",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/kanistry",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2291,
    "parent": 2154,
    "code": "krepezhnyye-elementy",
    "position": 18,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Елементи кріплення",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/aksessuary-dlya-avto/krepezhnyye-elementy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2292,
    "parent": 2031,
    "code": "rul",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Кермо",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/motozapchasti/rul-i-upravleniye/rul",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2293,
    "parent": 2010,
    "code": "drugoe",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інша чоловіча білизна",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskoe-bele-plavki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2294,
    "parent": 2012,
    "code": "drugoe",
    "position": 18,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інший одяг для вагітних",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/odezhda-dlya-beremennyh/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2295,
    "parent": 2013,
    "code": "drugoe",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші головні убори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/golovnye-ubory/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2296,
    "parent": 2011,
    "code": "plyazhnye-platya-tuniki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пляжні сукні та туніки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/plyazhnye-platya-tuniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2297,
    "parent": 2011,
    "code": "pareo-nakidki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Парео, накидки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/pareo-nakidki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2298,
    "parent": 2011,
    "code": "plyazhnye-shorty",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пляжні шорти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/plyazhnye-shorty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2299,
    "parent": 2011,
    "code": "kupalniki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Купальники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/kupalniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2311,
      2315,
      2319,
      2323
    ]
  },
  {
    "category_id": 2300,
    "parent": 2011,
    "code": "trusy",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Труси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/trusy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2301,
    "parent": 2011,
    "code": "byustgaltery",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бюстгалтери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/byustgaltery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2312,
      2316,
      2320,
      2324,
      2327,
      2329,
      2331,
      2334
    ]
  },
  {
    "category_id": 2302,
    "parent": 2011,
    "code": "komplekty-nizhnego-belya",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Комплекти спідньої білизни",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/komplekty-nizhnego-belya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2303,
    "parent": 2011,
    "code": "korrektiruyushchee-bele",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Корегуюча білизна",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/korrektiruyushchee-bele",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2313,
      2317,
      2321,
      2325,
      2333,
      2335
    ]
  },
  {
    "category_id": 2304,
    "parent": 2011,
    "code": "termobele",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Термобілизна",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/termobele",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2305,
    "parent": 2011,
    "code": "eroticheskoe-bele",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Еротична білизна",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/eroticheskoe-bele",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2306,
    "parent": 2011,
    "code": "kombinatsii",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Комбінації",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/kombinatsii",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2307,
    "parent": 2011,
    "code": "topy-byuste",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Топи бюстьє",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/topy-byuste",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2308,
    "parent": 2011,
    "code": "poyasa-podvyazki",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пояси і підв'язки для панчох",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/poyasa-podvyazki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2309,
    "parent": 2011,
    "code": "kombidressy",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Комбідреси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/kombidressy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2310,
    "parent": 2011,
    "code": "chulki-noski-kolgotki",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Панчохи / шкарпетки / колготки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/chulki-noski-kolgotki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2314,
      2318,
      2322,
      2326,
      2328,
      2330,
      2336
    ]
  },
  {
    "category_id": 2311,
    "parent": 2299,
    "code": "razdelnye",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Роздільні купальники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/kupalniki/razdelnye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2312,
    "parent": 2301,
    "code": "balkonet",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Балконет",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/byustgaltery/balkonet",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2313,
    "parent": 2303,
    "code": "shorty-pantalony",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Корегуючі шорти і панталони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/korrektiruyushchee-bele/shorty-pantalony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2314,
    "parent": 2310,
    "code": "noski",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шкарпетки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/chulki-noski-kolgotki/noski",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2315,
    "parent": 2299,
    "code": "slitnye",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Суцільні купальники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/kupalniki/slitnye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2316,
    "parent": 2301,
    "code": "bando-bez-breteley",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бандо (без бретелів)",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/byustgaltery/bando-bez-breteley",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2317,
    "parent": 2303,
    "code": "poyasa",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Корегуючі пояси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/korrektiruyushchee-bele/poyasa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2318,
    "parent": 2310,
    "code": "chulki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Панчохи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/chulki-noski-kolgotki/chulki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2319,
    "parent": 2299,
    "code": "sportivnye",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні купальники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/kupalniki/sportivnye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2320,
    "parent": 2301,
    "code": "bralett-bez-kostochek",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бралетт (без кісточок)",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/byustgaltery/bralett-bez-kostochek",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2321,
    "parent": 2303,
    "code": "korsety",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Корегуючі корсети",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/korrektiruyushchee-bele/korsety",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2322,
    "parent": 2310,
    "code": "kolgotki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Колготки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/chulki-noski-kolgotki/kolgotki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2323,
    "parent": 2299,
    "code": "drugoe",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші купальники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/kupalniki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2324,
    "parent": 2301,
    "code": "push-up",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бюстгалтери з Push up",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/byustgaltery/push-up",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2325,
    "parent": 2303,
    "code": "bodi",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Корегуючі боді",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/korrektiruyushchee-bele/bodi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2326,
    "parent": 2310,
    "code": "getry-golfy",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Гетри та гольфи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/chulki-noski-kolgotki/getry-golfy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2327,
    "parent": 2301,
    "code": "besshovnye",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Безшовні бюстгалтери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/byustgaltery/besshovnye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2328,
    "parent": 2310,
    "code": "sledy",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сліди",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/chulki-noski-kolgotki/sledy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2329,
    "parent": 2301,
    "code": "myagkie",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "М'які бюстгалтери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/byustgaltery/myagkie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2330,
    "parent": 2310,
    "code": "kompressionnye-chulki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Компресійні панчохи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/chulki-noski-kolgotki/kompressionnye-chulki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2331,
    "parent": 2301,
    "code": "topy",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бюстгалтери топи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/byustgaltery/topy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2332,
    "parent": 2011,
    "code": "korsety",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Корсети",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/korsety",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2333,
    "parent": 2303,
    "code": "trusy",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Корегуючі труси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/korrektiruyushchee-bele/trusy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2334,
    "parent": 2301,
    "code": "drugoe",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші бюстгалтери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/byustgaltery/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2335,
    "parent": 2303,
    "code": "drugoe",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інша корегуюча білизна",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/korrektiruyushchee-bele/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2336,
    "parent": 2310,
    "code": "drugoe",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/chulki-noski-kolgotki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2337,
    "parent": 2011,
    "code": "drugoe",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інша жіноча білизна",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskoe-bele-kupalniki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2338,
    "parent": 897,
    "code": "dlya-muzhchin",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Подарунки для чоловіків",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/podarki/dlya-muzhchin",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2339,
    "parent": 897,
    "code": "dlya-zhenshchin",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Подарунки для жінок",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/podarki/dlya-zhenshchin",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2340,
    "parent": 897,
    "code": "dlya-detey",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Подарунки для дітей",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/podarki/dlya-detey",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2341,
    "parent": 897,
    "code": "den-rozhdeniya",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Подарунки на день народження",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/podarki/den-rozhdeniya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2342,
    "parent": 897,
    "code": "svadba",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Подарунки на весілля",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/podarki/svadba",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2343,
    "parent": 897,
    "code": "8-marta",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Подарунки на 8 березня",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/podarki/8-marta",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2344,
    "parent": 897,
    "code": "noviy-god",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Подарунки на Новий рік",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/podarki/noviy-god",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2345,
    "parent": 897,
    "code": "kollegam",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Подарунки колегам",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/podarki/kollegam",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2346,
    "parent": 897,
    "code": "vkusnye",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Смачні подарунки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/podarki/vkusnye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2347,
    "parent": 897,
    "code": "korobki-upakovki",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Подарункові коробки та пакування",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/podarki/korobki-upakovki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2348,
    "parent": 897,
    "code": "ruchnoy-raboty",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Подарунки ручної роботи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/podarki/ruchnoy-raboty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2352,
      2353,
      2354,
      2355
    ]
  },
  {
    "category_id": 2349,
    "parent": 897,
    "code": "otkrytki-konverty",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Листівки та конверти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/podarki/otkrytki-konverty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2350,
    "parent": 897,
    "code": "bukety",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Букети",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/podarki/bukety",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2351,
    "parent": 897,
    "code": "vozdushnye-shary-i-kompozicii",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Повітряні кульки та композиції з них",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/podarki/vozdushnye-shary-i-kompozicii",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2352,
    "parent": 2348,
    "code": "mylo",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Мило ручної роботи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/podarki/ruchnoy-raboty/mylo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2353,
    "parent": 2348,
    "code": "svechi",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Свічки ручної роботи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/podarki/ruchnoy-raboty/svechi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2354,
    "parent": 2348,
    "code": "igrushki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Іграшки ручної роботи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/podarki/ruchnoy-raboty/igrushki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2355,
    "parent": 2348,
    "code": "drugoe",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші подарунки ручної роботи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/podarki/ruchnoy-raboty/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2356,
    "parent": 897,
    "code": "drugoe",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші подарунки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/podarki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2357,
    "parent": 3,
    "code": "avtozapchasti",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автозапчастини",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2358,
      2359,
      2360,
      2361,
      2362,
      2363,
      2364,
      2365,
      2366,
      2367,
      2368,
      2369,
      2370,
      2371,
      2372,
      2373,
      2374,
      2375,
      2376
    ]
  },
  {
    "category_id": 2358,
    "parent": 2357,
    "code": "dvigatel",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Двигун",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/dvigatel",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2442,
      2443,
      2444,
      2445,
      2446,
      2447,
      2448,
      2449,
      2450,
      2451,
      2452,
      2453,
      2454,
      2455,
      2456,
      2457,
      2458
    ]
  },
  {
    "category_id": 2359,
    "parent": 2357,
    "code": "vykhlopnaya-sistema",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Вихлопна система",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/vykhlopnaya-sistema",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2382,
      2383,
      2384,
      2385,
      2386,
      2387,
      2388,
      2389,
      2390,
      2391,
      2392,
      2393
    ]
  },
  {
    "category_id": 2360,
    "parent": 2357,
    "code": "transmissiya-kpp-i-akpp",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Трансмісія / КПП та АКПП",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/transmissiya-kpp-i-akpp",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2459,
      2460,
      2461,
      2462,
      2463,
      2464,
      2465,
      2466,
      2467,
      2468,
      2469,
      2470,
      2471,
      2472,
      2474,
      2475
    ]
  },
  {
    "category_id": 2361,
    "parent": 2357,
    "code": "rulevoye-upravleniye",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Кермове управління",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/rulevoye-upravleniye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2476,
      2477,
      2478,
      2479,
      2480,
      2481,
      2482,
      2483,
      2484,
      2485,
      2486
    ]
  },
  {
    "category_id": 2362,
    "parent": 2357,
    "code": "khodovaya-chast-podveska",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Ходова частина / підвіска",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/khodovaya-chast-podveska",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2509,
      2510,
      2511,
      2512,
      2513,
      2514,
      2515,
      2516,
      2517,
      2518,
      2519,
      2520,
      2521,
      2522,
      2523
    ]
  },
  {
    "category_id": 2363,
    "parent": 2357,
    "code": "toplivnaya-sistema",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Паливна система",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/toplivnaya-sistema",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2501,
      2502,
      2503,
      2504,
      2505,
      2506,
      2507,
      2508
    ]
  },
  {
    "category_id": 2364,
    "parent": 2357,
    "code": "tormoznaya-sistema",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Гальмівна система",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/tormoznaya-sistema",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2487,
      2488,
      2489,
      2490,
      2491,
      2492,
      2493,
      2494,
      2495,
      2496,
      2497,
      2498,
      2499,
      2500
    ]
  },
  {
    "category_id": 2365,
    "parent": 2357,
    "code": "kuzovnyye-zapchasti",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Кузовні запчастини",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/kuzovnyye-zapchasti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2417,
      2418,
      2419,
      2420,
      2421,
      2422,
      2423,
      2424,
      2425,
      2426,
      2427,
      2428,
      2429,
      2430,
      2431,
      2616,
      2617
    ]
  },
  {
    "category_id": 2366,
    "parent": 2357,
    "code": "detali-salona",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Деталі салону",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/detali-salona",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2398,
      2399,
      2400,
      2401,
      2402,
      2403,
      2404,
      2405,
      2406,
      2407,
      2408,
      2409,
      2410,
      2411,
      2412,
      2413,
      2414,
      2415,
      2416
    ]
  },
  {
    "category_id": 2367,
    "parent": 2357,
    "code": "konditsioner-otopitel-ventilyatsiya",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Кондиціонер, обігрівач, вентиляція",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/konditsioner-otopitel-ventilyatsiya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2432,
      2433,
      2434,
      2435,
      2436,
      2437,
      2438,
      2439,
      2440,
      2441
    ]
  },
  {
    "category_id": 2368,
    "parent": 2357,
    "code": "sistema-podachi-vozdukha",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Система подачі повітря",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-podachi-vozdukha",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2524,
      2525,
      2526,
      2527,
      2528
    ]
  },
  {
    "category_id": 2369,
    "parent": 2357,
    "code": "sistema-zazhiganiya",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Система запалювання",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-zazhiganiya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2529,
      2530,
      2531,
      2532,
      2533,
      2534,
      2535,
      2536
    ]
  },
  {
    "category_id": 2370,
    "parent": 2357,
    "code": "sistema-ochistki-okon-i-far",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Система очищення вікон та фар",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-ochistki-okon-i-far",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2537,
      2538,
      2539,
      2540,
      2541,
      2542,
      2543
    ]
  },
  {
    "category_id": 2371,
    "parent": 2357,
    "code": "fary-i-osveshcheniye",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Фари та освітлення",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/fary-i-osveshcheniye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2544,
      2545,
      2546,
      2547,
      2548,
      2549,
      2550,
      2551,
      2552,
      2553
    ]
  },
  {
    "category_id": 2372,
    "parent": 2357,
    "code": "elektrooborudovaniye",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Електрообладнання",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/elektrooborudovaniye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2554,
      2555,
      2556,
      2557,
      2558,
      2559,
      2560,
      2561,
      2562,
      2563,
      2564
    ]
  },
  {
    "category_id": 2373,
    "parent": 2357,
    "code": "filtry",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Фільтри",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/filtry",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2565,
      2566,
      2567,
      2568,
      2569,
      2570
    ]
  },
  {
    "category_id": 2374,
    "parent": 2357,
    "code": "avtostekla",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автоскло",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/avtostekla",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2377,
      2378,
      2379,
      2380,
      2381
    ]
  },
  {
    "category_id": 2375,
    "parent": 2357,
    "code": "gbo",
    "position": 18,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "ГБО",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/gbo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2394,
      2395,
      2396,
      2397
    ]
  },
  {
    "category_id": 2376,
    "parent": 2357,
    "code": "drugiye-vidy-zapchastey",
    "position": 19,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші види запчастин",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/drugiye-vidy-zapchastey",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2377,
    "parent": 2374,
    "code": "stekla-vetrovyye-lobovyye",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Скло вітрове (лобове)",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/avtostekla/stekla-vetrovyye-lobovyye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2378,
    "parent": 2374,
    "code": "stekla-bokovyye",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Бічне скло",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/avtostekla/stekla-bokovyye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2379,
    "parent": 2374,
    "code": "dvernyye-stekla",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Скло дверей",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/avtostekla/dvernyye-stekla",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2380,
    "parent": 2374,
    "code": "zadniye-stekla",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Заднє скло",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/avtostekla/zadniye-stekla",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2381,
    "parent": 2374,
    "code": "avtostekla-drugikh-vidov",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автоскло інших видів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/avtostekla/avtostekla-drugikh-vidov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2382,
    "parent": 2359,
    "code": "vypusknyye-kollektory",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Вихлопні колектори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/vykhlopnaya-sistema/vypusknyye-kollektory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2383,
    "parent": 2359,
    "code": "vykhlopnyye-sistemy-v-sbore",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Вихлопні системи в зборі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/vykhlopnaya-sistema/vykhlopnyye-sistemy-v-sbore",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2384,
    "parent": 2359,
    "code": "glushiteli",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Глушники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/vykhlopnaya-sistema/glushiteli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2385,
    "parent": 2359,
    "code": "gofry",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Гофри",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/vykhlopnaya-sistema/gofry",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2386,
    "parent": 2359,
    "code": "datchiki-kislorodnyye-lyambda-zond",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Датчики кисневі (лямбда-зонд)",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/vykhlopnaya-sistema/datchiki-kislorodnyye-lyambda-zond",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2387,
    "parent": 2359,
    "code": "datchiki-temperatury-topliva-masla-i-drugiye",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Датчики температури палива, оливи та інші",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/vykhlopnaya-sistema/datchiki-temperatury-topliva-masla-i-drugiye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2388,
    "parent": 2359,
    "code": "katalizatory",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Каталізатори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/vykhlopnaya-sistema/katalizatory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2389,
    "parent": 2359,
    "code": "klapany-egr",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Клапани EGR",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/vykhlopnaya-sistema/klapany-egr",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2390,
    "parent": 2359,
    "code": "plamegasiteli",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Полум'ягасники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/vykhlopnaya-sistema/plamegasiteli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2391,
    "parent": 2359,
    "code": "rezonatory",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Резонатори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/vykhlopnaya-sistema/rezonatory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2392,
    "parent": 2359,
    "code": "truby-i-chasti-vykhlopnoy-sistemy",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Труби та частини вихлопної системи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/vykhlopnaya-sistema/truby-i-chasti-vykhlopnoy-sistemy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2393,
    "parent": 2359,
    "code": "drugiye-zapchasti-vykhlopnoy-sistemy",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші запчастини вихлопної системи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/vykhlopnaya-sistema/drugiye-zapchasti-vykhlopnoy-sistemy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2394,
    "parent": 2375,
    "code": "ballony-gazovyye",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Балони газові",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/gbo/ballony-gazovyye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2395,
    "parent": 2375,
    "code": "reduktory-gazovyye",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Редуктори газові",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/gbo/reduktory-gazovyye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2396,
    "parent": 2375,
    "code": "forsunki-gazovyye",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Форсунки газові",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/gbo/forsunki-gazovyye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2397,
    "parent": 2375,
    "code": "drugiye-komponenty-gbo",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші компоненти ГБО",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/gbo/drugiye-komponenty-gbo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2398,
    "parent": 2366,
    "code": "avtosideniya-kresla",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автосидіння, крісла",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/detali-salona/avtosideniya-kresla",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2399,
    "parent": 2366,
    "code": "bardachki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Бардачки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/detali-salona/bardachki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2400,
    "parent": 2366,
    "code": "dvernyye-karty-obshivka-dveri",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Дверні карти, обшивка дверей",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/detali-salona/dvernyye-karty-obshivka-dveri",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2401,
    "parent": 2366,
    "code": "zerkala",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Дзеркала",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/detali-salona/zerkala",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2402,
    "parent": 2366,
    "code": "zadnyaya-polka",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Задня полиця",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/detali-salona/zadnyaya-polka",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2403,
    "parent": 2366,
    "code": "knopki-pereklyuchateli",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Кнопки / Перемикачі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/detali-salona/knopki-pereklyuchateli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2404,
    "parent": 2366,
    "code": "material-dlya-pola",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Матеріал для підлоги",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/detali-salona/material-dlya-pola",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2405,
    "parent": 2366,
    "code": "pepelnitsa",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Попільничка",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/detali-salona/pepelnitsa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2406,
    "parent": 2366,
    "code": "plastik-salona",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Пластик салону",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/detali-salona/plastik-salona",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2407,
    "parent": 2366,
    "code": "podlokotnik",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Підлокітник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/detali-salona/podlokotnik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2408,
    "parent": 2366,
    "code": "podstakannik",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Підсклянник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/detali-salona/podstakannik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2409,
    "parent": 2366,
    "code": "podushki-bezopasnosti-airbag",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Подушки безпеки, AIRBAG",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/detali-salona/podushki-bezopasnosti-airbag",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2410,
    "parent": 2366,
    "code": "prikurivatel",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Прикурювач",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/detali-salona/prikurivatel",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2411,
    "parent": 2366,
    "code": "remni-bezopasnosti-i-natyazhitel",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Ремені безпеки та натягувач",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/detali-salona/remni-bezopasnosti-i-natyazhitel",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2412,
    "parent": 2366,
    "code": "rul",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Кермо",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/detali-salona/rul",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2413,
    "parent": 2366,
    "code": "ruchki-dverey-i-salona",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Ручки дверей та салону",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/detali-salona/ruchki-dverey-i-salona",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2414,
    "parent": 2366,
    "code": "ruchki-kpp",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Ручки КПП",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/detali-salona/ruchki-kpp",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2415,
    "parent": 2366,
    "code": "torpedo-konsol",
    "position": 18,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Торпедо, консоль",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/detali-salona/torpedo-konsol",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2416,
    "parent": 2366,
    "code": "drugiye-detali-salona",
    "position": 19,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші деталі салону",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/detali-salona/drugiye-detali-salona",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2417,
    "parent": 2365,
    "code": "bagazhniki-zadniye-paneli-i-ikh-detali",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Багажники, задні панелі та їхні деталі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/kuzovnyye-zapchasti/bagazhniki-zadniye-paneli-i-ikh-detali",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2418,
    "parent": 2365,
    "code": "bampery",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Бампери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/kuzovnyye-zapchasti/bampery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2419,
    "parent": 2365,
    "code": "usiliteli-bampera-bufer-bampera",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Підсилювачі бампера, буфер бампера",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/kuzovnyye-zapchasti/usiliteli-bampera-bufer-bampera",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2420,
    "parent": 2365,
    "code": "dveri",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Двері",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/kuzovnyye-zapchasti/dveri",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2421,
    "parent": 2365,
    "code": "zashchita-kartera-i-kpp",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Захист картера та КПП",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/kuzovnyye-zapchasti/zashchita-kartera-i-kpp",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2422,
    "parent": 2365,
    "code": "kapoty-i-ikh-chasti",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Капоти та їхні частини",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/kuzovnyye-zapchasti/kapoty-i-ikh-chasti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2423,
    "parent": 2365,
    "code": "amortizatory-kapota-bagazhnika",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Амортизатори капота, багажника",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/kuzovnyye-zapchasti/amortizatory-kapota-bagazhnika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2424,
    "parent": 2365,
    "code": "krylia-i-ikh-chasti",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Крила та їхні частини",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/kuzovnyye-zapchasti/krylia-i-ikh-chasti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2425,
    "parent": 2365,
    "code": "kuzova-legkovykh-avtomobiley",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Кузови легкових автомобілів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/kuzovnyye-zapchasti/kuzova-legkovykh-avtomobiley",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2426,
    "parent": 2365,
    "code": "dvernyye-zamki-i-klyuchi",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Дверні замки та ключі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/kuzovnyye-zapchasti/dvernyye-zamki-i-klyuchi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2427,
    "parent": 2365,
    "code": "avtomobilnyye-lyuki",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автомобільні люки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/kuzovnyye-zapchasti/avtomobilnyye-lyuki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2428,
    "parent": 2365,
    "code": "lonzherony-podramniki",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Лонжерони, підрамники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/kuzovnyye-zapchasti/lonzherony-podramniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2429,
    "parent": 2365,
    "code": "lyuchki-kryshki-benzobaka",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Лючки, кришки бензобака",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/kuzovnyye-zapchasti/lyuchki-kryshki-benzobaka",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2430,
    "parent": 2365,
    "code": "nizhnyaya-chast-kuzova-i-rama-avtomobilya",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Нижня частина кузова та рама автомобіля",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/kuzovnyye-zapchasti/nizhnyaya-chast-kuzova-i-rama-avtomobilya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2431,
    "parent": 2365,
    "code": "drugiye-kuzovnyye-zapchasti",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші кузовні запчастини",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/kuzovnyye-zapchasti/drugiye-kuzovnyye-zapchasti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2432,
    "parent": 2367,
    "code": "avtomobilnyye-konditsionery-v-sbore",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автомобільні кондиціонери у зборі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/konditsioner-otopitel-ventilyatsiya/avtomobilnyye-konditsionery-v-sbore",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2433,
    "parent": 2367,
    "code": "avtonomnyye-otopiteli",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автономні обігрівачі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/konditsioner-otopitel-ventilyatsiya/avtonomnyye-otopiteli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2434,
    "parent": 2367,
    "code": "bloki-upravleniya-konditsionerom",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Блок управління кондиціонером",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/konditsioner-otopitel-ventilyatsiya/bloki-upravleniya-konditsionerom",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2435,
    "parent": 2367,
    "code": "ventilyatory-sistemy-konditsionirovaniya-i-otopleniya",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Вентилятори, системи кондиціювання та опалення",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/konditsioner-otopitel-ventilyatsiya/ventilyatory-sistemy-konditsionirovaniya-i-otopleniya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2436,
    "parent": 2367,
    "code": "ispariteli",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Випарники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/konditsioner-otopitel-ventilyatsiya/ispariteli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2437,
    "parent": 2367,
    "code": "kompressory",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Компресори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/konditsioner-otopitel-ventilyatsiya/kompressory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2438,
    "parent": 2367,
    "code": "kondensatory-konditsionera",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Конденсатори кондиціонера",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/konditsioner-otopitel-ventilyatsiya/kondensatory-konditsionera",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2439,
    "parent": 2367,
    "code": "radiatory-otopiteley",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Радіатори обігрівачів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/konditsioner-otopitel-ventilyatsiya/radiatory-otopiteley",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2440,
    "parent": 2367,
    "code": "trubki-shlangi-vozdukhovody",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Трубки, шланги, повітропроводи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/konditsioner-otopitel-ventilyatsiya/trubki-shlangi-vozdukhovody",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2441,
    "parent": 2367,
    "code": "drugiye-zapchasti-konditsionerov-i-otopleniya",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші запчастини кондиціонерів та опалення",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/konditsioner-otopitel-ventilyatsiya/drugiye-zapchasti-konditsionerov-i-otopleniya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2442,
    "parent": 2358,
    "code": "blok-tsilindrov-i-ego-chasti",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Блок циліндрів та його частини",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/dvigatel/blok-tsilindrov-i-ego-chasti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2443,
    "parent": 2358,
    "code": "gazoraspredelitelnyy-mekhanizm-grm",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Газорозподільний механізм (ГРМ)",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/dvigatel/gazoraspredelitelnyy-mekhanizm-grm",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2444,
    "parent": 2358,
    "code": "dvigateli-v-sbore",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Двигуни у зборі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/dvigatel/dvigateli-v-sbore",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2445,
    "parent": 2358,
    "code": "kozhukhi-i-kryshki-dvigatelya",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Кожухи та кришки двигуна",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/dvigatel/kozhukhi-i-kryshki-dvigatelya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2446,
    "parent": 2358,
    "code": "krivoshipno-shatunnyy-mekhanizm",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Кривошипно-шатунний механізм",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/dvigatel/krivoshipno-shatunnyy-mekhanizm",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2447,
    "parent": 2358,
    "code": "makhoviki-i-ikh-sostavlyayushchiye",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Маховики та їхні компоненти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/dvigatel/makhoviki-i-ikh-sostavlyayushchiye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2448,
    "parent": 2358,
    "code": "podushki-dvigatelya",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Подушки двигуна",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/dvigatel/podushki-dvigatelya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2449,
    "parent": 2358,
    "code": "podshipniki-dvigatelya",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Підшипники двигуна",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/dvigatel/podshipniki-dvigatelya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2450,
    "parent": 2358,
    "code": "porshnevaya-gruppa",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Поршнева група",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/dvigatel/porshnevaya-gruppa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2451,
    "parent": 2358,
    "code": "prokladki-i-komplekty-prokladok",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Прокладки та комплекти прокладок",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/dvigatel/prokladki-i-komplekty-prokladok",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2452,
    "parent": 2358,
    "code": "roliki-i-natyazhiteli",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Ролики та натягувачі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/dvigatel/roliki-i-natyazhiteli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2453,
    "parent": 2358,
    "code": "radiatory-avtomobilnyye",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Радіатори автомобільні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/dvigatel/radiatory-avtomobilnyye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2454,
    "parent": 2358,
    "code": "salniki-i-uplotnitelnyye-koltsa",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Сальники та ущільнюючі кільця",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/dvigatel/salniki-i-uplotnitelnyye-koltsa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2455,
    "parent": 2358,
    "code": "sistema-smazki-dvigatelya",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Система змащування двигуна",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/dvigatel/sistema-smazki-dvigatelya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2456,
    "parent": 2358,
    "code": "turbiny-turbokompressory",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Турбіни, турбокомпресори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/dvigatel/turbiny-turbokompressory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2457,
    "parent": 2358,
    "code": "shkivy-i-shponki-dvigatelya",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Шківи та шпонки двигуна",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/dvigatel/shkivy-i-shponki-dvigatelya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2458,
    "parent": 2358,
    "code": "drugiye-zapchasti-dvigatelya",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші запчастини двигуна",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/dvigatel/drugiye-zapchasti-dvigatelya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2459,
    "parent": 2360,
    "code": "valy-kardannyye",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Карданні вали",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/transmissiya-kpp-i-akpp/valy-kardannyye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2460,
    "parent": 2360,
    "code": "valy-privodnyye",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Приводні вали",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/transmissiya-kpp-i-akpp/valy-privodnyye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2461,
    "parent": 2360,
    "code": "variator",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Варіатор",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/transmissiya-kpp-i-akpp/variator",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2462,
    "parent": 2360,
    "code": "vilki-i-shtoki-pereklyucheniya-peredach",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Вилки та штоки перемикання передач",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/transmissiya-kpp-i-akpp/vilki-i-shtoki-pereklyucheniya-peredach",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2463,
    "parent": 2360,
    "code": "gidrotransformator",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Гідротрансформатор",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/transmissiya-kpp-i-akpp/gidrotransformator",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2464,
    "parent": 2360,
    "code": "diski-friktsionnyye-i-viskomufty",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Диски фрикційні та віскомуфти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/transmissiya-kpp-i-akpp/diski-friktsionnyye-i-viskomufty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2465,
    "parent": 2360,
    "code": "differentsialy-reduktory",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Диференціали (редуктори)",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/transmissiya-kpp-i-akpp/differentsialy-reduktory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2466,
    "parent": 2360,
    "code": "komponenty-kpp-akpp",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Компоненти КПП / АКПП",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/transmissiya-kpp-i-akpp/komponenty-kpp-akpp",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2467,
    "parent": 2360,
    "code": "korobki-otbora-moshchnosti",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Коробки відбору потужності",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/transmissiya-kpp-i-akpp/korobki-otbora-moshchnosti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2468,
    "parent": 2360,
    "code": "kpp-akpp-v-sbore",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "КПП / АКПП у зборі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/transmissiya-kpp-i-akpp/kpp-akpp-v-sbore",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2469,
    "parent": 2360,
    "code": "mosty-transmissii",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мости трансмісії",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/transmissiya-kpp-i-akpp/mosty-transmissii",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2470,
    "parent": 2360,
    "code": "mufty-elastichnyye",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Муфти еластичні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/transmissiya-kpp-i-akpp/mufty-elastichnyye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2471,
    "parent": 2360,
    "code": "podshipniki-transmissii",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Підшипники трансмісії",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/transmissiya-kpp-i-akpp/podshipniki-transmissii",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2472,
    "parent": 2360,
    "code": "tsilindry-stsepleniya",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Циліндри зчеплення",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/transmissiya-kpp-i-akpp/tsilindry-stsepleniya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2474,
    "parent": 2360,
    "code": "shrusy",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Шруси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/transmissiya-kpp-i-akpp/shrusy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2475,
    "parent": 2360,
    "code": "drugiye-zapchasti-transmissii",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші запчастини трансмісії",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/transmissiya-kpp-i-akpp/drugiye-zapchasti-transmissii",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2476,
    "parent": 2361,
    "code": "ruli-i-ikh-komponenty",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Керма та їхні компоненти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/rulevoye-upravleniye/ruli-i-ikh-komponenty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2477,
    "parent": 2361,
    "code": "amortizatory-i-stabilizatory-rulevogo-upravleniya",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Амортизатори та стабілізатори рульового керування",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/rulevoye-upravleniye/amortizatory-i-stabilizatory-rulevogo-upravleniya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2478,
    "parent": 2361,
    "code": "rulevyye-valy-i-ikh-komponenty",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Кермові вали та їхні компоненти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/rulevoye-upravleniye/rulevyye-valy-i-ikh-komponenty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2479,
    "parent": 2361,
    "code": "gidrousilitel-rulya",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Гідропідсилювач керма",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/rulevoye-upravleniye/gidrousilitel-rulya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2480,
    "parent": 2361,
    "code": "nakonechniki-rulevyye",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Накінечники кермові",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/rulevoye-upravleniye/nakonechniki-rulevyye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2481,
    "parent": 2361,
    "code": "pedal-gaza",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Педаль газу",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/rulevoye-upravleniye/pedal-gaza",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2482,
    "parent": 2361,
    "code": "pylniki-otboyniki",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Пильовики, відбійники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/rulevoye-upravleniye/pylniki-otboyniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2483,
    "parent": 2361,
    "code": "reyki-rulevogo-upravleniya",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Рейки кермового управління",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/rulevoye-upravleniye/reyki-rulevogo-upravleniya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2484,
    "parent": 2361,
    "code": "rulevoy-mekhanizm",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Кермові механізми",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/rulevoye-upravleniye/rulevoy-mekhanizm",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2485,
    "parent": 2361,
    "code": "rulevyye-tyagi",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Кермові тяги",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/rulevoye-upravleniye/rulevyye-tyagi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2486,
    "parent": 2361,
    "code": "drugiye-zapchasti-rulevogo-upravleniya",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші запчастини системи керування",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/rulevoye-upravleniye/drugiye-zapchasti-rulevogo-upravleniya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2487,
    "parent": 2364,
    "code": "barabany-tormoznyye",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Барабани гальмівні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/tormoznaya-sistema/barabany-tormoznyye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2488,
    "parent": 2364,
    "code": "detali-tormoznykh-sistem-abs-ebd",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Деталі гальмівних систем ABS, EBD",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/tormoznaya-sistema/detali-tormoznykh-sistem-abs-ebd",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2489,
    "parent": 2364,
    "code": "kamery-tormoznyye-i-energoakkumulyatory",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Гальмівні камери та енергоакумулятори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/tormoznaya-sistema/kamery-tormoznyye-i-energoakkumulyatory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2490,
    "parent": 2364,
    "code": "nakladki-tormoznyye",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Накладки гальмівні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/tormoznaya-sistema/nakladki-tormoznyye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2491,
    "parent": 2364,
    "code": "resivery",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Ресивери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/tormoznaya-sistema/resivery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2492,
    "parent": 2364,
    "code": "retardery",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Ретардери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/tormoznaya-sistema/retardery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2493,
    "parent": 2364,
    "code": "tormoznyye-diski",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Гальмівні диски",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/tormoznaya-sistema/tormoznyye-diski",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2494,
    "parent": 2364,
    "code": "tormoznyye-kolodki-diskovyye",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Гальмівні колодки дискові",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/tormoznaya-sistema/tormoznyye-kolodki-diskovyye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2495,
    "parent": 2364,
    "code": "tormoznyye-kolodki-barabannyye",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Гальмівні колодки барабанні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/tormoznaya-sistema/tormoznyye-kolodki-barabannyye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2496,
    "parent": 2364,
    "code": "tormoznyye-supporta",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Гальмівні супорти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/tormoznaya-sistema/tormoznyye-supporta",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2497,
    "parent": 2364,
    "code": "tormoznyye-tsilindry",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Гальмівні циліндри",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/tormoznaya-sistema/tormoznyye-tsilindry",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2498,
    "parent": 2364,
    "code": "tormoznyye-shlangi",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Гальмівні шланги",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/tormoznaya-sistema/tormoznyye-shlangi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2499,
    "parent": 2364,
    "code": "usiliteli-tormozov-i-ikh-komponenty",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Підсилювачі гальм та їхні компоненти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/tormoznaya-sistema/usiliteli-tormozov-i-ikh-komponenty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2500,
    "parent": 2364,
    "code": "drugiye-zapchasti-tormoznoy-sistemy",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші запчастини гальмівної системи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/tormoznaya-sistema/drugiye-zapchasti-tormoznoy-sistemy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2501,
    "parent": 2363,
    "code": "toplivnyye-nasosy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Паливні насоси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/toplivnaya-sistema/toplivnyye-nasosy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2502,
    "parent": 2363,
    "code": "baki-i-bachki-toplivnoy-sistemy",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Баки та бачки паливної системи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/toplivnaya-sistema/baki-i-bachki-toplivnoy-sistemy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2503,
    "parent": 2363,
    "code": "vpusknyye-kollektory",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Впускні колектори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/toplivnaya-sistema/vpusknyye-kollektory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2504,
    "parent": 2363,
    "code": "drosselnyye-zaslonki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Дросельні заслінки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/toplivnaya-sistema/drosselnyye-zaslonki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2505,
    "parent": 2363,
    "code": "karbyuratory-i-komplektuyushchiye",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Карбюратори та комплектуючі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/toplivnaya-sistema/karbyuratory-i-komplektuyushchiye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2506,
    "parent": 2363,
    "code": "raspyliteli-i-toplivnyye-forsunki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Розпилювачі та паливні форсунки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/toplivnaya-sistema/raspyliteli-i-toplivnyye-forsunki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2507,
    "parent": 2363,
    "code": "regulyatory-davleniya-topliva",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Регулятори тиску палива",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/toplivnaya-sistema/regulyatory-davleniya-topliva",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2508,
    "parent": 2363,
    "code": "drugiye-zapchasti-toplivnoy-sistemy",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші запчастини паливної системи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/toplivnaya-sistema/drugiye-zapchasti-toplivnoy-sistemy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2509,
    "parent": 2362,
    "code": "amortizatory",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Амортизатори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/khodovaya-chast-podveska/amortizatory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2510,
    "parent": 2362,
    "code": "balki-podveski",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Балки підвіски",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/khodovaya-chast-podveska/balki-podveski",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2511,
    "parent": 2362,
    "code": "vtulki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Втулки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/khodovaya-chast-podveska/vtulki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2512,
    "parent": 2362,
    "code": "opory-sharovyye",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Кульові опори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/khodovaya-chast-podveska/opory-sharovyye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2513,
    "parent": 2362,
    "code": "pnevmoressory-pnevmopodushki",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Пневморесори, пневмоподушки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/khodovaya-chast-podveska/pnevmoressory-pnevmopodushki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2514,
    "parent": 2362,
    "code": "povorotnyye-kulaki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Поворотні кулаки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/khodovaya-chast-podveska/povorotnyye-kulaki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2515,
    "parent": 2362,
    "code": "prostavki-dlya-uvelicheniya-klirensa",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Проставки для збільшення кліренсу",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/khodovaya-chast-podveska/prostavki-dlya-uvelicheniya-klirensa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2516,
    "parent": 2362,
    "code": "pruzhiny-podveski",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Пружини підвіски",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/khodovaya-chast-podveska/pruzhiny-podveski",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2517,
    "parent": 2362,
    "code": "ressory-i-ikh-sostavlyayushchiye",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Ресори та їхні складові",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/khodovaya-chast-podveska/ressory-i-ikh-sostavlyayushchiye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2518,
    "parent": 2362,
    "code": "rychagi-tyagi",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Важелі, тяги",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/khodovaya-chast-podveska/rychagi-tyagi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2519,
    "parent": 2362,
    "code": "saylentbloki",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Сайлентблоки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/khodovaya-chast-podveska/saylentbloki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2520,
    "parent": 2362,
    "code": "stoyki-stabilizatorov",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Стійки стабілізаторів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/khodovaya-chast-podveska/stoyki-stabilizatorov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2521,
    "parent": 2362,
    "code": "stupitsa-podshipnik-stupitsy",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Маточини, підшипники маточини",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/khodovaya-chast-podveska/stupitsa-podshipnik-stupitsy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2522,
    "parent": 2362,
    "code": "torsiony",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Торсіони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/khodovaya-chast-podveska/torsiony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2523,
    "parent": 2362,
    "code": "drugiye-zapchasti-podveski",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші запчастини підвіски",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/khodovaya-chast-podveska/drugiye-zapchasti-podveski",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2524,
    "parent": 2368,
    "code": "vpusknoy-i-vypusknoy-kollektory",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Впускний та випускний колектори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-podachi-vozdukha/vpusknoy-i-vypusknoy-kollektory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2525,
    "parent": 2368,
    "code": "interkulery",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інтеркулери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-podachi-vozdukha/interkulery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2526,
    "parent": 2368,
    "code": "korpusa-vozdushnogo-filtra",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Корпуси повітряного фільтра",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-podachi-vozdukha/korpusa-vozdushnogo-filtra",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2527,
    "parent": 2368,
    "code": "patrubki-turbiny-i-interkulera",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Патрубки турбіни та інтеркулера",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-podachi-vozdukha/patrubki-turbiny-i-interkulera",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2528,
    "parent": 2368,
    "code": "drugiye-zapchasti-podachi-vozdukha",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші запчастини подачі повітря",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-podachi-vozdukha/drugiye-zapchasti-podachi-vozdukha",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2529,
    "parent": 2369,
    "code": "vysokovoltnyye-provoda-zazhiganiya",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Високовольтні дроти запалювання",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-zazhiganiya/vysokovoltnyye-provoda-zazhiganiya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2530,
    "parent": 2369,
    "code": "zamki-zazhiganiya-i-klyuchi",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Замки запалювання та ключі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-zazhiganiya/zamki-zazhiganiya-i-klyuchi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2531,
    "parent": 2369,
    "code": "katushki-zazhiganiya",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Котушки запалювання",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-zazhiganiya/katushki-zazhiganiya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2532,
    "parent": 2369,
    "code": "svechi-zazhiganiya",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Свічки запалювання",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-zazhiganiya/svechi-zazhiganiya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2533,
    "parent": 2369,
    "code": "svechi-nakala",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Свічки розжарювання",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-zazhiganiya/svechi-nakala",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2534,
    "parent": 2369,
    "code": "starter-zapchasti-startera",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Стартер, запчастини стартера",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-zazhiganiya/starter-zapchasti-startera",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2535,
    "parent": 2369,
    "code": "tramblery",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Трамблери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-zazhiganiya/tramblery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2536,
    "parent": 2369,
    "code": "drugiye-zapchasti-sistemy-zazhiganiya",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші запчастини системи запалення",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-zazhiganiya/drugiye-zapchasti-sistemy-zazhiganiya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2537,
    "parent": 2370,
    "code": "bachki-i-kryshki-omyvateley",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Бачки та кришки омивачів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-ochistki-okon-i-far/bachki-i-kryshki-omyvateley",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2538,
    "parent": 2370,
    "code": "motorchiki-dvornikov",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Моторчики двірників",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-ochistki-okon-i-far/motorchiki-dvornikov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2539,
    "parent": 2370,
    "code": "nasosy-omyvatelya",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Насоси омивача",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-ochistki-okon-i-far/nasosy-omyvatelya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2540,
    "parent": 2370,
    "code": "rezinki-dlya-stekloochistiteley",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Гумки для склоочисників",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-ochistki-okon-i-far/rezinki-dlya-stekloochistiteley",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2541,
    "parent": 2370,
    "code": "forsunki-omyvatelya",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Форсунки омивача",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-ochistki-okon-i-far/forsunki-omyvatelya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2542,
    "parent": 2370,
    "code": "dvorniki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Двірники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-ochistki-okon-i-far/dvorniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2543,
    "parent": 2370,
    "code": "drugiye-zapchasti-ochistki-okon-i-far",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші запчастини очищення вікон та фар",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/sistema-ochistki-okon-i-far/drugiye-zapchasti-ochistki-okon-i-far",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2544,
    "parent": 2371,
    "code": "peredniye-fary",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Передні фари",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/fary-i-osveshcheniye/peredniye-fary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2545,
    "parent": 2371,
    "code": "zadniye-fary",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Задні фари",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/fary-i-osveshcheniye/zadniye-fary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2546,
    "parent": 2371,
    "code": "protivotumannyye-fary",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Протитуманні фари",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/fary-i-osveshcheniye/protivotumannyye-fary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2547,
    "parent": 2371,
    "code": "dnevnyye-khodovyye-ogni",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Денні ходові вогні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/fary-i-osveshcheniye/dnevnyye-khodovyye-ogni",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2548,
    "parent": 2371,
    "code": "ksenon",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Ксенон",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/fary-i-osveshcheniye/ksenon",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2549,
    "parent": 2371,
    "code": "lampochki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Лампочки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/fary-i-osveshcheniye/lampochki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2550,
    "parent": 2371,
    "code": "otrazhateli-korpusa-far",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Відбивачі, корпуси фар",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/fary-i-osveshcheniye/otrazhateli-korpusa-far",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2551,
    "parent": 2371,
    "code": "povtoriteli-povorotov",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Повторювачі поворотів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/fary-i-osveshcheniye/povtoriteli-povorotov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2552,
    "parent": 2371,
    "code": "osveshcheniye-interyera",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Освітлення інтер'єру",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/fary-i-osveshcheniye/osveshcheniye-interyera",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2553,
    "parent": 2371,
    "code": "drugiye-elementy-osveshcheniya",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші елементи освітлення",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/fary-i-osveshcheniye/drugiye-elementy-osveshcheniya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2554,
    "parent": 2372,
    "code": "akkumulyatory",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Акумулятори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/elektrooborudovaniye/akkumulyatory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2555,
    "parent": 2372,
    "code": "bloki-upravleniya",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Блоки керування",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/elektrooborudovaniye/bloki-upravleniya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2556,
    "parent": 2372,
    "code": "generator-zapchasti-generatora",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Генератор, запчастини генератора",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/elektrooborudovaniye/generator-zapchasti-generatora",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2557,
    "parent": 2372,
    "code": "knopki-i-vyklyuchateli",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Кнопки та вимикачі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/elektrooborudovaniye/knopki-i-vyklyuchateli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2558,
    "parent": 2372,
    "code": "paneli-priborov",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Панелі приладів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/elektrooborudovaniye/paneli-priborov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2559,
    "parent": 2372,
    "code": "predokhraniteli-preryvateli",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Запобіжники, переривники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/elektrooborudovaniye/predokhraniteli-preryvateli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2560,
    "parent": 2372,
    "code": "prikurivateli-i-komplektuyushchiye",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Прикурювачі та комплектуючі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/elektrooborudovaniye/prikurivateli-i-komplektuyushchiye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2561,
    "parent": 2372,
    "code": "rele",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Реле",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/elektrooborudovaniye/rele",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2562,
    "parent": 2372,
    "code": "signaly-zvukovyye",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Звукові сигнали",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/elektrooborudovaniye/signaly-zvukovyye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2563,
    "parent": 2372,
    "code": "elektroprovodka-i-komponenty",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Електропроводка та компоненти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/elektrooborudovaniye/elektroprovodka-i-komponenty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2564,
    "parent": 2372,
    "code": "drugoye-elektroborudovaniye",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інше електрообладнання",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/elektrooborudovaniye/drugoye-elektroborudovaniye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2565,
    "parent": 2373,
    "code": "vozdushnyye-filtry",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Повітряні фільтри",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/filtry/vozdushnyye-filtry",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2566,
    "parent": 2373,
    "code": "maslyanyye-filtry",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мастильні фільтри",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/filtry/maslyanyye-filtry",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2567,
    "parent": 2373,
    "code": "toplivnyye-filtry",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Паливні фільтри",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/filtry/toplivnyye-filtry",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2568,
    "parent": 2373,
    "code": "filtry-salona",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Фільтри салону",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/filtry/filtry-salona",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2569,
    "parent": 2373,
    "code": "filtry-gbo",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Фільтри ГБО",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/filtry/filtry-gbo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2570,
    "parent": 2373,
    "code": "filtry-drugikh-tipov",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Фільтри інших типів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/filtry/filtry-drugikh-tipov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2571,
    "parent": 3,
    "code": "masla-i-avtokhimiya",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мастила та автохімія",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/masla-i-avtokhimiya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2572,
      2573,
      2574,
      2575,
      2576,
      2577
    ]
  },
  {
    "category_id": 2572,
    "parent": 2571,
    "code": "motornyye-masla",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Моторні мастила",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/masla-i-avtokhimiya/motornyye-masla",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2573,
    "parent": 2571,
    "code": "transmissionnoye-maslo",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Трансмісійні мастила",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/masla-i-avtokhimiya/transmissionnoye-maslo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2574,
    "parent": 2571,
    "code": "gidravlicheskoye-maslo",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Гідравлічні мастила",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/masla-i-avtokhimiya/gidravlicheskoye-maslo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2575,
    "parent": 2571,
    "code": "masla-dlya-mototekhniki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Мастила для мототехніки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/masla-i-avtokhimiya/masla-dlya-mototekhniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2576,
    "parent": 2571,
    "code": "avtokosmetika-i-avtokhimiya",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Автокосметика та автохімія",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/masla-i-avtokhimiya/avtokosmetika-i-avtokhimiya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2577,
    "parent": 2571,
    "code": "prochiye-masla-i-zhidkosti",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші мастила та рідини",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/masla-i-avtokhimiya/prochiye-masla-i-zhidkosti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2578,
    "parent": 316,
    "code": "dlya-gruzovikov",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Для вантажівок",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/prochie-zapchasti/dlya-gruzovikov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2579,
    "parent": 316,
    "code": "dlya-avtobusov",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Для автобусів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/prochie-zapchasti/dlya-avtobusov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2580,
    "parent": 316,
    "code": "dlya-selkhoztekhniki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Для сільгосптехніки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/prochie-zapchasti/dlya-selkhoztekhniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2581,
    "parent": 316,
    "code": "dlya-spetstekhniki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Для спецтехніки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/prochie-zapchasti/dlya-spetstekhniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2582,
    "parent": 316,
    "code": "dlya-vodnogo-transporta",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Для водного транспорту",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/prochie-zapchasti/dlya-vodnogo-transporta",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2583,
    "parent": 316,
    "code": "dlya-drugikh-vidov-tekhniki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Для інших видів техніки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/prochie-zapchasti/dlya-drugikh-vidov-tekhniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2584,
    "parent": 97,
    "code": "platya",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Весільні сукні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/platya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2585,
    "parent": 97,
    "code": "kostyumy",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Весільні костюми",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/kostyumy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2586,
    "parent": 97,
    "code": "plate-podruzhki-nevesty",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сукні для подружок нареченої",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/plate-podruzhki-nevesty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2587,
    "parent": 97,
    "code": "aksessuary",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Весільні аксесуари для нареченої",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/aksessuary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2590,
      2592,
      2594,
      2596,
      2598,
      2600,
      2602,
      2604,
      2606,
      2608
    ]
  },
  {
    "category_id": 2588,
    "parent": 97,
    "code": "svadebnoe-bele",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Весільна білизна",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/svadebnoe-bele",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2589,
    "parent": 97,
    "code": "dekor",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Весільні аксесуари (декор)",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/dekor",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2591,
      2593,
      2595,
      2597,
      2599,
      2601,
      2603,
      2605,
      2607
    ]
  },
  {
    "category_id": 2590,
    "parent": 2587,
    "code": "podvyazki-dlya-nevesty",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Підв'язки для нареченої",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/aksessuary/podvyazki-dlya-nevesty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2591,
    "parent": 2589,
    "code": "butonerki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бутоньєрки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/dekor/butonerki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2592,
    "parent": 2587,
    "code": "shubki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шубки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/aksessuary/shubki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2593,
    "parent": 2589,
    "code": "podushki-dlya-kolets",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Подушки для обручок",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/dekor/podushki-dlya-kolets",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2594,
    "parent": 2587,
    "code": "nakidki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Накидки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/aksessuary/nakidki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2595,
    "parent": 2589,
    "code": "ukrasheniya-na-mashinu",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Прикраси на машину",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/dekor/ukrasheniya-na-mashinu",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2596,
    "parent": 2587,
    "code": "bolero",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Болеро",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/aksessuary/bolero",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2597,
    "parent": 2589,
    "code": "svadebnaya-arka",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Весільна арка",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/dekor/svadebnaya-arka",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2598,
    "parent": 2587,
    "code": "podyubniki",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Під'юбники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/aksessuary/podyubniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2599,
    "parent": 2589,
    "code": "svadebnye-bokaly",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Весільні келихи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/dekor/svadebnye-bokaly",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2600,
    "parent": 2587,
    "code": "veera-sumochki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Віяла і сумочки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/aksessuary/veera-sumochki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2601,
    "parent": 2589,
    "code": "shkatulki-dlya-deneg",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Скриньки для грошей",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/dekor/shkatulki-dlya-deneg",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2602,
    "parent": 2587,
    "code": "zonty",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Парасолі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/aksessuary/zonty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2603,
    "parent": 2589,
    "code": "svadebnye-bukety",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Весільні букети",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/dekor/svadebnye-bukety",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2604,
    "parent": 2587,
    "code": "fata",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Фата",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/aksessuary/fata",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2605,
    "parent": 2589,
    "code": "priglasheniya-na-svadbu",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Запрошення на весілля",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/dekor/priglasheniya-na-svadbu",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2606,
    "parent": 2587,
    "code": "perchatki-poyasa",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Весільні рукавички і пояси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/aksessuary/perchatki-poyasa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2607,
    "parent": 2589,
    "code": "svadebniy-rushnik",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Весільний рушник",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/dekor/svadebniy-rushnik",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2608,
    "parent": 2587,
    "code": "yuvelirnye-izdeliya-ukrasheniya",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Весільні ювелірні вироби та прикраси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/aksessuary/yuvelirnye-izdeliya-ukrasheniya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2609,
    "parent": 97,
    "code": "obuv",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Весільне взуття",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/obuv",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2611,
      2612,
      2613,
      2614,
      2615
    ]
  },
  {
    "category_id": 2610,
    "parent": 97,
    "code": "drugoe",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2611,
    "parent": 2609,
    "code": "tufli",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Туфлі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/obuv/tufli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2612,
    "parent": 2609,
    "code": "bosonozhki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Босоніжки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/obuv/bosonozhki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2613,
    "parent": 2609,
    "code": "baletki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Балетки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/obuv/baletki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2614,
    "parent": 2609,
    "code": "botilony",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ботильйони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/obuv/botilony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2615,
    "parent": 2609,
    "code": "botinki-krossovki",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Черевики і кросівки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/dlya-svadby/obuv/botinki-krossovki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2616,
    "parent": 2365,
    "code": "reshetka-radiatora",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Решітка радіатора",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/kuzovnyye-zapchasti/reshetka-radiatora",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2617,
    "parent": 2365,
    "code": "perednyaya-panel-televizor",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Передня панель, телевізор",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "zapchasti-dlya-transporta/avtozapchasti/kuzovnyye-zapchasti/perednyaya-panel-televizor",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2618,
    "parent": 2015,
    "code": "botinki-polubotinki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Черевики та напівчеревики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/botinki-polubotinki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2629,
      2635,
      2641,
      2647,
      2653,
      2658,
      2661,
      2663,
      2672
    ]
  },
  {
    "category_id": 2619,
    "parent": 2015,
    "code": "tufli",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Туфлі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/tufli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2630,
      2636,
      2642,
      2648,
      2654,
      2671
    ]
  },
  {
    "category_id": 2620,
    "parent": 2015,
    "code": "sapogi-polusapozhki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Чоботи, напівчоботи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/sapogi-polusapozhki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2631,
      2637,
      2643,
      2649,
      2655,
      2670
    ]
  },
  {
    "category_id": 2621,
    "parent": 2015,
    "code": "uggi",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Уггі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/uggi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2622,
    "parent": 2015,
    "code": "mokasiny-slipony",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Мокасини та сліпони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/mokasiny-slipony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2632,
      2638,
      2644,
      2650,
      2669
    ]
  },
  {
    "category_id": 2623,
    "parent": 2015,
    "code": "krossovki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кросівки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/krossovki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2633,
      2645,
      2651,
      2656,
      2659,
      2666,
      2667
    ]
  },
  {
    "category_id": 2624,
    "parent": 2015,
    "code": "kedy",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кеди",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/kedy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2625,
    "parent": 2015,
    "code": "domashnie-tapochki",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Домашні капці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/domashnie-tapochki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2626,
    "parent": 2015,
    "code": "ortopedicheskaya-obuv",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ортопедичне взуття",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/ortopedicheskaya-obuv",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2627,
    "parent": 2015,
    "code": "sportivnaya-obuv",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивне взуття",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/sportivnaya-obuv",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2628,
    "parent": 2015,
    "code": "bosonozhki-sandali-vetnamki",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Босоніжки, сандалі, в'єтнамки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/bosonozhki-sandali-vetnamki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2634,
      2640,
      2646,
      2652,
      2657,
      2660,
      2662,
      2664,
      2668
    ]
  },
  {
    "category_id": 2629,
    "parent": 2618,
    "code": "botilony",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ботильйони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/botinki-polubotinki/botilony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2630,
    "parent": 2619,
    "code": "baletki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Балетки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/tufli/baletki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2631,
    "parent": 2620,
    "code": "dutiki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Дутики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/sapogi-polusapozhki/dutiki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2632,
    "parent": 2622,
    "code": "mokasiny",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Мокасини",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/mokasiny-slipony/mokasiny",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2633,
    "parent": 2623,
    "code": "snikersy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Снікерси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/krossovki/snikersy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2634,
    "parent": 2628,
    "code": "akvashuzy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Аквашузи (коралоходи)",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/bosonozhki-sandali-vetnamki/akvashuzy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2635,
    "parent": 2618,
    "code": "chelsi",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Челсі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/botinki-polubotinki/chelsi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2636,
    "parent": 2619,
    "code": "derbi",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Дербі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/tufli/derbi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2637,
    "parent": 2620,
    "code": "botforty",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ботфорти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/sapogi-polusapozhki/botforty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2638,
    "parent": 2622,
    "code": "topsaydery",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Топсайдери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/mokasiny-slipony/topsaydery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2640,
    "parent": 2628,
    "code": "bosonozhki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Босоніжки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/bosonozhki-sandali-vetnamki/bosonozhki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2641,
    "parent": 2618,
    "code": "haytopy",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Хайтопи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/botinki-polubotinki/haytopy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2642,
    "parent": 2619,
    "code": "lofery",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Лофери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/tufli/lofery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2643,
    "parent": 2620,
    "code": "lunohody",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Місяцеходи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/sapogi-polusapozhki/lunohody",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2644,
    "parent": 2622,
    "code": "slipony",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сліпони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/mokasiny-slipony/slipony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2645,
    "parent": 2623,
    "code": "noski",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кросівки-шкарпетки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/krossovki/noski",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2646,
    "parent": 2628,
    "code": "sandali",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сандалі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/bosonozhki-sandali-vetnamki/sandali",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2647,
    "parent": 2618,
    "code": "bertsy",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Берці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/botinki-polubotinki/bertsy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2648,
    "parent": 2619,
    "code": "lodochki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Туфлі-човники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/tufli/lodochki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2649,
    "parent": 2620,
    "code": "rezinovye-sapogi",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Гумові чоботи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/sapogi-polusapozhki/rezinovye-sapogi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2650,
    "parent": 2622,
    "code": "espadrili",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Еспадрилі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/mokasiny-slipony/espadrili",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2651,
    "parent": 2623,
    "code": "beg",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кросівки для бігу",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/krossovki/beg",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2652,
    "parent": 2628,
    "code": "shlepantsy",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шльопанці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/bosonozhki-sandali-vetnamki/shlepantsy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2653,
    "parent": 2618,
    "code": "brogi",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Броги",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/botinki-polubotinki/brogi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2654,
    "parent": 2619,
    "code": "oksfordy",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Оксфорди",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/tufli/oksfordy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2655,
    "parent": 2620,
    "code": "unty",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Унти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/sapogi-polusapozhki/unty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2656,
    "parent": 2623,
    "code": "fitnes-trenirovki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кросівки для фітнесу та тренувань",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/krossovki/fitnes-trenirovki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2657,
    "parent": 2628,
    "code": "slantsy",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сланці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/bosonozhki-sandali-vetnamki/slantsy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2658,
    "parent": 2618,
    "code": "haykery",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Хайкери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/botinki-polubotinki/haykery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2659,
    "parent": 2623,
    "code": "trekingovye",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Трекінгові кросівки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/krossovki/trekingovye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2660,
    "parent": 2628,
    "code": "vetnamki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "В'єтнамки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/bosonozhki-sandali-vetnamki/vetnamki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2661,
    "parent": 2618,
    "code": "dezerty",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Дезерти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/botinki-polubotinki/dezerty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2662,
    "parent": 2628,
    "code": "sabo",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сабо",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/bosonozhki-sandali-vetnamki/sabo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2663,
    "parent": 2618,
    "code": "kazaki",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Козаки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/botinki-polubotinki/kazaki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2664,
    "parent": 2628,
    "code": "myuli",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Мюлі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/bosonozhki-sandali-vetnamki/myuli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2665,
    "parent": 2015,
    "code": "drugoe",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше жіноче взуття",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2666,
    "parent": 2623,
    "code": "haytopy",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кросівки-хайтопи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/krossovki/haytopy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2667,
    "parent": 2623,
    "code": "drugoe",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші кросівки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/krossovki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2668,
    "parent": 2628,
    "code": "drugoe",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/bosonozhki-sandali-vetnamki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2669,
    "parent": 2622,
    "code": "drugoe",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/mokasiny-slipony/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2670,
    "parent": 2620,
    "code": "drugoe",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/sapogi-polusapozhki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2671,
    "parent": 2619,
    "code": "drugoe",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші туфлі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/tufli/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2672,
    "parent": 2618,
    "code": "drugoe",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші черевики та напівчеревики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-obuv/botinki-polubotinki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2680,
    "parent": 110,
    "code": "samolety",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Літаки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/drugoy-transport/samolety",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2681,
    "parent": 110,
    "code": "vertolety",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Гелікоптери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/drugoy-transport/vertolety",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2682,
    "parent": 110,
    "code": "deltaplany",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Дельтаплани",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/drugoy-transport/deltaplany",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2683,
    "parent": 110,
    "code": "karety",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Карети",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/drugoy-transport/karety",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2684,
    "parent": 110,
    "code": "zhd-teplovozy",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Залізничні тепловози",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/drugoy-transport/zhd-teplovozy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2685,
    "parent": 110,
    "code": "zhd-vagony",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Залізничні вагони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/drugoy-transport/zhd-vagony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2686,
    "parent": 110,
    "code": "drugiye-vidy-transporta",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "automotive",
    "name": "Інші види транспорту",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 24,
    "path": "transport/drugoy-transport/drugiye-vidy-transporta",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2687,
    "parent": 2014,
    "code": "krossovki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кросівки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/krossovki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2696,
      2702,
      2708,
      2714,
      2719
    ]
  },
  {
    "category_id": 2688,
    "parent": 2014,
    "code": "kedy",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кеди",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/kedy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2689,
    "parent": 2014,
    "code": "botinki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Черевики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/botinki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2697,
      2703,
      2709,
      2721,
      2722
    ]
  },
  {
    "category_id": 2690,
    "parent": 2014,
    "code": "sandali-shlepantsy",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сандалі і шльопанці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/sandali-shlepantsy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2698,
      2704,
      2710,
      2715,
      2723
    ]
  },
  {
    "category_id": 2691,
    "parent": 2014,
    "code": "sapogi",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Чоботи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/sapogi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2699,
      2705,
      2711,
      2716,
      2724
    ]
  },
  {
    "category_id": 2692,
    "parent": 2014,
    "code": "domashnie-tapochki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Домашні капці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/domashnie-tapochki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2693,
    "parent": 2014,
    "code": "mokasiny-slipony",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Мокасини та сліпони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/mokasiny-slipony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2700,
      2706,
      2712,
      2717,
      2725
    ]
  },
  {
    "category_id": 2694,
    "parent": 2014,
    "code": "ortopedicheskaya-obuv",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ортопедичне взуття",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/ortopedicheskaya-obuv",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2695,
    "parent": 2014,
    "code": "tufli",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Туфлі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/tufli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2701,
      2707,
      2713,
      2718,
      2720,
      2726
    ]
  },
  {
    "category_id": 2696,
    "parent": 2687,
    "code": "beg",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кросівки для бігу",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/krossovki/beg",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2697,
    "parent": 2689,
    "code": "chelsi",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Челсі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/botinki/chelsi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2698,
    "parent": 2690,
    "code": "sandali",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сандалі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/sandali-shlepantsy/sandali",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2699,
    "parent": 2691,
    "code": "lunohody",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Мунбути",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/sapogi/lunohody",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2700,
    "parent": 2693,
    "code": "topsaydery",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Топсайдери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/mokasiny-slipony/topsaydery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2701,
    "parent": 2695,
    "code": "monki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Монки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/tufli/monki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2702,
    "parent": 2687,
    "code": "gorod",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кросівки для міста",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/krossovki/gorod",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2703,
    "parent": 2689,
    "code": "bertsy",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Берці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/botinki/bertsy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2704,
    "parent": 2690,
    "code": "shlepantsy",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шльопанці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/sandali-shlepantsy/shlepantsy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2705,
    "parent": 2691,
    "code": "uggi-unty",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Уггі та унти чоловічі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/sapogi/uggi-unty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2706,
    "parent": 2693,
    "code": "mokasiny",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Мокасини",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/mokasiny-slipony/mokasiny",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2707,
    "parent": 2695,
    "code": "oksfordy",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Оксфорди",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/tufli/oksfordy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2708,
    "parent": 2687,
    "code": "zal-trenirovki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кросівки для залу і тренувань",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/krossovki/zal-trenirovki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2709,
    "parent": 2689,
    "code": "polubotinki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Напівчеревики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/botinki/polubotinki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2710,
    "parent": 2690,
    "code": "vetnamki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "В'єтнамки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/sandali-shlepantsy/vetnamki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2711,
    "parent": 2691,
    "code": "dutiki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Дутики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/sapogi/dutiki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2712,
    "parent": 2693,
    "code": "slipony",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сліпони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/mokasiny-slipony/slipony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2713,
    "parent": 2695,
    "code": "derbi",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Дербі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/tufli/derbi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2714,
    "parent": 2687,
    "code": "trekingovye",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Трекінгові кросівки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/krossovki/trekingovye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2715,
    "parent": 2690,
    "code": "akvashuzy",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Аквашузи (коралоходи)",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/sandali-shlepantsy/akvashuzy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2716,
    "parent": 2691,
    "code": "rezinovye-sapogi",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Гумові чоботи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/sapogi/rezinovye-sapogi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2717,
    "parent": 2693,
    "code": "espadrili",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Еспадрилі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/mokasiny-slipony/espadrili",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2718,
    "parent": 2695,
    "code": "lofery",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Лофери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/tufli/lofery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2719,
    "parent": 2687,
    "code": "drugoe",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші кросівки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/krossovki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2720,
    "parent": 2695,
    "code": "brogi",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Броги",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/tufli/brogi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2721,
    "parent": 2689,
    "code": "trekingovye",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Трекінгові черевики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/botinki/trekingovye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2722,
    "parent": 2689,
    "code": "drugoe",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші черевики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/botinki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2723,
    "parent": 2690,
    "code": "drugoe",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/sandali-shlepantsy/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2724,
    "parent": 2691,
    "code": "drugoe",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші чоботи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/sapogi/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2725,
    "parent": 2693,
    "code": "drugoe",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/mokasiny-slipony/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2726,
    "parent": 2695,
    "code": "drugoe",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші туфлі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/tufli/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2727,
    "parent": 2014,
    "code": "drugoe",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше чоловіче взуття",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-obuv/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2728,
    "parent": 568,
    "code": "duhi",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Духи (парфуми)",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/parfyumeriya/duhi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2729,
    "parent": 568,
    "code": "parfyumirovannaya-voda",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Парфумована вода",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/parfyumeriya/parfyumirovannaya-voda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2730,
    "parent": 568,
    "code": "maslo-maslyanye-duhi",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Парфумовані олії і масляні духи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/parfyumeriya/maslo-maslyanye-duhi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2731,
    "parent": 568,
    "code": "odekolon",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Одеколон",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/parfyumeriya/odekolon",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2732,
    "parent": 568,
    "code": "probniki-miniatyury-testery",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пробники, мініатюри, тестери",
    "view_type": "list",
    "is_addable": false,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/parfyumeriya/probniki-miniatyury-testery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2733,
    "parent": 568,
    "code": "nabory",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Набори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/parfyumeriya/nabory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2734,
    "parent": 568,
    "code": "drugoe",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/parfyumeriya/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2735,
    "parent": 568,
    "code": "tualetnaya-voda",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Туалетна вода",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/parfyumeriya/tualetnaya-voda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2736,
    "parent": 2107,
    "code": "futbolki-mayki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Футболки та майки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/futbolki-mayki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2747,
      2757,
      2767,
      2776,
      2783
    ]
  },
  {
    "category_id": 2737,
    "parent": 2107,
    "code": "rubashki-tenniski",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сорочки і теніски",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/rubashki-tenniski",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2748,
      2758,
      2768,
      2810
    ]
  },
  {
    "category_id": 2738,
    "parent": 2107,
    "code": "kofty-svitery",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кофти й светри",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/kofty-svitery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2749,
      2759,
      2769,
      2777,
      2784,
      2789,
      2793,
      2796,
      2799,
      2802,
      2818
    ]
  },
  {
    "category_id": 2739,
    "parent": 2107,
    "code": "verhnyaya-odezhda",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Верхній одяг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/verhnyaya-odezhda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2750,
      2760,
      2770,
      2778,
      2785,
      2790,
      2794,
      2797,
      2800,
      2803,
      2805,
      2807,
      2821,
      2829
    ]
  },
  {
    "category_id": 2740,
    "parent": 2107,
    "code": "shorty",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шорти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/shorty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2751,
      2761,
      2771,
      2779,
      2786,
      2791,
      2822
    ]
  },
  {
    "category_id": 2741,
    "parent": 2107,
    "code": "dzhinsy",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джинси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/dzhinsy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2752,
      2762,
      2772,
      2780,
      2812,
      2823
    ]
  },
  {
    "category_id": 2742,
    "parent": 2107,
    "code": "bryuki",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Штани",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/bryuki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2753,
      2763,
      2809,
      2811,
      2813,
      2817,
      2824
    ]
  },
  {
    "category_id": 2743,
    "parent": 2107,
    "code": "kostyumy-pidzhaki",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Костюми та піджаки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/kostyumy-pidzhaki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2754,
      2764,
      2773,
      2781,
      2787,
      2825
    ]
  },
  {
    "category_id": 2744,
    "parent": 2107,
    "code": "kombinezony",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Комбінезони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/kombinezony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2745,
    "parent": 2107,
    "code": "odezhda-dlya-doma-sna",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Одяг для дому та сну",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/odezhda-dlya-doma-sna",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2755,
      2765,
      2774,
      2826
    ]
  },
  {
    "category_id": 2746,
    "parent": 2107,
    "code": "sportivnaya-odezhda",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивний одяг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/sportivnaya-odezhda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2756,
      2766,
      2775,
      2782,
      2788,
      2792,
      2795,
      2798,
      2801,
      2804,
      2806,
      2808,
      2814,
      2815,
      2816,
      2819,
      2820,
      2827
    ]
  },
  {
    "category_id": 2747,
    "parent": 2736,
    "code": "futbolki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Футболки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/futbolki-mayki/futbolki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2748,
    "parent": 2737,
    "code": "rubashki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сорочки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/rubashki-tenniski/rubashki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2749,
    "parent": 2738,
    "code": "hudi",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Худі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/kofty-svitery/hudi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2750,
    "parent": 2739,
    "code": "dozhdeviki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Дощовики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/verhnyaya-odezhda/dozhdeviki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2751,
    "parent": 2740,
    "code": "dzhinsovye",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джинсові шорти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/shorty/dzhinsovye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2752,
    "parent": 2741,
    "code": "zauzhennye-skinni",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Завужені джинси (Skinny)",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/dzhinsy/zauzhennye-skinni",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2753,
    "parent": 2742,
    "code": "zauzhennye",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Завужені штани",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/bryuki/zauzhennye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2754,
    "parent": 2743,
    "code": "pidzhaki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Чоловічі піджаки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/kostyumy-pidzhaki/pidzhaki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2755,
    "parent": 2745,
    "code": "odezhda",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Домашній одяг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/odezhda-dlya-doma-sna/odezhda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2756,
    "parent": 2746,
    "code": "kostyumy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні костюми",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/sportivnaya-odezhda/kostyumy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2757,
    "parent": 2736,
    "code": "longsliv",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Лонгслів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/futbolki-mayki/longsliv",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2758,
    "parent": 2737,
    "code": "vyshivanki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Вишиванки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/rubashki-tenniski/vyshivanki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2759,
    "parent": 2738,
    "code": "svitshot",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Світшот",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/kofty-svitery/svitshot",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2760,
    "parent": 2739,
    "code": "palto",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пальто",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/verhnyaya-odezhda/palto",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2761,
    "parent": 2740,
    "code": "bridzhi",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бриджі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/shorty/bridzhi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2762,
    "parent": 2741,
    "code": "pryamye",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Прямі джинси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/dzhinsy/pryamye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2763,
    "parent": 2742,
    "code": "chinosy",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Чіноси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/bryuki/chinosy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2764,
    "parent": 2743,
    "code": "smokingi",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Смокінги",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/kostyumy-pidzhaki/smokingi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2765,
    "parent": 2745,
    "code": "pizhamy",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Піжами",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/odezhda-dlya-doma-sna/pizhamy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2766,
    "parent": 2746,
    "code": "noski-getry",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні шкарпетки, гетри",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/sportivnaya-odezhda/noski-getry",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2767,
    "parent": 2736,
    "code": "mayki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Майки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/futbolki-mayki/mayki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2768,
    "parent": 2737,
    "code": "tenniski",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Теніски",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/rubashki-tenniski/tenniski",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2769,
    "parent": 2738,
    "code": "tolstovka",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Толстовки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/kofty-svitery/tolstovka",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2770,
    "parent": 2739,
    "code": "vetrovki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Вітровки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/verhnyaya-odezhda/vetrovki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2771,
    "parent": 2740,
    "code": "kargo",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Карго",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/shorty/kargo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2772,
    "parent": 2741,
    "code": "shirokie",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Широкі джинси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/dzhinsy/shirokie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2773,
    "parent": 2743,
    "code": "zhiletki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Жилетки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/kostyumy-pidzhaki/zhiletki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2774,
    "parent": 2745,
    "code": "halaty",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Халати",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/odezhda-dlya-doma-sna/halaty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2775,
    "parent": 2746,
    "code": "kurtki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні куртки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/sportivnaya-odezhda/kurtki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2776,
    "parent": 2736,
    "code": "polo",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Поло",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/futbolki-mayki/polo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2777,
    "parent": 2738,
    "code": "mantiya",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Мантія",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/kofty-svitery/mantiya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2778,
    "parent": 2739,
    "code": "kozhanye-kurtki",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шкіряні куртки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/verhnyaya-odezhda/kozhanye-kurtki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2779,
    "parent": 2740,
    "code": "klassicheskie",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Класичні шорти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/shorty/klassicheskie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2780,
    "parent": 2741,
    "code": "dzhoggery",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джинсові джогери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/dzhinsy/dzhoggery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2781,
    "parent": 2743,
    "code": "bleyzery",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Блейзери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/kostyumy-pidzhaki/bleyzery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2782,
    "parent": 2746,
    "code": "taytsy-legginsy",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Тайтси і легінси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/sportivnaya-odezhda/taytsy-legginsy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2783,
    "parent": 2736,
    "code": "drugoe",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/futbolki-mayki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2784,
    "parent": 2738,
    "code": "olimpiyka",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Олімпійка",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/kofty-svitery/olimpiyka",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2785,
    "parent": 2739,
    "code": "bombery",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бомбери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/verhnyaya-odezhda/bombery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2786,
    "parent": 2740,
    "code": "bermudy",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шорти бермуди",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/shorty/bermudy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2787,
    "parent": 2743,
    "code": "klassicheskie-kostyumy",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Класичні костюми",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/kostyumy-pidzhaki/klassicheskie-kostyumy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2788,
    "parent": 2746,
    "code": "lyzhnye-kurtki",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Лижні куртки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/sportivnaya-odezhda/lyzhnye-kurtki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2789,
    "parent": 2738,
    "code": "golf-vodolazka",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Гольф / водолазка",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/kofty-svitery/golf-vodolazka",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2790,
    "parent": 2739,
    "code": "dzhinsovye-kurtki",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джинсові куртки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/verhnyaya-odezhda/dzhinsovye-kurtki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2791,
    "parent": 2740,
    "code": "plyazhnye",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пляжні шорти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/shorty/plyazhnye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2792,
    "parent": 2746,
    "code": "lyzhnye-kostyumy",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Лижні костюми",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/sportivnaya-odezhda/lyzhnye-kostyumy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2793,
    "parent": 2738,
    "code": "sviter",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Светр",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/kofty-svitery/sviter",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2794,
    "parent": 2739,
    "code": "parki",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Парки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/verhnyaya-odezhda/parki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2795,
    "parent": 2746,
    "code": "lyzhnye-shtany",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Лижні штани",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/sportivnaya-odezhda/lyzhnye-shtany",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2796,
    "parent": 2738,
    "code": "kardigan",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кардиган",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/kofty-svitery/kardigan",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2797,
    "parent": 2739,
    "code": "puhoviki-zimnie-kurtki",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пуховики та зимові куртки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/verhnyaya-odezhda/puhoviki-zimnie-kurtki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2798,
    "parent": 2746,
    "code": "lyzhnye-kombinezony",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Лижні комбінезони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/sportivnaya-odezhda/lyzhnye-kombinezony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2799,
    "parent": 2738,
    "code": "sviter-reglan",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Светр реглан",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/kofty-svitery/sviter-reglan",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2800,
    "parent": 2739,
    "code": "shuby-dublenki",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шуби та дублянки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/verhnyaya-odezhda/shuby-dublenki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2801,
    "parent": 2746,
    "code": "kurtki-dlya-snoubordov",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Куртки для сноубордів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/sportivnaya-odezhda/kurtki-dlya-snoubordov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2802,
    "parent": 2738,
    "code": "kofty",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кофти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/kofty-svitery/kofty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2803,
    "parent": 2739,
    "code": "zhilety",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Жилети",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/verhnyaya-odezhda/zhilety",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2804,
    "parent": 2746,
    "code": "shtany-dlya-snoubordov",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Штани для сноубордів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/sportivnaya-odezhda/shtany-dlya-snoubordov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2805,
    "parent": 2739,
    "code": "anorak",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Анорак",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/verhnyaya-odezhda/anorak",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2806,
    "parent": 2746,
    "code": "dlya-trenazhernogo-zala",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Одяг для тренажерного залу",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/sportivnaya-odezhda/dlya-trenazhernogo-zala",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2807,
    "parent": 2739,
    "code": "kosuha",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Косуха",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/verhnyaya-odezhda/kosuha",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2808,
    "parent": 2746,
    "code": "dlya-bega",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Одяг для бігу",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/sportivnaya-odezhda/dlya-bega",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2809,
    "parent": 2742,
    "code": "dzhoggery",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джогери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/bryuki/dzhoggery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2810,
    "parent": 2737,
    "code": "drugoe",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/rubashki-tenniski/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2811,
    "parent": 2742,
    "code": "shirokie",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Широкі штани",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/bryuki/shirokie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2812,
    "parent": 2741,
    "code": "klassicheskie",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Класичні джинси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/dzhinsy/klassicheskie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2813,
    "parent": 2742,
    "code": "kargo",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Карго",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/bryuki/kargo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2814,
    "parent": 2746,
    "code": "futbolki-mayki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні футболки та майки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/sportivnaya-odezhda/futbolki-mayki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2815,
    "parent": 2746,
    "code": "shorty",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні шорти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/sportivnaya-odezhda/shorty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2816,
    "parent": 2746,
    "code": "kofty-svitery",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні кофти та светри",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/sportivnaya-odezhda/kofty-svitery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2817,
    "parent": 2742,
    "code": "klassicheskie",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Класичні штани",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/bryuki/klassicheskie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2818,
    "parent": 2738,
    "code": "drugoe",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/kofty-svitery/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2819,
    "parent": 2746,
    "code": "shtany",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні штани",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/sportivnaya-odezhda/shtany",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2820,
    "parent": 2746,
    "code": "dzhoggery",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джогери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/sportivnaya-odezhda/dzhoggery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2821,
    "parent": 2739,
    "code": "drugoe",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інший верхній одяг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/verhnyaya-odezhda/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2822,
    "parent": 2740,
    "code": "drugoe",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші шорти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/shorty/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2823,
    "parent": 2741,
    "code": "drugoe",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші джинси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/dzhinsy/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2824,
    "parent": 2742,
    "code": "drugoe",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші штани",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/bryuki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2825,
    "parent": 2743,
    "code": "drugoe",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/kostyumy-pidzhaki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2826,
    "parent": 2745,
    "code": "drugoe",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/odezhda-dlya-doma-sna/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2827,
    "parent": 2746,
    "code": "drugoe",
    "position": 18,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інший спортивний одяг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/sportivnaya-odezhda/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2828,
    "parent": 2107,
    "code": "drugoe",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інший чоловічий одяг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2829,
    "parent": 2739,
    "code": "plashchi",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Плащі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/muzhskaya-odezhda/verhnyaya-odezhda/plashchi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2830,
    "parent": 571,
    "code": "kolyaski",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Коляски",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/tovary-dlya-invalidov/kolyaski",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2831,
    "parent": 571,
    "code": "trosti",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Тростини",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/tovary-dlya-invalidov/trosti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2832,
    "parent": 571,
    "code": "kostyli",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Милиці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/tovary-dlya-invalidov/kostyli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2833,
    "parent": 571,
    "code": "hodunki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ходунки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/tovary-dlya-invalidov/hodunki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2834,
    "parent": 571,
    "code": "tualetnye-stulya",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Туалетні стільці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/tovary-dlya-invalidov/tualetnye-stulya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2835,
    "parent": 571,
    "code": "matrasy-ot-prolezhney",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Матраци від пролежнів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/tovary-dlya-invalidov/matrasy-ot-prolezhney",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2836,
    "parent": 571,
    "code": "drugoe",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/tovary-dlya-invalidov/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2837,
    "parent": 2016,
    "code": "bodi",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Боді",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/bodi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2838,
    "parent": 2016,
    "code": "mayki-futbolki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Майки та футболки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/mayki-futbolki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2853,
      2867,
      2879,
      2889,
      2899,
      2990
    ]
  },
  {
    "category_id": 2839,
    "parent": 2016,
    "code": "bluzy-rubashki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Блузи і сорочки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/bluzy-rubashki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2854,
      2868,
      2991
    ]
  },
  {
    "category_id": 2840,
    "parent": 2016,
    "code": "svitery-kardigany-hudi",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Светри, кардигани, худі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/svitery-kardigany-hudi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2855,
      2869,
      2880,
      2890,
      2900,
      2908,
      2915,
      2922,
      2929,
      2935,
      2993
    ]
  },
  {
    "category_id": 2841,
    "parent": 2016,
    "code": "platya",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Плаття",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/platya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2856,
      2870,
      2881,
      2891,
      2901,
      2909,
      2916,
      2923,
      2930,
      2992
    ]
  },
  {
    "category_id": 2842,
    "parent": 2016,
    "code": "yubki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спідниці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/yubki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2857,
      2871,
      2882,
      2892,
      2902,
      2910,
      2917,
      2924,
      2931,
      2936,
      2940,
      2943,
      2945,
      2994
    ]
  },
  {
    "category_id": 2843,
    "parent": 2016,
    "code": "verhnyaya-odezhda",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Верхній одяг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/verhnyaya-odezhda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2858,
      2872,
      2883,
      2893,
      2903,
      2911,
      2918,
      2925,
      2932,
      2937,
      2941,
      2944,
      2946,
      2947,
      2948,
      2949,
      2950,
      2995
    ]
  },
  {
    "category_id": 2844,
    "parent": 2016,
    "code": "dzhinsy",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джинси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/dzhinsy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2859,
      2873,
      2884,
      2894,
      2904,
      2912,
      2919,
      2926,
      2933,
      2938,
      2942,
      2996
    ]
  },
  {
    "category_id": 2845,
    "parent": 2016,
    "code": "bryuki",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Брюки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/bryuki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2860,
      2874,
      2885,
      2895,
      2905,
      2913,
      2920,
      2927,
      2964,
      2965,
      2966,
      2967,
      2968
    ]
  },
  {
    "category_id": 2846,
    "parent": 2016,
    "code": "kombinezony",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Комбінезони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/kombinezony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2861,
      2875,
      2886,
      2896,
      2962,
      2963,
      2997
    ]
  },
  {
    "category_id": 2847,
    "parent": 2016,
    "code": "kostyumy",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Костюми",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/kostyumy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2862,
      2960,
      2961,
      2998
    ]
  },
  {
    "category_id": 2848,
    "parent": 2016,
    "code": "pidzhaki-zhakety",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Жіночі піджаки та жакети",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/pidzhaki-zhakety",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2863,
      2969,
      2970,
      2971
    ]
  },
  {
    "category_id": 2849,
    "parent": 2016,
    "code": "domashnyaya-dlya-sna",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Домашній одяг та одяг для сну",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/domashnyaya-dlya-sna",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2864,
      2876,
      2887,
      2897,
      2906,
      2972,
      2973,
      2974,
      2999
    ]
  },
  {
    "category_id": 2850,
    "parent": 2016,
    "code": "sportivnaya-odezhda",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивний одяг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2865,
      2877,
      2888,
      2898,
      2907,
      2914,
      2921,
      2928,
      2934,
      2939,
      2975,
      2976,
      2977,
      2978,
      2979,
      2980,
      2981,
      2982,
      2983,
      2984,
      2985,
      2986
    ]
  },
  {
    "category_id": 2851,
    "parent": 2016,
    "code": "vyshivanki",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Вишиванки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/vyshivanki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2866,
      2878,
      2987,
      2988,
      2989
    ]
  },
  {
    "category_id": 2852,
    "parent": 2016,
    "code": "drugoe",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інший жіночий одяг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2853,
    "parent": 2838,
    "code": "futbolki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Футболки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/mayki-futbolki/futbolki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2854,
    "parent": 2839,
    "code": "bluzy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Блузи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/bluzy-rubashki/bluzy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2855,
    "parent": 2840,
    "code": "svitery",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Светри",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/svitery-kardigany-hudi/svitery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2856,
    "parent": 2841,
    "code": "vechernie",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Вечірні сукні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/platya/vechernie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2857,
    "parent": 2842,
    "code": "dzhinsovye",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джинсові спідниці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/yubki/dzhinsovye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2858,
    "parent": 2843,
    "code": "plashchi-trenchi",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Плащі й тренчі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/verhnyaya-odezhda/plashchi-trenchi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2859,
    "parent": 2844,
    "code": "mom",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джинси-мом",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/dzhinsy/mom",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2860,
    "parent": 2845,
    "code": "klassicheskie",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Класичні брюки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/bryuki/klassicheskie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2861,
    "parent": 2846,
    "code": "letnie",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Літні комбінезони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/kombinezony/letnie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2862,
    "parent": 2847,
    "code": "s-yubkoy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Костюми зі спідницею",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/kostyumy/s-yubkoy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2863,
    "parent": 2848,
    "code": "oversayz",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Піджаки оверсайз",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/pidzhaki-zhakety/oversayz",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2864,
    "parent": 2849,
    "code": "pizhamy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Піжами",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/domashnyaya-dlya-sna/pizhamy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2865,
    "parent": 2850,
    "code": "lyzhnye-kostyumy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Лижні костюми",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/lyzhnye-kostyumy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2866,
    "parent": 2851,
    "code": "natsionalnye-kostyumy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Національні костюми",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/vyshivanki/natsionalnye-kostyumy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2867,
    "parent": 2838,
    "code": "mayki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Майки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/mayki-futbolki/mayki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2868,
    "parent": 2839,
    "code": "rubashki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сорочки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/bluzy-rubashki/rubashki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2869,
    "parent": 2840,
    "code": "kardigany",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кардигани",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/svitery-kardigany-hudi/kardigany",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2870,
    "parent": 2841,
    "code": "tuniki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Туніки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/platya/tuniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2871,
    "parent": 2842,
    "code": "karandash",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спідниці олівець",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/yubki/karandash",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2872,
    "parent": 2843,
    "code": "dozhdeviki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Дощовики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/verhnyaya-odezhda/dozhdeviki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2873,
    "parent": 2844,
    "code": "dzhegginsy",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джеггінси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/dzhinsy/dzhegginsy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2874,
    "parent": 2845,
    "code": "chinos",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Штани чінос",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/bryuki/chinos",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2875,
    "parent": 2846,
    "code": "zimnie",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Зимові комбінезони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/kombinezony/zimnie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2876,
    "parent": 2849,
    "code": "halaty",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Халати",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/domashnyaya-dlya-sna/halaty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2877,
    "parent": 2850,
    "code": "bele",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивна білизна",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/bele",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2878,
    "parent": 2851,
    "code": "rubashki-bluzki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сорочки та блузки вишиванки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/vyshivanki/rubashki-bluzki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2879,
    "parent": 2838,
    "code": "topy",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Топи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/mayki-futbolki/topy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2880,
    "parent": 2840,
    "code": "hudi",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Худі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/svitery-kardigany-hudi/hudi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2881,
    "parent": 2841,
    "code": "sarafany",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сарафани",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/platya/sarafany",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2882,
    "parent": 2842,
    "code": "kozhanye",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шкіряні спідниці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/yubki/kozhanye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2883,
    "parent": 2843,
    "code": "palto",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пальто",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/verhnyaya-odezhda/palto",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2884,
    "parent": 2844,
    "code": "boifrendy",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джинси-бойфренди",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/dzhinsy/boifrendy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2885,
    "parent": 2845,
    "code": "kargo",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Штани карго",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/bryuki/kargo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2886,
    "parent": 2846,
    "code": "s-bryukami",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Комбінезони з брюками",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/kombinezony/s-bryukami",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2887,
    "parent": 2849,
    "code": "kigurumi",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кігурумі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/domashnyaya-dlya-sna/kigurumi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2888,
    "parent": 2850,
    "code": "losiny",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні лосини",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/losiny",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2889,
    "parent": 2838,
    "code": "polo",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Поло",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/mayki-futbolki/polo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2890,
    "parent": 2840,
    "code": "svitshoty",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Світшоти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/svitery-kardigany-hudi/svitshoty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2891,
    "parent": 2841,
    "code": "povsednevnye",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Повсякденні сукні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/platya/povsednevnye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2892,
    "parent": 2842,
    "code": "v-pol",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спідниці довгі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/yubki/v-pol",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2893,
    "parent": 2843,
    "code": "vetrovki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Вітровки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/verhnyaya-odezhda/vetrovki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2894,
    "parent": 2844,
    "code": "pryamye",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Прямі джинси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/dzhinsy/pryamye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2895,
    "parent": 2845,
    "code": "lnyanye",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Лляні штани",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/bryuki/lnyanye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2896,
    "parent": 2846,
    "code": "s-shortami",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Комбінезони з шортами",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/kombinezony/s-shortami",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2897,
    "parent": 2849,
    "code": "nochnye-rubashki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Нічні сорочки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/domashnyaya-dlya-sna/nochnye-rubashki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2898,
    "parent": 2850,
    "code": "dzhoggery",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джоггери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/dzhoggery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2899,
    "parent": 2838,
    "code": "longsliv",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Лонгслів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/mayki-futbolki/longsliv",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2900,
    "parent": 2840,
    "code": "tolstovki",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Толстовки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/svitery-kardigany-hudi/tolstovki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2901,
    "parent": 2841,
    "code": "kokteylnye",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Коктейльні сукні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/platya/kokteylnye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2902,
    "parent": 2842,
    "code": "plissirovannye",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Плісировані спідниці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/yubki/plissirovannye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2903,
    "parent": 2843,
    "code": "kozhanye-kurtki",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шкіряні куртки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/verhnyaya-odezhda/kozhanye-kurtki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2904,
    "parent": 2844,
    "code": "uzkie-skinny",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Вузькі джинси / skinny",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/dzhinsy/uzkie-skinny",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2905,
    "parent": 2845,
    "code": "velvetovye",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Вельветові штани",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/bryuki/velvetovye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2906,
    "parent": 2849,
    "code": "penyuary",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пеньюари",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/domashnyaya-dlya-sna/penyuary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2907,
    "parent": 2850,
    "code": "kapri-bridzhi",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні капрі та бриджі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/kapri-bridzhi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2908,
    "parent": 2840,
    "code": "golfy-vodolazki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Гольфи / водолазки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/svitery-kardigany-hudi/golfy-vodolazki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2909,
    "parent": 2841,
    "code": "delovye",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ділові сукні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/platya/delovye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2910,
    "parent": 2842,
    "code": "teplye",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Теплі спідниці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/yubki/teplye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2911,
    "parent": 2843,
    "code": "bombery",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бомбери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/verhnyaya-odezhda/bombery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2912,
    "parent": 2844,
    "code": "vysokie",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Високі джинси / джинси з високою талією",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/dzhinsy/vysokie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2913,
    "parent": 2845,
    "code": "losiny-legginsy",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Лосини і легінси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/bryuki/losiny-legginsy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2914,
    "parent": 2850,
    "code": "lyzhnye-kurtki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Лижні куртки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/lyzhnye-kurtki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2915,
    "parent": 2840,
    "code": "koftochki",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кофтинки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/svitery-kardigany-hudi/koftochki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2916,
    "parent": 2841,
    "code": "vypusknye",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Випускні сукні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/platya/vypusknye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2917,
    "parent": 2842,
    "code": "mini",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Міні-спідниці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/yubki/mini",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2918,
    "parent": 2843,
    "code": "dzhinsovye-kurtki",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джинсові куртки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/verhnyaya-odezhda/dzhinsovye-kurtki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2919,
    "parent": 2844,
    "code": "uteplennye",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джинси утеплені",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/dzhinsy/uteplennye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2920,
    "parent": 2845,
    "code": "s-zavyshennoj-taliey",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Брюки з високою талією",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/bryuki/s-zavyshennoj-taliey",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2921,
    "parent": 2850,
    "code": "lyzhnye-kombinezony",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Лижні комбінезони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/lyzhnye-kombinezony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2922,
    "parent": 2840,
    "code": "dzhempery",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джемпери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/svitery-kardigany-hudi/dzhempery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2923,
    "parent": 2841,
    "code": "plate-pidzhak",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сукня-піджак",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/platya/plate-pidzhak",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2924,
    "parent": 2842,
    "code": "zamshevye",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Замшеві спідниці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/yubki/zamshevye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2925,
    "parent": 2843,
    "code": "parki",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Парки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/verhnyaya-odezhda/parki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2926,
    "parent": 2844,
    "code": "rvanye",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Рвані джинси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/dzhinsy/rvanye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2927,
    "parent": 2845,
    "code": "ukorochennye",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Укорочені брюки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/bryuki/ukorochennye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2928,
    "parent": 2850,
    "code": "lyzhnye-shtany-bryuki",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Лижні штани та брюки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/lyzhnye-shtany-bryuki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2929,
    "parent": 2840,
    "code": "nakidki",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Накидки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/svitery-kardigany-hudi/nakidki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2930,
    "parent": 2841,
    "code": "v-belevom-stile",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сукні у білизняному стилі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/platya/v-belevom-stile",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2931,
    "parent": 2842,
    "code": "midi",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спідниці міді",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/yubki/midi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2932,
    "parent": 2843,
    "code": "puhoviki-zimnie-kurtki",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пуховики і зимові куртки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/verhnyaya-odezhda/puhoviki-zimnie-kurtki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2933,
    "parent": 2844,
    "code": "klesh",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джинси кльош",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/dzhinsy/klesh",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2934,
    "parent": 2850,
    "code": "shtany-dlya-snoubordov",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Штани для сноубордів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/shtany-dlya-snoubordov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2935,
    "parent": 2840,
    "code": "poncho",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пончо",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/svitery-kardigany-hudi/poncho",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2936,
    "parent": 2842,
    "code": "fatinovye",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Фатінові спідниці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/yubki/fatinovye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2937,
    "parent": 2843,
    "code": "shuby-polushubki",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шуби, кожухи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/verhnyaya-odezhda/shuby-polushubki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2938,
    "parent": 2844,
    "code": "kyuloty",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джинси кюлоти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/dzhinsy/kyuloty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2939,
    "parent": 2850,
    "code": "kurtki-dlya-snoubordov",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Куртки для сноубордів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/kurtki-dlya-snoubordov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2940,
    "parent": 2842,
    "code": "shorty",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спідниці-шорти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/yubki/shorty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2941,
    "parent": 2843,
    "code": "dublenki",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Дублянки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/verhnyaya-odezhda/dublenki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2942,
    "parent": 2844,
    "code": "shirokie",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Широкі джинси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/dzhinsy/shirokie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2943,
    "parent": 2842,
    "code": "na-zapah",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спідниці на запах",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/yubki/na-zapah",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2944,
    "parent": 2843,
    "code": "zhiletki",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Жилетки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/verhnyaya-odezhda/zhiletki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2945,
    "parent": 2842,
    "code": "iz-ekokozhi",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спідниці з екошкіри",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/yubki/iz-ekokozhi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2946,
    "parent": 2843,
    "code": "bolero",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Болеро",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/verhnyaya-odezhda/bolero",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2947,
    "parent": 2843,
    "code": "ekoshuby",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Екошуби",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/verhnyaya-odezhda/ekoshuby",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2948,
    "parent": 2843,
    "code": "anoraki",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Анораки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/verhnyaya-odezhda/anoraki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2949,
    "parent": 2843,
    "code": "kosuhi",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Косухи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/verhnyaya-odezhda/kosuhi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2950,
    "parent": 2843,
    "code": "kurtki-rubashki",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Куртки-сорочки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/verhnyaya-odezhda/kurtki-rubashki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2951,
    "parent": 2016,
    "code": "shorty",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шорти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/shorty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      2952,
      2953,
      2954,
      2955,
      2956,
      2957,
      2958,
      2959
    ]
  },
  {
    "category_id": 2952,
    "parent": 2951,
    "code": "dzhinsovye",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джинсові шорти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/shorty/dzhinsovye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2953,
    "parent": 2951,
    "code": "kozhanye",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шкіряні шорти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/shorty/kozhanye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2954,
    "parent": 2951,
    "code": "trikotazhnye",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Трикотажні шорти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/shorty/trikotazhnye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2955,
    "parent": 2951,
    "code": "vysokie",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шорти з високою талією",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/shorty/vysokie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2956,
    "parent": 2951,
    "code": "bridzhi-kapri",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бриджі та капрі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/shorty/bridzhi-kapri",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2957,
    "parent": 2951,
    "code": "bermudy",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шорти-бермуди",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/shorty/bermudy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2958,
    "parent": 2951,
    "code": "velosipedki",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Велосипедки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/shorty/velosipedki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2959,
    "parent": 2951,
    "code": "drugoe",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші шорти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/shorty/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2960,
    "parent": 2847,
    "code": "s-bryukami",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Костюми з брюками",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/kostyumy/s-bryukami",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2961,
    "parent": 2847,
    "code": "s-shortami",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Костюми з шортами",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/kostyumy/s-shortami",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2962,
    "parent": 2846,
    "code": "dzhinsovye",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джинсові комбінезони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/kombinezony/dzhinsovye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2963,
    "parent": 2846,
    "code": "vechernie",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Вечірні комбінезони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/kombinezony/vechernie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2964,
    "parent": 2845,
    "code": "kozhanye",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шкіряні брюки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/bryuki/kozhanye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2965,
    "parent": 2845,
    "code": "shirokie",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Широкі брюки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/bryuki/shirokie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2966,
    "parent": 2845,
    "code": "klesh",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Брюки кльош",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/bryuki/klesh",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2967,
    "parent": 2845,
    "code": "kyuloty",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Брюки-кюлоти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/bryuki/kyuloty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2968,
    "parent": 2845,
    "code": "drugoe",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші брюки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/bryuki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2969,
    "parent": 2848,
    "code": "dzhinsovye",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джинсові піджаки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/pidzhaki-zhakety/dzhinsovye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2970,
    "parent": 2848,
    "code": "kozhanye",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шкіряні піджаки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/pidzhaki-zhakety/kozhanye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2971,
    "parent": 2848,
    "code": "drugoe",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші піджаки та жакети",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/pidzhaki-zhakety/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2972,
    "parent": 2849,
    "code": "kostyumy",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Домашні костюми",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/domashnyaya-dlya-sna/kostyumy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2973,
    "parent": 2849,
    "code": "platya",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Домашні сукні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/domashnyaya-dlya-sna/platya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2974,
    "parent": 2849,
    "code": "shtany-bryuki",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Домашні штани та брюки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/domashnyaya-dlya-sna/shtany-bryuki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2975,
    "parent": 2850,
    "code": "kostyumy",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні костюми",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/kostyumy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2976,
    "parent": 2850,
    "code": "futbolki",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні футболки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/futbolki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2977,
    "parent": 2850,
    "code": "mayki",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні майки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/mayki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2978,
    "parent": 2850,
    "code": "topy",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні топи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/topy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2979,
    "parent": 2850,
    "code": "yubki",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні спідниці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/yubki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2980,
    "parent": 2850,
    "code": "platya",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні сукні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/platya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2981,
    "parent": 2850,
    "code": "shorty",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні шорти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/shorty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2982,
    "parent": 2850,
    "code": "kombinezony",
    "position": 18,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні комбінезони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/kombinezony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2983,
    "parent": 2850,
    "code": "kofty",
    "position": 19,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні кофти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/kofty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2984,
    "parent": 2850,
    "code": "kurtki-vetrovki",
    "position": 20,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні куртки і ветровки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/kurtki-vetrovki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2985,
    "parent": 2850,
    "code": "shtany-bryuki",
    "position": 21,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні штани та брюки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/shtany-bryuki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2986,
    "parent": 2850,
    "code": "drugoe",
    "position": 22,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інший спортивний одяг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/sportivnaya-odezhda/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2987,
    "parent": 2851,
    "code": "platya",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сукні вишиванки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/vyshivanki/platya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2988,
    "parent": 2851,
    "code": "futbolki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Футболки вишиванки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/vyshivanki/futbolki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2989,
    "parent": 2851,
    "code": "drugoe",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші вишиванки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/vyshivanki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2990,
    "parent": 2838,
    "code": "drugoe",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/mayki-futbolki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2991,
    "parent": 2839,
    "code": "drugoe",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/bluzy-rubashki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2992,
    "parent": 2841,
    "code": "drugoe",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші плаття",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/platya/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2993,
    "parent": 2840,
    "code": "drugoe",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/svitery-kardigany-hudi/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2994,
    "parent": 2842,
    "code": "drugoe",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші спідниці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/yubki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2995,
    "parent": 2843,
    "code": "drugoe",
    "position": 18,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інший верхній одяг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/verhnyaya-odezhda/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2996,
    "parent": 2844,
    "code": "drugoe",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші джинси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/dzhinsy/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2997,
    "parent": 2846,
    "code": "drugoe",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші комбінезони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/kombinezony/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2998,
    "parent": 2847,
    "code": "drugoe",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/kostyumy/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 2999,
    "parent": 2849,
    "code": "drugoe",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/zhenskaya-odezhda/domashnyaya-dlya-sna/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3000,
    "parent": 570,
    "code": "dlya-manikyura-pedikyura",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Для манікюру і педикюру",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/oborudovanie-parikmaherskih-salonov-krasoty/dlya-manikyura-pedikyura",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3001,
    "parent": 570,
    "code": "dlya-parikmaherskoy",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Для перукарні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/oborudovanie-parikmaherskih-salonov-krasoty/dlya-parikmaherskoy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3002,
    "parent": 570,
    "code": "dlya-massazhnyh-salonov",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Для масажних салонів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/oborudovanie-parikmaherskih-salonov-krasoty/dlya-massazhnyh-salonov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3003,
    "parent": 570,
    "code": "dlya-solyariya",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Для солярію",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/oborudovanie-parikmaherskih-salonov-krasoty/dlya-solyariya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3004,
    "parent": 570,
    "code": "mebel-dlya-salonov-krasoty",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Меблі для салонів краси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/oborudovanie-parikmaherskih-salonov-krasoty/mebel-dlya-salonov-krasoty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3005,
    "parent": 570,
    "code": "koltsevye-lampy",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кільцеві лампи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/oborudovanie-parikmaherskih-salonov-krasoty/koltsevye-lampy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3006,
    "parent": 570,
    "code": "drugoe",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/oborudovanie-parikmaherskih-salonov-krasoty/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3007,
    "parent": 52,
    "code": "uhod-za-litsom",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Догляд за обличчям",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/uhod-za-litsom",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3019,
      3031,
      3043,
      3055,
      3067,
      3078,
      3087,
      3095,
      3101,
      3106,
      3111,
      3115,
      3128
    ]
  },
  {
    "category_id": 3008,
    "parent": 52,
    "code": "makiyazh",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Макіяж / декоративна косметика",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3020,
      3032,
      3044,
      3056,
      3068,
      3079,
      3088,
      3096,
      3102,
      3107,
      3112,
      3116,
      3119,
      3121,
      3122,
      3123,
      3124,
      3125,
      3126,
      3127,
      3132
    ]
  },
  {
    "category_id": 3009,
    "parent": 52,
    "code": "kosmetika-dlya-volos",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Косметика для волосся",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-volos",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3021,
      3045,
      3057,
      3069,
      3080,
      3089,
      3097,
      3103,
      3108,
      3113,
      3117,
      3130,
      3133
    ]
  },
  {
    "category_id": 3010,
    "parent": 52,
    "code": "okrashivanie",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Фарбування",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/okrashivanie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3022,
      3034,
      3046,
      3058,
      3070,
      3134
    ]
  },
  {
    "category_id": 3011,
    "parent": 52,
    "code": "kosmetika-dlya-tela",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Косметика для тіла",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-tela",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3023,
      3035,
      3047,
      3059,
      3071,
      3081,
      3090,
      3098,
      3104,
      3109,
      3114,
      3118,
      3120,
      3131,
      3135
    ]
  },
  {
    "category_id": 3012,
    "parent": 52,
    "code": "dlya-polosti-rta",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Для ротової порожнини",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-polosti-rta",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3024,
      3036,
      3048,
      3060,
      3072,
      3136
    ]
  },
  {
    "category_id": 3013,
    "parent": 52,
    "code": "dlya-nogtey",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Для нігтів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-nogtey",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3025,
      3037,
      3049,
      3061,
      3073,
      3082,
      3091,
      3129,
      3137
    ]
  },
  {
    "category_id": 3014,
    "parent": 52,
    "code": "dlya-britya",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Для гоління",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-britya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3026,
      3038,
      3050,
      3062,
      3074,
      3083,
      3092,
      3138
    ]
  },
  {
    "category_id": 3015,
    "parent": 52,
    "code": "gigienicheskie-sredstva",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Гігієнічні засоби",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/gigienicheskie-sredstva",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3027,
      3039,
      3051,
      3063,
      3075,
      3084,
      3139
    ]
  },
  {
    "category_id": 3016,
    "parent": 52,
    "code": "ortopedicheskie-tovary",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ортопедичні товари",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/ortopedicheskie-tovary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3028,
      3040,
      3052,
      3064,
      3076,
      3085,
      3093,
      3099
    ]
  },
  {
    "category_id": 3017,
    "parent": 52,
    "code": "meditsinskie-prinadlezhnosti",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Медичне приладдя",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/meditsinskie-prinadlezhnosti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3029,
      3041,
      3053,
      3065,
      3140
    ]
  },
  {
    "category_id": 3018,
    "parent": 52,
    "code": "kosmeticheskie-instrumenty-aksessuary",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Косметичні інструменти та аксесуари",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmeticheskie-instrumenty-aksessuary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3030,
      3042,
      3054,
      3066,
      3077,
      3086,
      3094,
      3100,
      3105,
      3110
    ]
  },
  {
    "category_id": 3019,
    "parent": 3007,
    "code": "kremy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Креми для обличчя",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/uhod-za-litsom/kremy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3020,
    "parent": 3008,
    "code": "tush",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Туш",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh/tush",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3021,
    "parent": 3009,
    "code": "shampuni",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шампуні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-volos/shampuni",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3022,
    "parent": 3010,
    "code": "kraska",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Фарба для волосся",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/okrashivanie/kraska",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3023,
    "parent": 3011,
    "code": "dezodoranty-antiperspiranty",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Дезодоранти і антиперспіранти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-tela/dezodoranty-antiperspiranty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3024,
    "parent": 3012,
    "code": "pasty",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Зубні пасти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-polosti-rta/pasty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3025,
    "parent": 3013,
    "code": "gel-laki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Гель-лаки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-nogtey/gel-laki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3026,
    "parent": 3014,
    "code": "kosmetika",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Косметика для гоління",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-britya/kosmetika",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3027,
    "parent": 3015,
    "code": "prokladki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Прокладки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/gigienicheskie-sredstva/prokladki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3028,
    "parent": 3016,
    "code": "prisposobleniya-dlya-stopy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пристосування для стоп",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/ortopedicheskie-tovary/prisposobleniya-dlya-stopy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3029,
    "parent": 3017,
    "code": "odnorazovye-shapochki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Одноразові шапочки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/meditsinskie-prinadlezhnosti/odnorazovye-shapochki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3030,
    "parent": 3018,
    "code": "zerkala-dlya-makiyazha",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Дзеркала для макіяжу",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmeticheskie-instrumenty-aksessuary/zerkala-dlya-makiyazha",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3031,
    "parent": 3007,
    "code": "maski",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Маски для обличчя",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/uhod-za-litsom/maski",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3032,
    "parent": 3008,
    "code": "teni",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Тіні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh/teni",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3034,
    "parent": 3010,
    "code": "toniruyushchie-sredstva",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Тонуючі засоби",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/okrashivanie/toniruyushchie-sredstva",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3035,
    "parent": 3011,
    "code": "geli",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Гелі для душу",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-tela/geli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3036,
    "parent": 3012,
    "code": "shchetki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Зубні щітки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-polosti-rta/shchetki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3037,
    "parent": 3013,
    "code": "zhidkost-dlya-snyatiya-laka",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Рідина для зняття лаку",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-nogtey/zhidkost-dlya-snyatiya-laka",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3038,
    "parent": 3014,
    "code": "stanok",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Станок для гоління",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-britya/stanok",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3039,
    "parent": 3015,
    "code": "tampony",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Тампони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/gigienicheskie-sredstva/tampony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3040,
    "parent": 3016,
    "code": "fiksatory",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Фіксатори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/ortopedicheskie-tovary/fiksatory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3041,
    "parent": 3017,
    "code": "meditsinskie-perchatki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Медичні рукавиці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/meditsinskie-prinadlezhnosti/meditsinskie-perchatki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3042,
    "parent": 3018,
    "code": "pintsety-shchiptsy",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пінцети та щипці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmeticheskie-instrumenty-aksessuary/pintsety-shchiptsy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3043,
    "parent": 3007,
    "code": "masla",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Олія для обличчя",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/uhod-za-litsom/masla",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3044,
    "parent": 3008,
    "code": "konturnye-karandashi",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Контурні олівці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh/konturnye-karandashi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3045,
    "parent": 3009,
    "code": "konditsionery",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кондиціонери для волосся",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-volos/konditsionery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3046,
    "parent": 3010,
    "code": "okislitel",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Окислювач для волосся",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/okrashivanie/okislitel",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3047,
    "parent": 3011,
    "code": "mylo",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Мило",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-tela/mylo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3048,
    "parent": 3012,
    "code": "poroshok",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Зубний порошок",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-polosti-rta/poroshok",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3049,
    "parent": 3013,
    "code": "dekor",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Декор для нігтів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-nogtey/dekor",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3050,
    "parent": 3014,
    "code": "lezviya",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Леза для гоління",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-britya/lezviya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3051,
    "parent": 3015,
    "code": "antiseptiki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Антисептики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/gigienicheskie-sredstva/antiseptiki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3052,
    "parent": 3016,
    "code": "korsety-korrektory-osanki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Корсети, коректори постави",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/ortopedicheskie-tovary/korsety-korrektory-osanki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3053,
    "parent": 3017,
    "code": "perevyazochnye-materialy",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Перев'язувальні матеріали",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/meditsinskie-prinadlezhnosti/perevyazochnye-materialy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3054,
    "parent": 3018,
    "code": "rascheski",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Гребінці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmeticheskie-instrumenty-aksessuary/rascheski",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3055,
    "parent": 3007,
    "code": "skrab",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Скраб для обличчя",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/uhod-za-litsom/skrab",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3056,
    "parent": 3008,
    "code": "podvodka",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Підводка",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh/podvodka",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3057,
    "parent": 3009,
    "code": "balzamy",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бальзами для волосся",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-volos/balzamy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3058,
    "parent": 3010,
    "code": "obestsvechivanie-osvetlenie",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Знебарвлення та освітлення",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/okrashivanie/obestsvechivanie-osvetlenie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3059,
    "parent": 3011,
    "code": "krema-dlya-tela",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Креми для тіла",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-tela/krema-dlya-tela",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3060,
    "parent": 3012,
    "code": "opolaskivateli",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ополіскувачі для порожнини рота, зубів, ясен",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-polosti-rta/opolaskivateli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3061,
    "parent": 3013,
    "code": "uhod-za-kutikuloy",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Догляд за кутикулою",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-nogtey/uhod-za-kutikuloy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3062,
    "parent": 3014,
    "code": "kosmetika-posle-britya",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Косметика після гоління",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-britya/kosmetika-posle-britya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3063,
    "parent": 3015,
    "code": "podguzniki-dlya-vzroslyh",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Підгузки для дорослих",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/gigienicheskie-sredstva/podguzniki-dlya-vzroslyh",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3064,
    "parent": 3016,
    "code": "nakolenniki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Наколінники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/ortopedicheskie-tovary/nakolenniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3065,
    "parent": 3017,
    "code": "meditsinskie-maski-respiratory",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Медичні маски і респіратори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/meditsinskie-prinadlezhnosti/meditsinskie-maski-respiratory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3066,
    "parent": 3018,
    "code": "sponzhi",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спонжі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmeticheskie-instrumenty-aksessuary/sponzhi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3067,
    "parent": 3007,
    "code": "sprey",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спрей для обличчя",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/uhod-za-litsom/sprey",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3068,
    "parent": 3008,
    "code": "korrektory",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Коректори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh/korrektory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3069,
    "parent": 3009,
    "code": "losony",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Лосьйони для волосся",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-volos/losony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3070,
    "parent": 3010,
    "code": "hna",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Хна",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/okrashivanie/hna",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3071,
    "parent": 3011,
    "code": "skraby",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Скраби для тіла",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-tela/skraby",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3072,
    "parent": 3012,
    "code": "dorozhnye-nabory",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Дорожні набори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-polosti-rta/dorozhnye-nabory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3073,
    "parent": 3013,
    "code": "nakladnye-nogti",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Накладні нігті",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-nogtey/nakladnye-nogti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3074,
    "parent": 3014,
    "code": "krem-dlya-depilyatsii",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Крем для депіляції",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-britya/krem-dlya-depilyatsii",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3075,
    "parent": 3015,
    "code": "menstrualnye-chashi",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Менструальні чаші",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/gigienicheskie-sredstva/menstrualnye-chashi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3076,
    "parent": 3016,
    "code": "bandazhi-poyasa",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бандажі та пояси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/ortopedicheskie-tovary/bandazhi-poyasa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3077,
    "parent": 3018,
    "code": "instrumenty-dlya-chistki-litsa",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інструменти для чистки обличчя",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmeticheskie-instrumenty-aksessuary/instrumenty-dlya-chistki-litsa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3078,
    "parent": 3007,
    "code": "piling",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пілінг для обличчя",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/uhod-za-litsom/piling",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3079,
    "parent": 3008,
    "code": "nakladnye-resnitsy",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Накладні вії",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh/nakladnye-resnitsy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3080,
    "parent": 3009,
    "code": "maslo",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Олія для волосся",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-volos/maslo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3081,
    "parent": 3011,
    "code": "sol",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сіль для ванни",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-tela/sol",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3082,
    "parent": 3013,
    "code": "instrumenty-dlya-manikyura-pedikyura",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інструменти для манікюру та педикюру",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-nogtey/instrumenty-dlya-manikyura-pedikyura",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3083,
    "parent": 3014,
    "code": "vosk-dlya-depilyatsii",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Віск для депіляції",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-britya/vosk-dlya-depilyatsii",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3084,
    "parent": 3015,
    "code": "sredstva-dlya-intimnoy-gigieny",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Засоби для інтимної гігієни",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/gigienicheskie-sredstva/sredstva-dlya-intimnoy-gigieny",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3085,
    "parent": 3016,
    "code": "gips-binty-teypy",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Гіпс, бинти, тейпи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/ortopedicheskie-tovary/gips-binty-teypy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3086,
    "parent": 3018,
    "code": "organayzery-dlya-kosmetiki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Органайзери для косметики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmeticheskie-instrumenty-aksessuary/organayzery-dlya-kosmetiki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3087,
    "parent": 3007,
    "code": "sredstva-dlya-ochishcheniya-litsa",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Засоби для очищення обличчя",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/uhod-za-litsom/sredstva-dlya-ochishcheniya-litsa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3088,
    "parent": 3008,
    "code": "gubnaya-pomada",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Губна помада",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh/gubnaya-pomada",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3089,
    "parent": 3009,
    "code": "laki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Лаки для волосся",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-volos/laki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3090,
    "parent": 3011,
    "code": "pena",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Піна для ванни",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-tela/pena",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3091,
    "parent": 3013,
    "code": "startoviy-nabor-dlya-manikyura",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Стартовий набір для манікюру",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-nogtey/startoviy-nabor-dlya-manikyura",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3092,
    "parent": 3014,
    "code": "pasta-dlya-shugaringa",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Паста для шугарінгу",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-britya/pasta-dlya-shugaringa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3093,
    "parent": 3016,
    "code": "ortezy",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ортези",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/ortopedicheskie-tovary/ortezy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3094,
    "parent": 3018,
    "code": "bigudi",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бігуді",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmeticheskie-instrumenty-aksessuary/bigudi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3095,
    "parent": 3007,
    "code": "solntsezashchitnye-sredstva",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сонцезахисні засоби",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/uhod-za-litsom/solntsezashchitnye-sredstva",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3096,
    "parent": 3008,
    "code": "blesk-dlya-gub",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Блиск для губ",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh/blesk-dlya-gub",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3097,
    "parent": 3009,
    "code": "muss",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Мус для волосся",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-volos/muss",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3098,
    "parent": 3011,
    "code": "sredstva-dlya-zagara",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Засоби для засмаги",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-tela/sredstva-dlya-zagara",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3099,
    "parent": 3016,
    "code": "drugie-ortopedicheskie-tovary",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші ортопедичні товари",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/ortopedicheskie-tovary/drugie-ortopedicheskie-tovary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3100,
    "parent": 3018,
    "code": "mochalki-dlya-dusha",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Мочалки для душу",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmeticheskie-instrumenty-aksessuary/mochalki-dlya-dusha",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3101,
    "parent": 3007,
    "code": "toniki",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Тоніки для обличчя",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/uhod-za-litsom/toniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3102,
    "parent": 3008,
    "code": "tint-dlya-gub",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Тінт для губ",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh/tint-dlya-gub",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3103,
    "parent": 3009,
    "code": "sredstva-ot-perhoti",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Засоби від лупи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-volos/sredstva-ot-perhoti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3104,
    "parent": 3011,
    "code": "kosmetika-dlya-ruk",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Косметика для рук",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-tela/kosmetika-dlya-ruk",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3105,
    "parent": 3018,
    "code": "shchetki-dlya-suhogo-massazha",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Щітки для сухого масажу",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmeticheskie-instrumenty-aksessuary/shchetki-dlya-suhogo-massazha",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3106,
    "parent": 3007,
    "code": "mitsellyarnaya-voda",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Міцелярна вода",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/uhod-za-litsom/mitsellyarnaya-voda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3107,
    "parent": 3008,
    "code": "balzamy-dlya-gub",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бальзами для губ",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh/balzamy-dlya-gub",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3108,
    "parent": 3009,
    "code": "sredstva-ot-vypadeniya",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Засоби від випадіння волосся",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-volos/sredstva-ot-vypadeniya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3109,
    "parent": 3011,
    "code": "krem-dlya-ruk",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Крем для рук",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-tela/krem-dlya-ruk",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3110,
    "parent": 3018,
    "code": "drugoe",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmeticheskie-instrumenty-aksessuary/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3111,
    "parent": 3007,
    "code": "syvorotki",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сироватки для обличчя",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/uhod-za-litsom/syvorotki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3112,
    "parent": 3008,
    "code": "kosmetika-dlya-brovey",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Косметика для брів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh/kosmetika-dlya-brovey",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3113,
    "parent": 3009,
    "code": "termozashchita",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Термозахист",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-volos/termozashchita",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3114,
    "parent": 3011,
    "code": "kosmetika-dlya-nog",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Косметика для ніг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-tela/kosmetika-dlya-nog",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3115,
    "parent": 3007,
    "code": "uhod-dlya-glaz",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Догляд за очима",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/uhod-za-litsom/uhod-dlya-glaz",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3116,
    "parent": 3008,
    "code": "tonalniy-krem",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Тональний крем",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh/tonalniy-krem",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3117,
    "parent": 3009,
    "code": "kanekalony",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Канекалони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-volos/kanekalony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3118,
    "parent": 3011,
    "code": "krem-dlya-nog",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Крем для ніг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-tela/krem-dlya-nog",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3119,
    "parent": 3008,
    "code": "pudry",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пудри",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh/pudry",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3120,
    "parent": 3011,
    "code": "dezodorant-dlya-nog",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Дезодорант для ніг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-tela/dezodorant-dlya-nog",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3121,
    "parent": 3008,
    "code": "haylayter",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Хайлайтер",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh/haylayter",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3122,
    "parent": 3008,
    "code": "rumyana",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Рум'яна",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh/rumyana",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3123,
    "parent": 3008,
    "code": "bronzery",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бронзери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh/bronzery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3124,
    "parent": 3008,
    "code": "bb-cc-kremy",
    "position": 17,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "BB-креми і CC-креми",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh/bb-cc-kremy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3125,
    "parent": 3008,
    "code": "nabory",
    "position": 18,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Набори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh/nabory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3126,
    "parent": 3008,
    "code": "bazy-praymery",
    "position": 19,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бази / праймери під макіяж",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh/bazy-praymery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3127,
    "parent": 3008,
    "code": "kisti",
    "position": 20,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пензлі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh/kisti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3128,
    "parent": 3007,
    "code": "drugoe",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/uhod-za-litsom/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3129,
    "parent": 3013,
    "code": "laki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Лаки для нігтів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-nogtey/laki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3130,
    "parent": 3009,
    "code": "maski",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Маски для волосся",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-volos/maski",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3131,
    "parent": 3011,
    "code": "masla",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Масла для тіла",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-tela/masla",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3132,
    "parent": 3008,
    "code": "drugoe",
    "position": 21,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/makiyazh/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3133,
    "parent": 3009,
    "code": "drugoe",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-volos/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3134,
    "parent": 3010,
    "code": "drugoe",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/okrashivanie/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3135,
    "parent": 3011,
    "code": "drugoe",
    "position": 15,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/kosmetika-dlya-tela/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3136,
    "parent": 3012,
    "code": "drugoe",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-polosti-rta/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3137,
    "parent": 3013,
    "code": "drugoe",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-nogtey/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3138,
    "parent": 3014,
    "code": "drugoe",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/dlya-britya/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3139,
    "parent": 3015,
    "code": "drugoe",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/gigienicheskie-sredstva/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3140,
    "parent": 3017,
    "code": "drugoe",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/krasota-zdorove/meditsinskie-prinadlezhnosti/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3141,
    "parent": 98,
    "code": "shkatulki-dlya-ukrasheniy",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Скриньки для прикрас",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/shkatulki-dlya-ukrasheniy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3142,
    "parent": 98,
    "code": "ryukzaki",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Рюкзаки міські та спортивні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/ryukzaki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3155,
      3165,
      3175,
      3215
    ]
  },
  {
    "category_id": 3143,
    "parent": 98,
    "code": "koshelki-kosmetichki-vizitnitsy",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Гаманці, косметички, візитниці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/koshelki-kosmetichki-vizitnitsy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3156,
      3166,
      3176,
      3183,
      3190,
      3216
    ]
  },
  {
    "category_id": 3144,
    "parent": 98,
    "code": "zonty",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Парасолі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/zonty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3145,
    "parent": 98,
    "code": "dlya-volos",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Аксесуари для волосся",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/dlya-volos",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3157,
      3167,
      3177,
      3184,
      3191,
      3217
    ]
  },
  {
    "category_id": 3146,
    "parent": 98,
    "code": "remni-poyasa",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ремені та пояси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/remni-poyasa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3158,
      3168,
      3178,
      3185,
      3192,
      3218
    ]
  },
  {
    "category_id": 3147,
    "parent": 98,
    "code": "sharfy-perchatki",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шарфи і рукавички",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sharfy-perchatki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3159,
      3169,
      3179,
      3186,
      3193,
      3197,
      3201,
      3219
    ]
  },
  {
    "category_id": 3148,
    "parent": 98,
    "code": "ochki",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Окуляри",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/ochki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3160,
      3170,
      3180,
      3187,
      3194,
      3198,
      3220,
      3222
    ]
  },
  {
    "category_id": 3149,
    "parent": 98,
    "code": "galstuki-babochki",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Краватки і метелики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/galstuki-babochki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3161,
      3171
    ]
  },
  {
    "category_id": 3150,
    "parent": 98,
    "code": "dlya-obuvi",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Аксесуари для взуття",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/dlya-obuvi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3162,
      3172,
      3221
    ]
  },
  {
    "category_id": 3151,
    "parent": 98,
    "code": "remeshki-braslety",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ремінці та браслети для годинника",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/remeshki-braslety",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3152,
    "parent": 98,
    "code": "maski-dlya-sna",
    "position": 16,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Маски для сну",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/maski-dlya-sna",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3153,
    "parent": 552,
    "code": "aksessuary",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Аксесуари для сумок",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sumki/aksessuary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3154,
    "parent": 553,
    "code": "koltsa",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Каблучки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/bizhuteriya/koltsa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3155,
    "parent": 3142,
    "code": "dlya-noutbuka",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Рюкзаки для ноутбука",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/ryukzaki/dlya-noutbuka",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3156,
    "parent": 3143,
    "code": "vizitnitsy-kartholdery",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Візитниці та картхолдери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/koshelki-kosmetichki-vizitnitsy/vizitnitsy-kartholdery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3157,
    "parent": 3145,
    "code": "povyazki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пов'язки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/dlya-volos/povyazki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3158,
    "parent": 3146,
    "code": "remni",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ремені",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/remni-poyasa/remni",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3159,
    "parent": 3147,
    "code": "nosovye-platki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Носові хустинки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sharfy-perchatki/nosovye-platki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3160,
    "parent": 3148,
    "code": "opravy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Оправи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/ochki/opravy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3161,
    "parent": 3149,
    "code": "galstuki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Краватки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/galstuki-babochki/galstuki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3162,
    "parent": 3150,
    "code": "stelki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Устілки для взуття",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/dlya-obuvi/stelki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3163,
    "parent": 552,
    "code": "kozhanye",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шкіряні сумки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sumki/kozhanye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3164,
    "parent": 553,
    "code": "sergi",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сережки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/bizhuteriya/sergi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3165,
    "parent": 3142,
    "code": "dlya-puteshestviy",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Рюкзаки для подорожей",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/ryukzaki/dlya-puteshestviy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3166,
    "parent": 3143,
    "code": "koshelki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Гаманці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/koshelki-kosmetichki-vizitnitsy/koshelki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3167,
    "parent": 3145,
    "code": "rezinki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Гумки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/dlya-volos/rezinki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3168,
    "parent": 3146,
    "code": "podtyazhki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Підтяжки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/remni-poyasa/podtyazhki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3169,
    "parent": 3147,
    "code": "palantiny-shali",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Палантини і шалі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sharfy-perchatki/palantiny-shali",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3170,
    "parent": 3148,
    "code": "solntsezashchitnye",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сонцезахисні окуляри",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/ochki/solntsezashchitnye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3171,
    "parent": 3149,
    "code": "babochki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Метелики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/galstuki-babochki/babochki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3172,
    "parent": 3150,
    "code": "kremy-shchetki-aksessuary",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Креми, щітки та інші аксесуари",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/dlya-obuvi/kremy-shchetki-aksessuary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3173,
    "parent": 552,
    "code": "klatchi",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Клатчі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sumki/klatchi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3174,
    "parent": 553,
    "code": "braslety",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Браслети",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/bizhuteriya/braslety",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3175,
    "parent": 3142,
    "code": "gorodskie",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Міські рюкзаки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/ryukzaki/gorodskie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3176,
    "parent": 3143,
    "code": "kosmetichki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Косметички",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/koshelki-kosmetichki-vizitnitsy/kosmetichki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3177,
    "parent": 3145,
    "code": "zakolki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Заколки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/dlya-volos/zakolki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3178,
    "parent": 3146,
    "code": "poyasa",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пояси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/remni-poyasa/poyasa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3179,
    "parent": 3147,
    "code": "platki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Хустки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sharfy-perchatki/platki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3180,
    "parent": 3148,
    "code": "dlya-zreniya",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Окуляри для зору",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/ochki/dlya-zreniya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3181,
    "parent": 552,
    "code": "plyazhnye",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пляжні сумки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sumki/plyazhnye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3182,
    "parent": 553,
    "code": "kulony-podveski",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кулони / підвіски",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/bizhuteriya/kulony-podveski",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3183,
    "parent": 3143,
    "code": "chehly-dlya-klyuchey",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Чохли для ключів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/koshelki-kosmetichki-vizitnitsy/chehly-dlya-klyuchey",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3184,
    "parent": 3145,
    "code": "obruchi",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Обручі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/dlya-volos/obruchi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3185,
    "parent": 3146,
    "code": "shnurki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шнурки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/remni-poyasa/shnurki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3186,
    "parent": 3147,
    "code": "snudy",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Снуди",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sharfy-perchatki/snudy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3187,
    "parent": 3148,
    "code": "kompyuternye",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Комп'ютерні окуляри",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/ochki/kompyuternye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3188,
    "parent": 552,
    "code": "poyasnye",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Поясні сумки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sumki/poyasnye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3189,
    "parent": 553,
    "code": "broshi",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Брошки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/bizhuteriya/broshi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3190,
    "parent": 3143,
    "code": "zazhimy-dlya-deneg",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Затискачі для грошей",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/koshelki-kosmetichki-vizitnitsy/zazhimy-dlya-deneg",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3191,
    "parent": 3145,
    "code": "shpilki",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шпильки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/dlya-volos/shpilki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3192,
    "parent": 3146,
    "code": "portupei",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Портупеї",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/remni-poyasa/portupei",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3193,
    "parent": 3147,
    "code": "sharfy",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шарфи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sharfy-perchatki/sharfy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3194,
    "parent": 3148,
    "code": "kontaktnye-linzy",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Контактні лінзи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/ochki/kontaktnye-linzy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3195,
    "parent": 552,
    "code": "sportivnye",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спортивні сумки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sumki/sportivnye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3196,
    "parent": 553,
    "code": "tsepochki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ланцюжки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/bizhuteriya/tsepochki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3197,
    "parent": 3147,
    "code": "perchatki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Рукавички",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sharfy-perchatki/perchatki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3198,
    "parent": 3148,
    "code": "chehly",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Чохли для окулярів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/ochki/chehly",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3199,
    "parent": 552,
    "code": "cherez-plecho",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сумки через плече",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sumki/cherez-plecho",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3200,
    "parent": 553,
    "code": "choker",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Чокери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/bizhuteriya/choker",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3201,
    "parent": 3147,
    "code": "varezhki",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Рукавиці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sharfy-perchatki/varezhki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3202,
    "parent": 552,
    "code": "portfeli",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Портфелі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sumki/portfeli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3203,
    "parent": 553,
    "code": "zaponki",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Запонки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/bizhuteriya/zaponki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3204,
    "parent": 552,
    "code": "shoppery",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шоппери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sumki/shoppery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3205,
    "parent": 553,
    "code": "klipsy",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кліпси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/bizhuteriya/klipsy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3206,
    "parent": 552,
    "code": "barsetki",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Барсетки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sumki/barsetki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3207,
    "parent": 553,
    "code": "bulavki",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шпильки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/bizhuteriya/bulavki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3208,
    "parent": 552,
    "code": "chemodany",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Валізи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sumki/chemodany",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3209,
    "parent": 553,
    "code": "biser-furnitura",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бісер / фурнітура",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/bizhuteriya/biser-furnitura",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3210,
    "parent": 552,
    "code": "dorozhnye",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Дорожні сумки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sumki/dorozhnye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3211,
    "parent": 553,
    "code": "kole",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кольє",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/bizhuteriya/kole",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3212,
    "parent": 553,
    "code": "nabory-bizhuterii",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Набори біжутерії",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/bizhuteriya/nabory-bizhuterii",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3213,
    "parent": 552,
    "code": "drugoe",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sumki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3214,
    "parent": 553,
    "code": "drugoe",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/bizhuteriya/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3215,
    "parent": 3142,
    "code": "drugoe",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/ryukzaki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3216,
    "parent": 3143,
    "code": "drugoe",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/koshelki-kosmetichki-vizitnitsy/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3217,
    "parent": 3145,
    "code": "drugoe",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/dlya-volos/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3218,
    "parent": 3146,
    "code": "drugoe",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/remni-poyasa/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3219,
    "parent": 3147,
    "code": "drugoe",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/sharfy-perchatki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3220,
    "parent": 3148,
    "code": "drugoe",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/ochki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3221,
    "parent": 3150,
    "code": "shnurki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шнурки для взуття",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/dlya-obuvi/shnurki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3222,
    "parent": 3148,
    "code": "aksessuary",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Аксесуари для окулярів і лінз",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/ochki/aksessuary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3223,
    "parent": 891,
    "code": "spetsodezhda-i-spetsobuv",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спецодяг, спецвзуття та аксесуари",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "moda-i-stil/spetsodezhda-i-spetsobuv",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3224,
      3225,
      3226,
      3227,
      3228,
      3229,
      3230,
      3240
    ]
  },
  {
    "category_id": 3224,
    "parent": 3223,
    "code": "spetsodezhda",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Робочий спецодяг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/spetsodezhda-i-spetsobuv/spetsodezhda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3231,
      3234,
      3237,
      3239
    ]
  },
  {
    "category_id": 3225,
    "parent": 3223,
    "code": "meditsinskaya-odezhda",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Медичний одяг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/spetsodezhda-i-spetsobuv/meditsinskaya-odezhda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3232,
      3235
    ]
  },
  {
    "category_id": 3226,
    "parent": 3223,
    "code": "svetootrazhayushchie-zhilety",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Світловідбиваючі жилети",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/spetsodezhda-i-spetsobuv/svetootrazhayushchie-zhilety",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3227,
    "parent": 3223,
    "code": "odnorazovaya-odezhda",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Одноразовий одяг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/spetsodezhda-i-spetsobuv/odnorazovaya-odezhda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3228,
    "parent": 3223,
    "code": "dozhdeviki",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Дощовики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/spetsodezhda-i-spetsobuv/dozhdeviki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3229,
    "parent": 3223,
    "code": "sredstva-zashchity-organov-dyhaniya",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Засоби захисту органів дихання",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/spetsodezhda-i-spetsobuv/sredstva-zashchity-organov-dyhaniya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3230,
    "parent": 3223,
    "code": "rabochaya-obuv",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Робоче взуття",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/spetsodezhda-i-spetsobuv/rabochaya-obuv",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3233,
      3236,
      3238,
      3241
    ]
  },
  {
    "category_id": 3231,
    "parent": 3224,
    "code": "bryuki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Робочі штани",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/spetsodezhda-i-spetsobuv/spetsodezhda/bryuki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3232,
    "parent": 3225,
    "code": "halaty",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Медичні халати",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/spetsodezhda-i-spetsobuv/meditsinskaya-odezhda/halaty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3233,
    "parent": 3230,
    "code": "botinki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Робочі черевики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/spetsodezhda-i-spetsobuv/rabochaya-obuv/botinki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3234,
    "parent": 3224,
    "code": "kurtki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Робочі куртки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/spetsodezhda-i-spetsobuv/spetsodezhda/kurtki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3235,
    "parent": 3225,
    "code": "kostyumy_5",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Медичні костюми",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/spetsodezhda-i-spetsobuv/meditsinskaya-odezhda/kostyumy_5",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3236,
    "parent": 3230,
    "code": "sapogi",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Робочі чоботи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/spetsodezhda-i-spetsobuv/rabochaya-obuv/sapogi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3237,
    "parent": 3224,
    "code": "kombinezony",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Робочі комбінезони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/spetsodezhda-i-spetsobuv/spetsodezhda/kombinezony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3238,
    "parent": 3230,
    "code": "krossovki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Робочі кросівки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/spetsodezhda-i-spetsobuv/rabochaya-obuv/krossovki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3239,
    "parent": 3224,
    "code": "kostyumy",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Робочі костюми",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/spetsodezhda-i-spetsobuv/spetsodezhda/kostyumy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3240,
    "parent": 3223,
    "code": "drugoe",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "moda-i-stil/spetsodezhda-i-spetsobuv/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3241,
    "parent": 3230,
    "code": "drugoe",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше робоче взуття",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/spetsodezhda-i-spetsobuv/rabochaya-obuv/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3242,
    "parent": 557,
    "code": "koltsa",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Каблучки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/yuvelirnye-izdeliya/koltsa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3243,
    "parent": 557,
    "code": "sergi",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сережки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/yuvelirnye-izdeliya/sergi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3244,
    "parent": 557,
    "code": "braslety",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Браслети",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/yuvelirnye-izdeliya/braslety",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3245,
    "parent": 557,
    "code": "podveski",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Підвіски",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/yuvelirnye-izdeliya/podveski",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3246,
    "parent": 557,
    "code": "broshi",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Брошки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/yuvelirnye-izdeliya/broshi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3247,
    "parent": 557,
    "code": "tsepochki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ланцюжки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/yuvelirnye-izdeliya/tsepochki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3248,
    "parent": 557,
    "code": "ukrasheniya-dlya-pirsinga",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Прикраси для пірсингу",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/yuvelirnye-izdeliya/ukrasheniya-dlya-pirsinga",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3249,
    "parent": 557,
    "code": "kole",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кольє",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/yuvelirnye-izdeliya/kole",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3250,
    "parent": 557,
    "code": "bulavki",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шпильки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/yuvelirnye-izdeliya/bulavki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3251,
    "parent": 557,
    "code": "zaponki",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Запонки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/yuvelirnye-izdeliya/zaponki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3252,
    "parent": 557,
    "code": "sharmy",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шарми",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/yuvelirnye-izdeliya/sharmy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3253,
    "parent": 557,
    "code": "nabory",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Набори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/yuvelirnye-izdeliya/nabory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3254,
    "parent": 557,
    "code": "drugoe",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "moda-i-stil/aksessuary/yuvelirnye-izdeliya/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3255,
    "parent": 192,
    "code": "arenda-avto",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "services",
    "name": "Оренда авто",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/perevozki-arenda-transporta/arenda-avto",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3256,
    "parent": 192,
    "code": "arenda-avtobusov-i-mikroavtobusov",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "services",
    "name": "Оренда автобусів та мікроавтобусів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/perevozki-arenda-transporta/arenda-avtobusov-i-mikroavtobusov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3257,
    "parent": 192,
    "code": "drugiye-transportnye-uslugi",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "services",
    "name": "Інші транспортні послуги",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/perevozki-arenda-transporta/drugiye-transportnye-uslugi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3258,
    "parent": 192,
    "code": "arenda-moto-transporta",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "services",
    "name": "Оренда мото транспорту",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/perevozki-arenda-transporta/arenda-moto-transporta",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3259,
    "parent": 192,
    "code": "arenda-spetstehniki",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "services",
    "name": "Оренда спец.техніки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/perevozki-arenda-transporta/arenda-spetstehniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3263,
      3264,
      3265,
      3266,
      3267,
      3268,
      3269,
      3270
    ]
  },
  {
    "category_id": 3260,
    "parent": 192,
    "code": "gruzoperevozki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "services",
    "name": "Вантажоперевезення",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/perevozki-arenda-transporta/gruzoperevozki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3261,
    "parent": 192,
    "code": "passazhirskie-perevozki",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "services",
    "name": "Пасажирські перевезення",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/perevozki-arenda-transporta/passazhirskie-perevozki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3262,
    "parent": 192,
    "code": "arenda-gruzovyh-avto",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "services",
    "name": "Оренда вантажних авто",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "uslugi/perevozki-arenda-transporta/arenda-gruzovyh-avto",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3263,
    "parent": 3259,
    "code": "buldozery",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "services",
    "name": "Оренда бульдозерів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "uslugi/perevozki-arenda-transporta/arenda-spetstehniki/buldozery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3264,
    "parent": 3259,
    "code": "avtovyshki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "services",
    "name": "Оренда автовишок",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "uslugi/perevozki-arenda-transporta/arenda-spetstehniki/avtovyshki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3265,
    "parent": 3259,
    "code": "avtokrany",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "services",
    "name": "Оренда автокранів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "uslugi/perevozki-arenda-transporta/arenda-spetstehniki/avtokrany",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3266,
    "parent": 3259,
    "code": "manipulyatory",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "services",
    "name": "Оренда маніпуляторів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "uslugi/perevozki-arenda-transporta/arenda-spetstehniki/manipulyatory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3267,
    "parent": 3259,
    "code": "samosvaly",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "services",
    "name": "Оренда самоскидів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "uslugi/perevozki-arenda-transporta/arenda-spetstehniki/samosvaly",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3268,
    "parent": 3259,
    "code": "ekskavatory",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "services",
    "name": "Оренда екскаваторів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "uslugi/perevozki-arenda-transporta/arenda-spetstehniki/ekskavatory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3269,
    "parent": 3259,
    "code": "pogruzchiki",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "services",
    "name": "Оренда навантажувачів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "uslugi/perevozki-arenda-transporta/arenda-spetstehniki/pogruzchiki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3270,
    "parent": 3259,
    "code": "drugaya",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "services",
    "name": "Оренда іншої спецтехніки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "uslugi/perevozki-arenda-transporta/arenda-spetstehniki/drugaya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3272,
    "parent": 442,
    "code": "akkumulyatory-i-portativnye-batarei",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "PowerBank та акумулятори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "elektronika/telefony-i-aksesuary/aksessuary-dlya-telefonov/akkumulyatory-i-portativnye-batarei",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3273,
    "parent": 442,
    "code": "zaryadnye-ustroystva-i-dok-stantsii",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Зарядні пристрої та док-станції",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "elektronika/telefony-i-aksesuary/aksessuary-dlya-telefonov/zaryadnye-ustroystva-i-dok-stantsii",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3274,
    "parent": 442,
    "code": "garnitury",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Гарнітури",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "elektronika/telefony-i-aksesuary/aksessuary-dlya-telefonov/garnitury",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3275,
    "parent": 442,
    "code": "korpusa-paneli",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Корпуси / панелі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "elektronika/telefony-i-aksesuary/aksessuary-dlya-telefonov/korpusa-paneli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3276,
    "parent": 442,
    "code": "chehly",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Чохли",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "elektronika/telefony-i-aksesuary/aksessuary-dlya-telefonov/chehly",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3277,
    "parent": 442,
    "code": "data-kabeli",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Data-кабелі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "elektronika/telefony-i-aksesuary/aksessuary-dlya-telefonov/data-kabeli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3278,
    "parent": 442,
    "code": "karty-pamyati",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Карти пам'яті",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "elektronika/telefony-i-aksesuary/aksessuary-dlya-telefonov/karty-pamyati",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3279,
    "parent": 442,
    "code": "zaschitnye-plenki-i-stekla",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Захисні плівки та скло",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "elektronika/telefony-i-aksesuary/aksessuary-dlya-telefonov/zaschitnye-plenki-i-stekla",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3280,
    "parent": 442,
    "code": "monopody-dlya-selfi",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Моноподи для селфі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "elektronika/telefony-i-aksesuary/aksessuary-dlya-telefonov/monopody-dlya-selfi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3281,
    "parent": 442,
    "code": "ochki-virtualnoy-realnosti",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Окуляри віртуальної реальності",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "elektronika/telefony-i-aksesuary/aksessuary-dlya-telefonov/ochki-virtualnoy-realnosti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3282,
    "parent": 442,
    "code": "smart-chasy",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Смарт годинники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "elektronika/telefony-i-aksesuary/aksessuary-dlya-telefonov/smart-chasy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3283,
    "parent": 442,
    "code": "fitnes-trekery",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Фітнес-трекери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "elektronika/telefony-i-aksesuary/aksessuary-dlya-telefonov/fitnes-trekery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3284,
    "parent": 442,
    "code": "prochie-aksessuary-dlya-telefonov",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Інші аксесуари для телефонів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "elektronika/telefony-i-aksesuary/aksessuary-dlya-telefonov/prochie-aksessuary-dlya-telefonov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3285,
    "parent": 654,
    "code": "generatory",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Генератори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "dom-i-sad/instrumenty/generatory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3286,
    "parent": 383,
    "code": "zaryadnyye-stantsii",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Зарядні станції",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-doma/zaryadnyye-stantsii",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3287,
    "parent": 383,
    "code": "preobrazovateli-napryazheniya",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Перетворювачі напруги",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-doma/preobrazovateli-napryazheniya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3288,
    "parent": 383,
    "code": "bespereboyniki-pitaniya",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "electronics",
    "name": "Безперебійники живлення",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "elektronika/tehnika-dlya-doma/bespereboyniki-pitaniya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3290,
    "parent": 590,
    "code": "fonariki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ліхтарики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh/turizm/fonariki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3291,
    "parent": 590,
    "code": "gazovyye-gorelki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Газові пальники (портативні газові плити)",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh/turizm/gazovyye-gorelki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3292,
    "parent": 590,
    "code": "gazovyye-ballony",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Газові балони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh/turizm/gazovyye-ballony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3293,
    "parent": 590,
    "code": "gps-navigatory",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "GPS навігатори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh/turizm/gps-navigatory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3294,
    "parent": 590,
    "code": "palatki",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Палатки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh/turizm/palatki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3295,
    "parent": 590,
    "code": "spalnye-meshki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спальні мішки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh/turizm/spalnye-meshki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3296,
    "parent": 590,
    "code": "ryukzaki",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Рюкзаки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh/turizm/ryukzaki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3297,
    "parent": 590,
    "code": "prochie-turisticheskie-tovary",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інші туристичні товари",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/sport-otdyh/turizm/prochie-turisticheskie-tovary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3298,
    "parent": 1610,
    "code": "kovorkingi",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "real_estate",
    "name": "Коворкінги",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": false,
    "level": 3,
    "max_photos": 24,
    "path": "nedvizhimost/kommercheskaya-nedvizhimost/kovorkingi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3352,
    "parent": 903,
    "code": "militariya",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Мілітарія",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3353,
      3354,
      3355,
      3356,
      3357,
      3358,
      3359,
      3386,
      3387
    ]
  },
  {
    "category_id": 3353,
    "parent": 3352,
    "code": "voennaya-odezhda",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Військовий одяг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/voennaya-odezhda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3360,
      3361,
      3362,
      3363,
      3364,
      3365,
      3366,
      3367,
      3368,
      3369,
      3370,
      3371,
      3372,
      3373
    ]
  },
  {
    "category_id": 3354,
    "parent": 3352,
    "code": "voennaya-obuv",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Військове взуття",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/voennaya-obuv",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3374,
      3375,
      3376,
      3377
    ]
  },
  {
    "category_id": 3355,
    "parent": 3352,
    "code": "ryukzaki-i-sumki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Рюкзаки та сумки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/ryukzaki-i-sumki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3378,
      3379,
      3380,
      3381
    ]
  },
  {
    "category_id": 3356,
    "parent": 3352,
    "code": "razgruzochnye-sistemy",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Розвантажувальні системи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/razgruzochnye-sistemy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3382,
      3383,
      3384,
      3385
    ]
  },
  {
    "category_id": 3357,
    "parent": 3352,
    "code": "podsumki",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Підсумки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/podsumki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3394,
      3395,
      3396,
      3397,
      3398
    ]
  },
  {
    "category_id": 3358,
    "parent": 3352,
    "code": "snaryazhenie",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спорядження",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/snaryazhenie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3399,
      3400,
      3401,
      3402,
      3403,
      3404,
      3405,
      3406,
      3407,
      3408
    ]
  },
  {
    "category_id": 3359,
    "parent": 3352,
    "code": "oruzhejnye-aksessuary",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Збройові аксесуари",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/oruzhejnye-aksessuary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3409,
      3410,
      3411,
      3412,
      3413,
      3414
    ]
  },
  {
    "category_id": 3360,
    "parent": 3353,
    "code": "kamuflyazhnye-kostyumy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Камуфляжні костюми",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/voennaya-odezhda/kamuflyazhnye-kostyumy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3361,
    "parent": 3353,
    "code": "shtany",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Штани",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/voennaya-odezhda/shtany",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3362,
    "parent": 3353,
    "code": "futbolki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Футболки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/voennaya-odezhda/futbolki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3363,
    "parent": 3353,
    "code": "rubashki",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сорочки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/voennaya-odezhda/rubashki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3364,
    "parent": 3353,
    "code": "kofty",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кофти",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/voennaya-odezhda/kofty",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3365,
    "parent": 3353,
    "code": "kurtki",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Куртки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/voennaya-odezhda/kurtki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3366,
    "parent": 3353,
    "code": "remni",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ремені",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/voennaya-odezhda/remni",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3367,
    "parent": 3353,
    "code": "perchatki",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Рукавички",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/voennaya-odezhda/perchatki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3368,
    "parent": 3353,
    "code": "noski",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шкарпетки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/voennaya-odezhda/noski",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3369,
    "parent": 3353,
    "code": "balaklavy-i-podshlemniki",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Балаклави та підшоломники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/voennaya-odezhda/balaklavy-i-podshlemniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3370,
    "parent": 3353,
    "code": "kepki-i-beysbolki",
    "position": 11,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кепки та бейсболки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/voennaya-odezhda/kepki-i-beysbolki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3371,
    "parent": 3353,
    "code": "panamy",
    "position": 12,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Панами",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/voennaya-odezhda/panamy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3372,
    "parent": 3353,
    "code": "shapki",
    "position": 13,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Шапки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/voennaya-odezhda/shapki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3373,
    "parent": 3353,
    "code": "drugaya-odezhda",
    "position": 14,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інший одяг",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/voennaya-odezhda/drugaya-odezhda",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3374,
    "parent": 3354,
    "code": "bertsy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Берці",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/voennaya-obuv/bertsy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3375,
    "parent": 3354,
    "code": "krossovki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кросівки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/voennaya-obuv/krossovki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3376,
    "parent": 3354,
    "code": "botinki-i-polubotinki",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Черевики та напівчеревики",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/voennaya-obuv/botinki-i-polubotinki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3377,
    "parent": 3354,
    "code": "drugaya-obuv",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше взуття",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/voennaya-obuv/drugaya-obuv",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3378,
    "parent": 3355,
    "code": "ryukzaki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Рюкзаки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/ryukzaki-i-sumki/ryukzaki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3379,
    "parent": 3355,
    "code": "sumki",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сумки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/ryukzaki-i-sumki/sumki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3380,
    "parent": 3355,
    "code": "bauly",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Баули",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/ryukzaki-i-sumki/bauly",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3381,
    "parent": 3355,
    "code": "drugoe",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/ryukzaki-i-sumki/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3382,
    "parent": 3356,
    "code": "zhilety-razgruzochnye",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Жилети розвантажувальні",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/razgruzochnye-sistemy/zhilety-razgruzochnye",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3383,
    "parent": 3356,
    "code": "takticheskie-poyasa",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Тактичні пояси",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/razgruzochnye-sistemy/takticheskie-poyasa",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3384,
    "parent": 3356,
    "code": "remenno-plechevye-sistemy-rps",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ремінно-плечові системи (РПС)",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/razgruzochnye-sistemy/remenno-plechevye-sistemy-rps",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3385,
    "parent": 3356,
    "code": "drugoe",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/razgruzochnye-sistemy/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3386,
    "parent": 3352,
    "code": "meditsina",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Медицина",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/meditsina",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3388,
      3390,
      3392
    ]
  },
  {
    "category_id": 3387,
    "parent": 3352,
    "code": "zashchita",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Захист",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/zashchita",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3389,
      3391,
      3393
    ]
  },
  {
    "category_id": 3388,
    "parent": 3386,
    "code": "aptechki-i-meditsinskie-nabory",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Аптечки та медичні набори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/meditsina/aptechki-i-meditsinskie-nabory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3389,
    "parent": 3387,
    "code": "broneplastiny",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бронепластини",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/zashchita/broneplastiny",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3390,
    "parent": 3386,
    "code": "zhguty-i-turnikety",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Джгути, турнікети",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/meditsina/zhguty-i-turnikety",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3391,
    "parent": 3387,
    "code": "bronezhilety",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Бронежилети",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/zashchita/bronezhilety",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3392,
    "parent": 3386,
    "code": "drugoe",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Iнше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/meditsina/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3393,
    "parent": 3387,
    "code": "kaski-i-shlemy",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Каски та шоломи",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/zashchita/kaski-i-shlemy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3394,
    "parent": 3357,
    "code": "meditsinskie",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Медичні підсумки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/podsumki/meditsinskie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3395,
    "parent": 3357,
    "code": "dlya-granat",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Підсумки для гранат",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/podsumki/dlya-granat",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3396,
    "parent": 3357,
    "code": "dlya-magazinov",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Підсумки для магазинів штурмових гвинтівок",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/podsumki/dlya-magazinov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3397,
    "parent": 3357,
    "code": "sbrosa-magazinov",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Сумки скидання магазинів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/podsumki/sbrosa-magazinov",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3398,
    "parent": 3357,
    "code": "drugie-podsumki",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Спеціальні та інші підсумки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/podsumki/drugie-podsumki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3399,
    "parent": 3358,
    "code": "sredstva-maskirovki",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Засоби маскування",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/snaryazhenie/sredstva-maskirovki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3400,
    "parent": 3358,
    "code": "teplovizory",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Тепловізори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/snaryazhenie/teplovizory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3401,
    "parent": 3358,
    "code": "nashivki-shevrony-zhetony",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Нашивки, шеврони, жетони",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/snaryazhenie/nashivki-shevrony-zhetony",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3402,
    "parent": 3358,
    "code": "kobury",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Кобури",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/snaryazhenie/kobury",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3403,
    "parent": 3358,
    "code": "remni-dlya-oruzhiya",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Ремені для зброї",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/snaryazhenie/remni-dlya-oruzhiya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3404,
    "parent": 3358,
    "code": "poyasa-patrontashi",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Пояси-патронташі",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/snaryazhenie/poyasa-patrontashi",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3405,
    "parent": 3358,
    "code": "takticheskie-naushniki",
    "position": 7,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Тактичні навушники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/snaryazhenie/takticheskie-naushniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3406,
    "parent": 3358,
    "code": "nakolenniki-i-nalokotniki",
    "position": 8,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Наколінники та налокітники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/snaryazhenie/nakolenniki-i-nalokotniki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3407,
    "parent": 3358,
    "code": "takticheskie-ochki",
    "position": 9,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Тактичні окуляри",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/snaryazhenie/takticheskie-ochki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3408,
    "parent": 3358,
    "code": "drugoe-snaryazhenie",
    "position": 10,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше спорядження",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/snaryazhenie/drugoe-snaryazhenie",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3409,
    "parent": 3359,
    "code": "kollimatory",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Коліматори",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/oruzhejnye-aksessuary/kollimatory",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3410,
    "parent": 3359,
    "code": "opticheskie-pritsely",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Оптичні приціли",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/oruzhejnye-aksessuary/opticheskie-pritsely",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3411,
    "parent": 3359,
    "code": "tsevya-nakladki-krepleniya",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Цівки, накладки, кріплення",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/oruzhejnye-aksessuary/tsevya-nakladki-krepleniya",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3412,
    "parent": 3359,
    "code": "plamegasiteli-dtk-glushiteli",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Полум'ягасники, ДТК, глушники",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/oruzhejnye-aksessuary/plamegasiteli-dtk-glushiteli",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3413,
    "parent": 3359,
    "code": "priklady-rukoyatki-soshki",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Приклади, рукоятки, сошки",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/oruzhejnye-aksessuary/priklady-rukoyatki-soshki",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3414,
    "parent": 3359,
    "code": "drugoe",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Інше",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 4,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/militariya/oruzhejnye-aksessuary/drugoe",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3415,
    "parent": 903,
    "code": "velo",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Вело",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/velo",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3416,
      3417,
      3418,
      3419,
      3420
    ]
  },
  {
    "category_id": 3416,
    "parent": 3415,
    "code": "velosipedy",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Велосипеди",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/velo/velosipedy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3417,
    "parent": 3415,
    "code": "velozashchita",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Велозахист",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/velo/velozashchita",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3418,
    "parent": 3415,
    "code": "veloaksessuary",
    "position": 3,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Велоаксесуари",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/velo/veloaksessuary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3419,
    "parent": 3415,
    "code": "velozapchasti",
    "position": 4,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Велозапчастини",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/velo/velozapchasti",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3420,
    "parent": 3415,
    "code": "elektrovelosipedy",
    "position": 5,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Електровелосипеди",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/velo/elektrovelosipedy",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3421,
    "parent": 903,
    "code": "kvadrokoptery-i-aksessuary",
    "position": 6,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Квадрокоптери та аксесуари",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 2,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/kvadrokoptery-i-aksessuary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    },
    "children": [
      3426,
      3427
    ]
  },
  {
    "category_id": 3426,
    "parent": 3421,
    "code": "kvadrokoptery",
    "position": 1,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Квадрокоптери",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/kvadrokoptery-i-aksessuary/kvadrokoptery",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  },
  {
    "category_id": 3427,
    "parent": 3421,
    "code": "instrumenty-i-aksessuary",
    "position": 2,
    "tree_revision": 1256,
    "brand": "olxua",
    "icon_name": "",
    "type": "goods",
    "name": "Аксесуари для квадрокоптерів",
    "view_type": "list",
    "is_addable": true,
    "is_searchable": false,
    "is_offer_seekable": false,
    "is_business": true,
    "level": 3,
    "max_photos": 8,
    "path": "hobbi-otdyh-i-sport/kvadrokoptery-i-aksessuary/instrumenty-i-aksessuary",
    "icon": {
      "image_url": "",
      "big_image_url": ""
    }
  }
]