import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useStore} from 'react-context-hook'
import categories from "../data/categories"

declare const window: Window &
    typeof globalThis & {
    Telegram: any
}

export const lookup = (id: number | undefined) => {
    if (id === undefined) return;

    for (let i = 0; i < categories.length; i++) {
        if (categories[i].category_id === id) {
            return categories[i]
        }
    }
}

export default function Categories() {
    const navigate = useNavigate()
    const [category, setCategory] = useStore('category')
    const [isTelegramReady] = useStore('isTelegramReady')
    let {id} = useParams();

    useEffect(() => {
        if (!isTelegramReady) return;

        window.Telegram.WebApp.BackButton.show()
        console.debug("telegram's back button is visible!")

        window.Telegram.WebApp.onEvent('backButtonClicked', () => {
            navigate('/');
        })
    }, [isTelegramReady, category]);

    const onClick = (category: number | undefined, final: boolean) => {
        return () => {
            if (!final && !category) return navigate(`/categories/0`);

            if (!final && lookup(category)?.children) {
                return navigate(`/categories/${category}`)
            }

            setCategory(category)
            navigate('/')
        }
    }

    const children = (id: number | undefined, selected: number | null,): any[] => {
        if (id === undefined) return [];

        let data = [];
        for (let i = 0; i < categories.length; i++) {
            const category = categories[i];

            if (category.parent !== id) {
                continue
            }

            if (data.length === 0) {
                const root = lookup(id);

                if (root) {
                    const back = lookup(lookup(id)?.parent)?.category_id;
                    data.push(<Category key={back} onClick={onClick(back, false)} name={".."}/>)

                    const cid = root.category_id;
                    const name = "Все в " + root.name;
                    const isSelected = cid === selected;

                    data.push(<Category key={cid} selected={isSelected} onClick={onClick(cid, true)} name={name}/>)
                } else {
                    const cid = 0;
                    const name = "Будь-яка категорія";

                    data.push(<Category key={cid} onClick={onClick(cid, true)} name={name}/>)
                }
            }


            const cid = category.category_id;
            const name = category.name;
            const isSelected = cid === selected;

            data.push(<Category key={cid} selected={isSelected} onClick={onClick(cid, false)} name={name}/>)
        }

        return data;
    }


    let data = children(Number(id), category as number);
    if (data.length === 0) {
        data = children(lookup(Number(id))?.parent, category as number);
    }

    return <>{data}</>;
}

function Category(props: any) {
    return <div
        className="bg-[var(--bg-color)] text-black dark:text-white p-2 m-2 border border-green-500" {...props}>{props.name}<span
        style={{display: props.selected ? 'inline' : 'none'}}> * </span>
    </div>;
}