import { Menu } from "../components/Menu";
import React from "react";

declare const window: Window &
    typeof globalThis & {
  Telegram: any
}

export default function Plans() {
  const openInvoice = () => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openInvoice('https://t.me/$4Wu4T6tOwEjZBgAA_8ynwXmq6Ic');
    }
  }

  return <>
    <Menu />

    <div className="p-2 mt-4 text-center">
      <div onClick={()=>{openInvoice()}} className="w-72 rounded mt-2 p-2 m-auto dark:text-gray-50 text-gray-500 border border-gray-500">
        <div>Економ</div>
        <span className="text-2xl">70 грн</span>
        <span>/місяць</span>
        <ul className="mt-1">
          <li>4 пошукових запитів</li>
          <li>перевірка запитів кожні 4хв</li>
        </ul>
      </div>

      <div onClick={()=>{openInvoice()}} className="w-80 rounded mt-2 p-2 m-auto dark:text-gray-50 text-gray-500 border-2 border-gray-500">
        <div>Стандарт</div>
        <span className="font-bold text-2xl">120 грн</span>
        <span>/місяць</span>
        <ul className="mt-1">
          <li>10 пошукових запитів</li>
          <li>перевірка запитів кожні 2хв</li>
        </ul>
      </div>

      <div onClick={()=>{openInvoice()}} className="w-72 rounded mt-2 p-2 m-auto dark:text-gray-50 text-gray-500 border border-gray-500">
        <div>Бізнес</div>
        <span className="text-2xl">250 грн</span>
        <span>/місяць</span>
        <ul className="mt-1">
          <li>25 пошукових запитів</li>
          <li>перевірка запитів кожну хвилину</li>
        </ul>
      </div>

      <p className="dark:text-gray-50 text-gray-500 mt-4">* Тарифи активуються з моменту оплати та тривають протягом одного календарного місяця.
        Після закінчення терміну дії відповідної підписки (Економ/Стандарт чи Бізнес) залишається один безкоштовний запит, який був створений останнім. Інші заявки будуть видалені.
      </p>
    </div>
  </>
}
