import {Menu} from "../components/Menu";
import React from "react";

declare const window: Window &
    typeof globalThis & {
    Telegram: any
}

export default function Contacts() {
    const openSupport = () => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.openTelegramLink('https://t.me/gbotsupport');
        }
    }

    const openPhone = () => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.openLink('https://tinyurl.com/2nr7a8f5');
        }
    }

    const openEmail = () => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.openLink('https://tinyurl.com/4wfvfvzw');
        }
    }

    return <>
        <Menu/>

        <div className="p-2 mt-4 text-center">
            <div className="dark:text-gray-50 text-gray-500 font-bold">Графік роботи:</div>
            <div className="dark:text-gray-50 text-gray-500">Пн-Пт: з 10:00 до 20:00</div>
            <div className="dark:text-gray-50 text-gray-500 mb-4">Сб-Нд: з 11:00 до 16:00</div>

            <div className="dark:text-gray-50 text-gray-500 font-bold">Контакти:</div>
            <div className="dark:text-gray-50 text-gray-500">📞
                <span onClick={() => {
                    openPhone()
                }} className="underline">+380 (63) 228 80 80</span>
            </div>
            <div className="dark:text-gray-50 text-gray-500">📧
                <span onClick={() => {
                    openEmail()
                }} className="underline">support@gbot.pro</span>
            </div>
            <div className="dark:text-gray-50 text-gray-500">🧑🏼‍💻
                <span onClick={() => {
                    openSupport()
                }} className="underline">@gbotsupport</span>
            </div>
        </div>
    </>
}



