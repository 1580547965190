import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useStore} from "react-context-hook";
import {Menu} from "../components/Menu";
import {lookup} from "./Categories";

declare const window: Window &
    typeof globalThis & {
    Telegram: any
}

export default function Home() {
    const navigate = useNavigate();
    const [category] = useStore('category')
    const [isTelegramReady] = useStore('isTelegramReady')

    useEffect(() => {
        if (!isTelegramReady) return;

        window.Telegram.WebApp.BackButton.hide();
        console.debug("telegram's back button is hidden!")
    }, [isTelegramReady]);

    useEffect(() => {
        if (!isTelegramReady) return;

        if (!category) window.Telegram.WebApp.MainButton.hide();

        if (category) window.Telegram.WebApp.MainButton.show();
    }, [isTelegramReady, category]);

    const selectedCategory = category ? lookup(category as number)?.name : 'Select category'

    return (
        <>
            <Menu/>

            <div className="bg-[var(--bg-color)] text-black dark:text-white p-2 m-2 border border-green-500"
                 onClick={() => navigate(`/categories/${category}`)}>{selectedCategory}</div>
        </>
    );
}