import React, {useEffect, useRef} from 'react';
import {Route, Routes} from "react-router-dom"
import {useScript} from "usehooks-ts";
import {useStore, withStore} from 'react-context-hook'
import Plans from "./pages/Plans"
import Settings from "./pages/Settings";
import Info from "./pages/Info";
import Contacts from "./pages/Contacts";
import Home from "./pages/Home";
import Categories from "./pages/Categories";

const initialState = {
    category: 0,
    isTelegramReady: false,
}

declare const window: Window &
    typeof globalThis & {
    Telegram: any
}

function App() {
    const status = useScript(`https://telegram.org/js/telegram-web-app.js`, {removeOnUnmount: false});
    const [isTelegramReady, setIsTelegramReady] = useStore('isTelegramReady')
    const [category] = useStore('category')
    const categoryRef = useRef(category);
    // test
    useEffect(() => {
        if (status !== 'ready') return;

        const elementExist = setInterval(() => {
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.ready();
                window.Telegram.WebApp.expand();
                setIsTelegramReady(true)
                clearInterval(elementExist);
                console.debug("telegram is ready!")
            }
        }, 100);
    }, [status])

    useEffect(() => {
        if (!isTelegramReady) return;

        const cs = getComputedStyle(document.documentElement)
        const tgColorScheme = cs.getPropertyValue('--tg-color-scheme');
        const theme = tgColorScheme ? tgColorScheme : "light"

        document.documentElement.classList.add(theme);

        console.debug(`${theme} theme is used!`)
    }, [isTelegramReady])

    useEffect(() => {
        categoryRef.current = category;
    }, [category]);

    useEffect(() => {
        if (!isTelegramReady) return;

        window.Telegram.WebApp.onEvent('mainButtonClicked', () => {
            alert(JSON.stringify({category: categoryRef.current}))
            window.Telegram.WebApp.close()
        })
    }, [isTelegramReady]);

    return (
        <>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/plans" element={<Plans/>}/>
                <Route path="/settings" element={<Settings/>}/>
                <Route path="/info" element={<Info/>}/>
                <Route path="/contacts" element={<Contacts/>}/>
                <Route path="/categories/:id" element={<Categories/>}/>
            </Routes>
        </>
    );
}

export default withStore(App, initialState);
